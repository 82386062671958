.create-h5p {
  min-height: 90vh;
  @apply relative w-full;
  .loading {
    height: 86vh;
    @apply absolute inset-0 bg-white w-full  flex justify-center items-center -m-6 top-20 left-6 z-30;
  }
  .header-modal {
    @apply h-16 sticky justify-between top-0;
    &-container {
      @apply flex h-12 group-hover:flex  border-b border-gray-300 items-center justify-between -mt-10;
    }
    &-title {
      @apply font-semibold text-base;
    }
    &-action {
      @apply flex gap-3 items-center;
      &-information {
        @apply pr-3 border-r border-gray-300 hover:text-gray-900;
      }
      &-close {
        @apply w-5 h-5 text-gray-800;
      }
    }
  }
  .main-content {
    max-height: 78vh;
    min-height: 78vh;
    @apply relative overflow-y-auto w-full;
    .toast-notify {
      @apply border bg-green-50 rounded-xl border-green-300 p-3;
    }
  }
  .footer {
    @apply flex justify-center gap-4 mt-2 sticky bottom-0 bg-white;
  }

  .h5p-information-container {
    max-height:  calc(100vh - 150px);
  }
}