.bg-apo-color {
  background-image: url('assets/img/bg-transparent.png');
  background-size: 100%;
}
.cover-color {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ApoLevelComponent-content {
  max-height: calc(100vh - 202px);
  overflow-y: auto;
  min-height: 200px;
}
.ApoLevelComponent-add {
  width: calc(100% - 50px);
}
