.noContent {
    @apply flex flex-col justify-center items-center py-6;
    &-img {
        max-width: 200px;
        @apply inline-block w-full;
    }
    &-title {
        @apply flex items-center justify-center;
        p {
            @apply mt-3 font-medium text-sm leading-6 text-ooolab_dark_2;
        }
    }
    &-subTitle {
        @apply flex items-center mb-3;
        p {
            @apply mt-3 font-normal text-xs leading-6 text-ooolab_dark_2;
        }
    }
}
