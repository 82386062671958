.information {
    &-label {
        @apply block w-full text-sm;
    }

    &-input {
        min-width: 125px;
        @apply text-xs text-gray-800 leading-5 p-0 bg-transparent mt-0.5 w-full max-w-full
        border border-transparent focus:bg-white focus:border-primary-500 rounded focus:shadow-input focus:px-0.5;

        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder  {
            @apply text-gray-400 text-xs;
        }
    }

    &-textarea {
        @apply text-xs text-gray-800 leading-5 p-0 bg-transparent mt-0.5 w-full focus:outline-none
        border border-transparent focus:bg-white focus:border-primary-500 rounded focus:shadow-input focus:px-0.5;

        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder  {
            @apply text-gray-400 text-xs;
        }
    }

    &-item {
        @apply mb-3;
        .error-title {
            font-size: 10px;
            @apply text-red-500 block text-xs;
        }
    }

    &-disclosure {
        @apply border-t border-gray-300 mb-3 pt-3;
    }

    &-info {
        max-height: calc(100vh - 460px);
        // min-height: 160px;
        @apply border-t border-gray-300 text-xs text-gray-500 pt-2 leading-5 font-normal overflow-y-auto;

        button {
            @apply text-primary-500 underline hover:no-underline;
        }
        .taglist {
            @apply pt-0;
        }
        .tagItem {
            @apply px-0 mt-0 pb-3;
        }
    }
    &-loading {
        min-height: 160px;
        @apply flex items-center justify-center;
    }
}