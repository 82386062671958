.instructorDashboard {
    &-filter {
        @apply flex justify-between items-center mt-3 px-5 relative z-10;
    }
    &-main {
        @apply gap-x-2  border-t border-gray-200 p-5;
        .loading {
            @apply flex items-center justify-center h-full;
          }
    }
    &-tableBody {
        @apply overflow-y-scroll ;
    }
    &-pagination {
        @apply flex items-center justify-between border-t border-ooolab_bar_color;
    }
    &-paginationCount {
        @apply text-ooolab_dark_2 text-ooolab_xs font-semibold leading-6 hidden lg:block pl-5;
    }
}
