.selectBox {
    &--button {
        @apply disabled:bg-gray-50 cursor-pointer outline-none focus:outline-none disabled:cursor-not-allowed w-full;
        .ItemSelected {
            @apply border border-gray-300 text-gray-800 w-full h-10 rounded flex gap-2 items-center px-2 justify-between py-0.5;
            
            .ListItem {
                @apply flex items-center gap-1 h-full;
                .item {
                    @apply px-1 h-full bg-blue-50 border border-blue-300 rounded text-xs text-primary-500 flex gap-1 items-center;
                    &-avatar {
                        @apply w-5 h-5 rounded-full;
                    }
                    &-icon {
                        @apply w-4 h-4 text-gray-800 hover:text-primary-500;
                    }
                }
            }
        }

    }
    
    &-checkbox {
        min-height: 38px;
        @apply flex gap-2 py-2 cursor-pointer hover:bg-blue-50 px-4 items-center text-xs bg-white ;

        &--checked {
            @apply bg-blue-50 border-b border-white;
        }
        &--disabled {
            @apply hover:bg-transparent pointer-events-none cursor-not-allowed opacity-50;
        }
    }
    &-check {
        position: relative;
        @apply w-5 h-5 min-w-[20px] rounded;
    }
}