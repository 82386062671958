.addSlot {
    &-action {
        @apply flex justify-center gap-4 mt-6 mb-2;
    }
    &-section {
        @apply rounded border border-dashed border-gray-300 bg-gray-50 py-3 px-4 mt-7;
    }
    &.time {
        @apply mt-5;
    }
}