.SCORMPackage {
    &-label {
        @apply font-semibold text-xs text-gray-800 block w-full mb-1;
    }
    &-required {
        @apply text-red-500;
    }
    &-input {
        @apply rounded border border-gray-300 px-2.5 py-1.5 w-full;

        &::placeholder {
            @apply text-sm text-gray-400;
        }
          
    }
    &-inputFile {
        @apply  relative rounded border border-gray-300 px-2.5 py-1.5 w-full;
        input {
            @apply opacity-0 absolute;
        }
        span {
            @apply text-sm text-gray-400;
        }
    }
    &-item {
        @apply mb-5;
        .error {
            @apply ml-0 text-xs;
        }
    }
    &-file {
        @apply border border-primary-500 rounded px-3 py-1.5 relative ml-2.5 cursor-pointer hover:bg-blue-50 transition;

        input {
            @apply opacity-0 absolute z-[999]  left-0 w-full h-full;
        }
        span {
            @apply text-sm text-primary-500 font-semibold whitespace-nowrap;  
        }
    }
    p {
        @apply text-xs text-gray-800 font-semibold;
    }
    ul {
        list-style-type: disc;
        @apply ml-[14px] mt-2;

        li {
            @apply text-xs text-gray-800 py-0.5 leading-5;
            a {
                padding-right: 0;
            }
        }
    }
    &-toggle {
        @apply border border-gray-300 rounded p-1 inline-block;

        span {
            @apply w-[112px] py-0.5 text-center inline-block text-sm text-gray-500 rounded cursor-pointer;

            &.actived {
                @apply bg-blue-50 text-primary-500;
            }
        }
    }
    &-action {
        @apply flex justify-center space-x-4 mt-6;
    }
}