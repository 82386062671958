.column-tooltip {
    opacity: 0;
    transition: 2 all ease-in;
}
.chart-column:hover .column-tooltip {
    opacity: 1;
    transition: 2 all ease-out;
}
.highcharts-xaxis,
.highcharts-yaxis-grid {
    display: none;
}
.highcharts-xaxis-labels {
    @apply font-display text-gray-500 text-[13px];
}
.highcharts-xaxis-labels  text {
    fill: #6B7280 !important;
    color: #6B7280 !important;
}
.highcharts-yaxis-labels {
    @apply font-display text-gray-500 text-[16px];
}
.highcharts-yaxis-labels text {
    fill: #6B7280 !important;
    color: #6B7280 !important;
}
.dot {
    @apply w-2 h-2 rounded-full inline-block bg-primary-500;
}