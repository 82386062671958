.assigmentBuilderHeader {
    height: 48px;
    @apply flex px-5 border-b border-gray-300 items-center w-full justify-between;

    &-action {
        @apply flex space-x-4 items-center;
    }
    &-line {
        width: 1px;
        height: 24px;
        @apply bg-gray-300;
    }
    &-btn {
        @apply text-gray-500 hover:text-primary-600 transition;

        &--active {
            @apply text-primary-500;
        }
    }
    
}
.assigmentBuilderStatus {
    max-width: calc(100vw - 500px);
    @apply w-full flex items-center justify-center;
    &-label {
        padding: 2px;
        @apply text-sm font-semibold text-gray-500 border border-gray-300 rounded px-2 mr-2;

        &--active {
            @apply text-green-500 border-green-300 bg-green-50;
        }
        
    }
    &-text {
        max-width: 200px;
        @apply text-base text-gray-800 font-semibold truncate block;
    }
}