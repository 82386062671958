.certificate-detail {
  .certificate-container {
    margin-top: 16px;
    position: relative;
    width: 100%;
    max-width: 3508px; /* Maximum width based on 300 PPI image */
    height: 0;
    padding-bottom: 70.57%; /* Aspect ratio (2480/3508 * 100) */
    background-size: cover;
    background-position: center;

    .certificate-text {
      position: absolute;
      transform: translate(-50%, -50%);
    }
  
    .learner-name {
      top: 55%;
      left: 50%;
      width: 81%;
      text-align: center;
      font-size: 3vw;
      font-weight: 600;
    }
  
    .course-name {
      top: 70%;
      left: 50%;
      width: 81%;
      text-align: center;
      font-size: 2vw;
      font-weight: 600;
    }
  
    .certificate-date {
      top: 82%;
      left: 21.5%;
      width: 15%;
      font-size: 1.5vw;
      font-weight: 400;
    }
  
    .certificate-id {
      top: 90%;
      left: 21.5%;
      width: 15%;
      font-size: 1.5vw;
      font-weight: 400;
    }

    @media (max-width: 768px) {
      .learner-name {
        font-size: 5vw;
      }
    
      .course-name {
        font-size: 4.5vw;
      }
    
      .certificate-date, .certificate-id {
        font-size: 3.5vw;
      }
    }
  }
}