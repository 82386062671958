.ooolab {
    &-bg-button {
        @apply bg-gray-700;
    }
}
.apollo {
    &-bg-button {
        @apply bg-primary-500;

        &.cursor-not-allowed {
            background-color: #C7C9D9;
        }
    }
}