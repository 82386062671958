.learningPathBuilder {
    @apply bg-gray-50 pt-[48px] min-h-screen;

    &-header {
        @apply fixed top-0 left-0 z-[69] w-full;
    }
    &-title {
        @apply flex mb-4 text-gray-800 font-semibold text-xl items-center;
    }
    &-main {
        @apply p-8 flex space-x-8;
    }
    &-sidebar {
        @apply p-5 bg-white rounded-lg w-[320px] min-h-[calc(100vh-156px)] flex-none;
    }
    &-content {
        @apply p-5 bg-white rounded-lg w-full;
    }
    &-add {
        @apply w-[120px] flex flex-none justify-center border border-primary-500 text-primary-500 text-sm font-semibold rounded py-1.5 px-3
        hover:text-primary-600 hover:border-primary-600 hover:bg-blue-50 transition items-center ml-4;

        &--large {
            @apply border-dashed w-full text-base justify-center h-[48px] py-2.5 mt-5 ml-0;
        }
    }
    &-unit {
        @apply relative mb-2;
        
    }
    &-container {
        &:before {
            content: '';
            height: calc(100% - 69px);
            @apply inline-block absolute border border-gray-400 left-[33px] top-[45px] border-dashed;
        }
    }
}