.tagItem {
  @apply flex flex-col mt-5 px-3;

  &-title {
    @apply text-xs font-semibold text-black;
  }

  &-list {
    @apply text-gray-400 flex gap-2 pt-1 flex-wrap;

    .tagItem-item {
      @apply flex h-7 items-center px-2 py-1 text-sm gap-x-2 rounded text-primary-500 bg-primary-100;

      .item-name {
        @apply w-fit;
      }

      .item-icon {
        @apply mt-0.5 cursor-pointer;
      }
    }
    .wrapper-input {
      @apply flex flex-col gap-1;
      .item-input {
        background-color: transparent;
        @apply h-7 inline-flex text-sm items-end w-auto bg-none;
      }
      .error {
        @apply text-red-500 text-xs;
      }
      input::placeholder {
        @apply text-gray-400 text-sm;
      }
    }
  }
}

.taglistHeader {
  @apply flex gap-3 items-center;

  &-label {
    @apply text-black font-semibold text-sm w-36;
  }

  &-line {
    height: 1px;
    @apply bg-gray-300 w-full;
  }
}

.taglist {
  min-height: 120px;
  @apply rounded-lg bg-gray-50 p-3;
  
  &.noBackground {
    @apply bg-transparent px-0;
  }
  &-loading {
    @apply text-primary-500 mx-auto mt-8;
  }
}