.assignedPermissions {
    @apply p-5 bg-white rounded-2xl grid grid-cols-2 text-gray-800 gap-5;
    &-header {
        @apply w-full flex justify-between col-span-2;
        h1 {
            @apply text-xl font-semibold;
        }
    }
    &-main {
        @apply col-span-2;
    }
    &-feature {
        @apply border-t border-t-gray-300 grid grid-cols-5 gap-x-4 gap-y-2  px-4 py-2 bg-gray-50;
    }
    &-featureName {
        @apply col-span-2 text-sm font-semibold text-gray-900;
    }
    &-readEdit {
        @apply col-span-1 font-semibold text-gray-900 flex justify-center;
    }
    &-readOnly {
        @apply col-span-1 font-semibold text-gray-900 flex justify-center;
    }
    &-noPermission {
        @apply col-span-1 font-semibold text-gray-900 flex justify-center ;
    }
}
