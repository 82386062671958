.learnerInfo {
    @apply flex flex-col gap-3;
    &-header {
        @apply flex justify-between items-center space-x-2;
    }
    
    &-learner {
        @apply flex-1 flex items-center gap-2;
    }
    &-avatar {
        @apply flex-none w-10 h-10 bg-cover rounded-full bg-gray-300;
    }
    &-name {
        @apply text-xl text-gray-800 font-semibold;
    }

    &-grade {
        @apply flex-none flex items-center p-3 rounded-lg text-sm text-gray-700 bg-primary-50 max-h-11;
        span {
            @apply text-xl text-gray-800 font-semibold ml-2;
        }
    }
    &-info {
        @apply flex gap-3;
    }
    &-item {
        @apply flex gap-1 items-center;
    }
    &-label {
        @apply text-sm text-gray-500;
    }
    &-value {
        @apply text-sm text-gray-800 font-semibold;
    }
    &-line {
        @apply h-[22px] w-[1px] bg-gray-200;
    }
    &-progress {
        @apply w-[208px] h-3 bg-gray-200/50 rounded-full relative;
    }
    &-bar {
        @apply  absolute left-0 top-0 h-3 rounded-full bg-green-500;
    }
}