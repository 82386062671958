.react-datepicker__custom {
    .react-datepicker-popper {
        &[data-placement^=top] {
            padding-bottom: 18px;
        }

        &[data-placement^=bottom] {
            padding-top: 18px;
        }
    }

    .react-datepicker {
        // display: flex;

        // .react-datepicker__time-container {
        //     width: 86px;

        //     .react-datepicker__time-list {
        //         &::-webkit-scrollbar {
        //             height: 10px;
        //             width: 10px;
        //             background: #ebebf0;
        //         }

        //         &::-webkit-scrollbar-thumb {
        //             background: #c4c4c4;
        //             border-radius: 10px;
        //         }
        //     }

        // }

        &.react-datepicker--time-only .react-datepicker__triangle {
            left: -44% !important;
        }
    }
}