.ModalCancel {
  @apply flex justify-center text-center items-center;
  .icon {
    @apply w-24 h-24 mx-auto;
  }
  .title {
    @apply text-gray-800 text-sm font-medium my-3 text-center font-inter;
  }
  .input {
    @apply h-11 text-xs rounded font-normal w-full border border-gray-300 leading-8 pl-2 focus:outline-none ;
  }
  .group-btn {
    @apply flex space-x-4 mt-7 justify-center;
    .btn-cancel {
      @apply  bg-red-500 border-red-500;
      >.icon {
        @apply text-white mx-auto;
      }
    }
  }
}