.drag-upload-file {
  @apply relative w-full h-full flex flex-col justify-center;
  width: 100%;
  min-height: 100%;
  height: auto;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23333' strokeWidth='1' stroke-dasharray='8%2c 8' stroke-dashoffset='48' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 16px;
  //@apply border border-dashed border-gray-400;

  &.drag-active {
    @apply bg-blue-300;
    
    > div {
      @apply opacity-0;
    }
  }
  .input {
    @apply hidden inset-0
  }
  .document {
    @apply w-full h-full;
  }

  .dropzone {
    @apply  w-full h-full flex flex-col justify-start items-center overflow-visible py-4;
    .img-nocontent {
      @apply max-w-xs w-full;
    }
    .main-content {
      @apply flex justify-center gap-2 flex-col mt-4;
      .title {
        @apply w-full text-center text-xl font-semibold;
      }
      .content {
        @apply w-full text-center;
      }
    }
  }
}

