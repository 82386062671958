.sideLeft {
    &-item {
        @apply px-4;
    }
    &-header {
        @apply flex flex-row items-center gap-2 text-gray-800;

        &.active {
            @apply text-primary-500;
        }
    }
    &-label {
        @apply text-sm font-semibold ;
    }
    &-count {
        @apply text-sm font-semibold text-primary-500 h-5 min-w-[20px] bg-blue-50 rounded-full px-1.5 inline-block;
    }
    &-container {
        @apply py-2 flex flex-col gap-2;
    }
}

.sideLeftUser {
    @apply flex gap-2 items-center w-full cursor-pointer py-2.5 px-2 rounded-lg border border-gray-200;
    &.active {
        --tw-border-opacity: 1 !important;
        border-color: rgba(var(--color-primary-500), var(--tw-border-opacity)) !important;
        @apply bg-primary-50 shadow-input_1;
    }
    .grade {
        min-width: 45px;
        max-height: 26px;
        @apply bg-gray-100 rounded-sub_tab font-semibold text-sm h-fit w-fit flex justify-center items-center px-1.5 py-1;
    }
    &-name {
        @apply text-sm font-semibold text-enterprise;
    }
    &-avatar {
        @apply w-7 h-7 inline-block rounded-full bg-gray-300 bg-cover;
    }
}