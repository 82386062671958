.importUser {
    &-button {
        @apply h-fit py-1.5 border-primary-500 text-primary-500 w-32;
    }
    &-icon {
        @apply mr-1.5 inline-block;
    }
    &-content {
        @apply flex justify-center items-center w-full;
    }
}