.LearningPathList {
  @apply w-full h-screen;
  &-breadcrumb {
    @apply z-10;
  }
  &-FilterBar {
    @apply flex justify-between p-4;
    .icon-add {
      @apply w-4 h-4;
    }
  }
  &-TableContent {
    @apply px-2;
    .Scrollbar {
      @apply h-[calc(100vh-179px)]  overflow-auto;
      &-loading {
        @apply h-[calc(100vh-179px)] flex justify-center items-center
      }
    }
  }
}