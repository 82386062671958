.courseHeader {
    @apply z-10 p-5 border-b border-gray-300 flex;

    &-title {
        @apply text-gray-900 text-2xl font-semibold truncate;
    }
    &-status {
        @apply bg-gray-50 rounded border border-gray-300 px-2 flex items-center text-gray-500 ml-2;
        &--active {
            @apply text-green-500 border-green-300 bg-green-50;
        }
    }
    &-save {
        @apply flex-none ml-auto text-gray-400 flex text-xs items-center pl-4;

        > span {
            @apply mr-1;
        }
    }
    &-saveTemplate {
        @apply font-semibold text-sm text-primary-500 border-l border-gray-300 pl-2 cursor-pointer hover:text-primary-300 transition;
    }
}