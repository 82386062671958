.lessonBuilder {
    @apply bg-gray-50;

    &-header {
        @apply fixed w-full top-0 left-0 bg-white z-10;
    }
    &-main {
        padding-top: 48px;
        min-height: calc(100vh);
        @apply flex;
    }
    &-sidebar {
        width: 400px;
        @apply flex-none border-r border-gray-200 bg-white;

        &-button {
            @apply w-full;
        }
        .number {
            @apply w-auto px-1 min-w-[20px] h-5 ml-2 text-sm font-semibold rounded-full bg-blue-50 text-primary-500 text-center;
        }
    }
    
    &-content {
        @apply w-full;
    }
}