.userCertificate {
    @apply p-5;
    &-title {
      @apply text-lg font-semibold text-dark-800;
    }
    &-filter {
      @apply flex items-center justify-between my-4;
      .PlusIcon {
        @apply -ml-1 mr-1 h-5 w-5;
      }
    }
    &-main {
      @apply gap-x-2;
      .wrapper-content {
        @apply relative flex gap-2 flex-col items-stretch;
        >.scrollbar {
          @apply h-[calc(100vh-380px)] overflow-auto;
          >.loading {
            @apply h-[calc(100vh-380px)] flex items-center justify-center;
          }
        }
      }
    }
  }
  