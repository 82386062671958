.CourseSessionList {
    @apply text-gray-800;
    &-filter {
        @apply pb-4 border-b border-gray-300 z-[6] relative;
    }
    .wrapper-content {
        @apply mt-5;
        &--main {
            height: calc(100vh - 430px);
            overflow: auto;
            .loading {
                height: calc(100vh - 430px);
                @apply flex  justify-center items-center;
            }
        }
    }
    .react-datepicker-custom-container .react-datepicker-popper {
        z-index: 7;
    }
    
}