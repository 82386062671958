.dropdown {
    @apply relative;

    &-button {
        padding-top: 4px;
        padding-bottom: 4px;
        @apply relative flex items-center w-full px-3 font-normal text-sm text-left border rounded text-black cursor-pointer;

        .dropdown--actived & {
            @apply border-primary-500;
        }

        .dropdown--selected & {
            background-color: #E5F4FF;
            @apply border-primary-500;
        }
    }

    &-number {
        @apply text-xs h-6 w-fit px-2 border border-gray-300 rounded bg-gray-50 flex items-center justify-center ml-2;
    }

    &-arrow {
        @apply flex items-center justify-center h-full ml-2 right-0 top-0 text-dark-100 w-4;

        .dropdown--actived & {
            @apply text-primary-500;
        }
    }

    &-panel {
        z-index: 9999;
        @apply absolute mt-1 rounded-lg bg-white shadow-md;
    }

    &-searchbox {
        @apply pt-2 px-4;
    }

    &-item {
        min-height: 38px;
        @apply flex items-center font-normal text-sm text-gray-800 px-4 py-2 hover:bg-blue-50 cursor-pointer;

        &--selected {
            @apply bg-blue-50;
        }
    }

    &-checkbox {
        width: 20px;
        height: 20px;
        @apply relative mr-2;

        label {
            @apply text-primary-500;

            &:before {
                width: 20px;
                height: 20px;
                content: '';
                left: 0px;
                @apply absolute inline-block border border-gray-300 rounded;
            }

            svg {
                margin-top: -3px;
                @apply opacity-0;
            }
        }

        &--checked label {
            &:before {
                @apply hidden;
            }

            svg {
                @apply opacity-100 inline-block;
            }

        }
    }

    &-footer {
        @apply flex px-4 py-2 border-t border-gray-300;
    }

    &-footer-button {
        @apply flex text-sm text-gray-400 font-normal;

        &--active {
            @apply text-primary-500;
        }
    }

    &-footer-content {
        @apply ml-auto text-sm font-normal text-gray-500 text-right;
    }
    &-showMore {
        @apply text-sm text-primary-500 cursor-pointer font-normal px-4 pb-2;
    }
}

.dropdown-custom-createWorkspace {
    &.dropdown--selected .dropdown-button {
        background-color: white;
        border-color: #d1d5db;
    }

    .dropdown-button {
        padding-top: 13px;
        padding-bottom: 13px;
        border-radius: 8px;
    }
}