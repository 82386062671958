.toastify {
  border-radius: 12px !important;
  @apply px-3 py-0 text-xs min-h-0;
  &.success {
    @apply bg-green-50 border border-green-300 text-sm ml-6 my-1 font-normal text-gray-800 ;
  }
  &.error {
    @apply bg-red-50 border border-red-300;
  }
  &.loading {
    @apply border border-blue-300 bg-blue-50 text-sm ml-6 my-1 font-normal text-gray-800;
  }
  
  &.warning {
    @apply bg-orange-50 border border-orange-300;
  }
  .Toastify__close-button {
    padding: 6px;
    align-self: center;
  }

 
  .Toastify__toast-body {
    padding: 0 6px!important;
    margin:  0 !important;
    align-self: center;
  }
}
.Toastify__toast {
  min-height: 48px !important;
  padding: 8px 16px !important;
}