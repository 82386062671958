.avatar {
  @apply w-7 h-7 min-w-[28px] border rounded-full border-light-100;
}
.InstructorStatus {
  @apply text-sm font-normal border rounded px-3 py-0.5 inline-block capitalize;
  &.scheduled {
    @apply border-primary-300 bg-primary-50 text-primary-500;
  }
  &.released {
    @apply border-green-300 bg-green-50 text-green-500;
  }
}