.DisclosureButton {
  @apply flex justify-between w-full items-center;
  .flex-item {
      @apply flex p-2 gap-1 items-center w-full;
      >.file-name {
        width: max-content;
        max-width: calc(100% - 80px);
        > div {
          @apply text-ellipsis truncate text-left;
        }
      }
  }
  .section-badge {
    min-width: 20px;
    @apply rounded-full justify-center text-primary-500 h-5 px-1.5 flex items-center font-semibold
    bg-blue-50 ml-2;
  }
  &.isDraggingContent {
    opacity: 0.5;
    pointer-events: none;
  }
}