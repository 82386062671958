.selectBox {
    &-checkbox {
        min-height: 38px;
        @apply flex gap-2 py-2 cursor-pointer hover:bg-blue-50 px-4 items-center;

        &--checked .selectBox-check svg {
            @apply inline-block ;
        }
        &--disabled {
            @apply hover:bg-transparent pointer-events-none cursor-not-allowed opacity-50;
        }
    }
    &-check {
        position: relative;
        @apply w-5 h-5 min-w-[20px] rounded border border-gray-300;
        > svg {
            position: absolute;
            @apply inset-0;
            height: 100%;
            width: 100%;
        }
    }
}