.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 32px;
}

.otp-input {
  width: 100%;
  color: #111827;
  border-bottom: 2px solid #D1D5DB;
  outline: none;
  padding: 0;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

.otp-input:hover {
  border-color: #5da9e8;
}

.otp-input:focus {
  border-color: #2e94e8;
}

@media (min-width: 768px){
  .otp-group {
    max-width: 260px;
    column-gap: 28px;
  }

  .otp-input {
    font-size: 30px;
  }
}

@media (min-width: 1024px){
  .otp-group {
    max-width: 360px;
    column-gap: 32px;
  }

  .otp-input {
    font-size: 40px;
  }
}
