.ModalViewDetailLearningPath {
  @apply h-[calc(100vh-124px)] overflow-y-auto;
}

.ModalAction {
  @apply mt-4;
  &-content {
    @apply text-gray-800 text-sm text-left;
  }
  &-input {
    @apply w-full py-2 px-3 border  text-sm font-normal border-gray-300 rounded outline-none;
  }
  &-groupButton {
    @apply flex items-center justify-center space-x-4 mt-8;
    .revertBtn {
      @apply bg-primary-500;
    }
    .deleteBtn {
      @apply bg-red-500;
    }
  }
}

.error-maxlength {
  @apply text-sm text-red-500 mt-1;
}