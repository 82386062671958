.ModalSessionDetail {
  @apply max-h-[600px] overflow-auto flex flex-col gap-3;
  &-session {
    @apply rounded-lg border border-blue-100;
    .DisclosureButton {
      @apply flex flex-col justify-center;
      >.title {
        @apply pl-4 bg-blue-50 w-full;
      }
      >.member {
        @apply flex justify-between px-4 my-2 w-full;
        >.showmore {
          @apply text-xs flex gap-1 items-center text-primary-500;
          >.icon {
            @apply w-3 h-3 text-primary-500;
          }
        }
      }
    }
  }
}

.SessionTitle {
  @apply flex justify-between w-full;
}

.sessionStatus {
  @apply text-sm font-normal border rounded px-3 py-0.5 inline-block capitalize whitespace-nowrap;

  &.completed {
      @apply border-green-300 bg-green-50 text-green-500;
  }
  &.cancelled {
      @apply border-red-300 bg-red-50 text-red-500;
  }
  &.in_progress {
      @apply border-blue-300 bg-blue-50 text-blue-500;
  }
  &.not_started {
      @apply border-orange-300 bg-orange-50 text-orange-500;
  }
}

.SessionDetail {
  @apply flex flex-col gap-2 pt-3 pb-1 px-4 mt-2;
  >.wrapper {
    @apply flex text-xs;
    >.label {
      @apply min-w-[120px] text-gray-500 w-20;
    }
    .value {
      @apply flex items-center gap-0.5;
      > img {
        @apply w-5 h-5 rounded-full bg-gray-300 border border-white;
      }
      >.link {
        @apply text-primary-500 font-semibold;
      }
      >.icon {
        @apply w-4 h-4 text-primary-500;
      }
      >.text {
        @apply text-gray-800 font-normal text-xs;
      }
    }
  }
}

.Member {
  @apply flex gap-2 items-center justify-start;
  .wrapper {
    @apply flex text-xs gap-2 items-center flex-wrap;
    >.label {
      @apply text-gray-500 min-w-fit text-left;
    }
    .avatar-member {
      @apply flex items-center gap-1 w-fit;
      img {
        @apply w-5 h-5 rounded-full bg-gray-300 border border-white;
      }
      .name {
        @apply text-primary-500 font-semibold text-ellipsis truncate;
      }
    }
  }
}