.panelSeting {
    @apply relative w-full max-w-[80vw] 2xl:max-w-[70vw] h-[80vh] max-h-[80vh] min-h-[80vh] rounded-lg transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all;
}

.courseSettings {
    &-header {
        @apply text-base text-gray-900 font-semibold flex py-4 px-6 border-b border-gray-300 items-center;
    }
    &-close {
        @apply ml-auto;
    }
    &-sideLeft {
        @apply w-[200px] min-h-full flex-none border-r border-gray-300;
    }
    &-content {
        @apply flex h-full;
    }
    &-footer {
        @apply flex border-t border-gray-300 py-4 px-6 justify-between absolute bottom-0 w-full bg-white;
    }
    &-actions {
        @apply flex ml-auto gap-1.5;
    }
    &-main {
        @apply w-full max-h-[calc(100vh-277px)] overflow-auto;
    }
    &-title {
        @apply text-xl text-gray-900 font-semibold py-3 px-5 border-b border-neutral-20 w-full;
        p {
            @apply text-sm text-gray-500 font-normal;
        }
    }
    &-container {
        @apply p-5;
        p:not(.text-red-500) {
            @apply text-[12px] text-gray-500 font-normal;
        }
    }
    &-item {
        @apply flex gap-2 border-b border-neutral-20 pb-5 mb-5;
    }
    &-label {
        @apply text-gray-900 text-base font-semibold;
    }
    &-subTitle {
        @apply text-gray-900 text-base font-semibold;
    }
    &-price {
        .errors {
            @apply absolute;
        }
    }
}

.backdropModal {
    @apply absolute inset-0 overflow-hidden bg-black/50;
    .modalDeleteCourse {
        @apply text-left w-full max-w-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white shadow-ooolab_box_shadow_container rounded-lg p-4;
        &-header {
            @apply flex justify-between;
            >.title {
                @apply text-base font-semibold;
            }
        }
        &-mainContent {
            @apply flex flex-col mt-4;
            >.text {
                @apply text-sm text-gray-800;
            }
            >.groupBtn {
                @apply flex justify-center gap-4 mt-6 w-full;
            }
        }
    }
}

