.instructorAvailability {
    &-header {
        @apply flex px-5 py-4 z-[9] relative;
    }
    &-action {
        @apply ml-auto;
    }
    &-content {
        min-height: 445px;
    }
}