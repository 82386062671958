.supplementaryMaterial {
  >.listContent {
      @apply max-h-[calc(100vh-320px)] overflow-auto;
  }
  >.popover {
    @apply relative;
    >.popover-btn {
        @apply relative justify-center text-base font-semibold text-primary-500 hover:text-primary-600 bg-white rounded-lg px-4 py-2 w-full flex items-center space-x-2
        hover:bg-gray-50 border border-transparent hover:border-primary-500 hover:shadow-input transition cursor-pointer;
        >.icon {
            @apply text-primary-500 w-5 h-5 absolute right-4 top-2;
        }
    }
    .popover-panel {
        transform: translate(0px, 42px);
        @apply absolute left-0 z-10 mt-1 w-full rounded-lg shadow-card bg-white;
        >.listMenu {
            @apply flex flex-col rounded-lg;
            >.menuItem {
                @apply w-full px-4 py-2 cursor-pointer hover:bg-blue-50;
                >.menuItem-content {
                    @apply flex items-center w-full gap-2 px-4 py-2;
                    > img {
                        @apply w-6 h-6;
                    }
                    >div {
                        @apply text-sm;
                    }
                }
            }
        }
    }
  }
}