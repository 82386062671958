.previewContent {
  min-height: 265px;
  padding-top: 39px;
  @apply relative flex flex-col justify-center items-center h-full w-full overflow-scroll  bg-white rounded-lg border-gray-200 border;
  &-header {
    z-index: 10;
    @apply flex w-full justify-between items-center bg-white  py-2 absolute -top-0.5 border-b border-gray-300 pb-2;
    .wrapper {
      @apply text-sm font-normal flex gap-1 items-center w-full max-w-[calc(100%-74px)] flex-nowrap  pl-4;
      >.image {
        @apply h-5 w-5 rounded-full;
      }
      >.title {
        @apply truncate text-ellipsis;
      }
    }
  }
  &-comment {
    @apply mr-4 mt-0.5 text-gray-500 hover:text-gray-600;
  }
  &-content {
    @apply flex min-h-full;
  }
}