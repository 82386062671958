.syllabusList {
    @apply w-full flex flex-col gap-8;
    &-info {
        @apply flex bg-[#E5F4FF] min-w-[260px] relative pl-5 pr-1 py-2 gap-2.5;

        &:before {
            content: '';
            border-bottom: 24px solid transparent;
            border-left: 19px solid #E5F4FF;
            border-top: 24px solid transparent;
            @apply -right-[18px] -top-[2px] absolute;
        }
    }
    &-action {
        @apply h-[44px] ml-auto bg-white flex items-center relative z-1 gap-1 pl-4 pr-4;
    }
    &-name {
        @apply text-gray-900 text-lg font-semibold text-left;
    }
    &-header {
        @apply relative w-full flex;

        &::before {
            content: '';
            @apply w-full top-[23px] h-[1px] left-0 absolute bg-primary-200;
        }
    }
    &-badge {
        @apply text-base font-semibold text-primary-500 min-w-[24px] h-6 bg-white flex items-center justify-center rounded-full mt-0.5;
    }
    &-content {
        @apply px-5 pt-5;
        p {
            @apply  text-gray-800 text-sm mb-2;

            >span {
                @apply  text-gray-500 font-semibold;
            }
        }
    }
}