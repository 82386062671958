.bookingDetails {
    &-header {
        @apply flex flex-col gap-2 rounded-lg p-4 bg-white;
    }
    &-title {
        @apply text-lg text-gray-800 font-semibold;
    }
    &-label {
        @apply text-sm text-dark-100 font-normal;
    }
    &-learner {
        @apply bg-blue-50 p-3 rounded-lg flex gap-2;

        &-avatar {
            @apply flex items-center justify-center flex-none w-10 h-10 rounded-full bg-cover;
        }
    }
    &-info {
        @apply flex flex-col w-full;
    }
    &-name {
        @apply text-gray-800 text-sm font-semibold;
    }
    &-available {
        @apply text-gray-800 text-xs font-semibold;
        
        span {
            @apply text-orange-500;
        }
    }
    &-sessions {
        @apply flex flex-col gap-4 rounded-lg p-4 bg-white mt-2;
    }
    &-date {
        @apply text-gray-800 text-sm font-semibold;
    }
    &-footer {
        @apply flex flex-col gap-2 rounded-lg p-4 bg-white mt-2;
        
        &-item {
            @apply flex justify-between;
        }
        &-label {
            @apply text-gray-800 text-sm font-normal;
        }
        &-value {
            @apply text-gray-800 text-sm font-semibold;
        }
        &-action {
            @apply border-t border-gray-300 pt-4;
        }
    }
    &-learners {
        @apply flex flex-col gap-2;
    }
}
.sessionsList {
    @apply flex flex-col gap-3;
    &-date {
        @apply mb-4;
    }
    &-header {
        @apply flex w-full items-center text-gray-800 text-sm font-semibold;
    }
    &-content {
        @apply flex flex-col px-7 py-2 gap-2;
    }
    &-label {
        @apply text-xs text-gray-500 mb-[1px] font-semibold;
    }
    &-value {
        @apply text-xs text-gray-800 font-normal;
    }
    &-user {
        @apply flex items-center gap-2;

        &-avatar {
            @apply w-6 h-6 rounded-full bg-gray-300 text-white flex items-center justify-center bg-cover;
        }
        &-name {
            @apply text-sm text-gray-900 font-normal;
        }
    }
    &-list {
        @apply border-b border-gray-300 pb-3;

        &:last-child {
            @apply border-0 pb-0;
        }
    }
    &-credit {
        @apply text-orange-500 font-semibold text-xs ml-2;
    }
    &-btn {
        @apply flex items-center text-primary-500 text-xs ml-2 gap-1; 
    }
    &-remove {
        @apply ml-auto hidden text-red-500 font-normal text-xs;

        .sessionsList-header:hover &{
            @apply block;
        }
    }
}