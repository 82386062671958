tbody tr td:first-child {
  /* background-color: red; */
  padding-top: 0;
  padding-bottom: 0;
}

th {
  padding-top: 0;
  padding-bottom: 0;
}

th {
  border-top: 1px solid rgba(209, 213, 219, 1);
  border-bottom: 1px solid rgba(209, 213, 219, 1);
}

.disable-selection {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.sticky-left {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #f9fafb; 
}

.sticky-left::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0px;
  width: 8px; 
  background-color: transparent; 
  box-shadow: 8px 0px 8px 0px #C7C9D93D; 
  z-index: -1; 
}

.sticky-right {
  position: sticky;
  right: 0;
  z-index: 1;
  background-color: white;
}

.table-cell-sticky {
  background-color: white !important;
}

tr:hover .table-cell-sticky {
  background-color: #f9fafb !important;
}

.table-cell-sticky::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 8px; 
  background-color: transparent; 
  box-shadow: 8px 0px 8px 0px #C7C9D93D; 
  z-index: -1; 
}