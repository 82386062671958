.card {
    @apply shadow-light rounded-2xl overflow-hidden transition;
  
    &-action:hover {
      @apply cursor-pointer;
    }
  
    &-light {
      @apply shadow-light-0;
    }
  }