.assigmentBuilderContent {
    min-height: 310px;
    height: calc(100vh - 48px);
    padding-bottom: 104px;
    @apply relative;

    &-main {
        @apply h-full px-6 py-5;
    }

    &-addNewLibrary {
        @apply absolute w-full left-0 bottom-0;
        
    }
}
.assigmentBuilderPreview {
    @apply rounded-lg shadow-card_common bg-white h-full relative pt-10;


    &--mobile {
        max-width: 375px;
        max-height: 667px;
        overflow-y: auto;
        @apply mx-auto;
        
        .previewContent-image {
            min-height: 0;
        }
    }

    &-header {
        @apply  border-b border-gray-300 px-5 py-2 h-10 absolute left-0 top-0 w-full
        flex items-center;

        .delete {
            @apply w-5 h-5 text-gray-500 hover:text-gray-600 ml-auto cursor-pointer;
        }
    }
    &-icon {
        @apply w-auto h-5;
    }
    &-title {
        @apply text-sm text-gray-800 ml-2;
    }
    &-preview {
        @apply h-full flex flex-col justify-center overflow-auto;

        .document {
            @apply w-full h-full;
        }
        .previewContent-image {
            @apply block  mx-auto p-4;

            .object-contain {
                margin: 0 auto;
            }
        }
        .audio {
            @apply flex items-center justify-center h-full;
        }
        .previewContent-h5p {
            @apply h-full;

            .h5p-viewer {
                max-width: 1024px;

            }
        }
    }
}