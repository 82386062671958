.confirmDeleteModal {
    &-header {
        @apply px-6 py-4 flex text-gray-900 text-base font-semibold;
    }
    &-close {
        @apply ml-auto w-6 h-6 rounded-full flex items-center justify-center hover:bg-blue-50 
        disabled:cursor-not-allowed
        transition;
    }
    &-container {
        @apply px-6 py-2 text-sm text-gray-800;
    }
    &-actions {
        @apply flex justify-center space-x-4 px-6 py-4;
    }
    &-loading {
        @apply flex items-center justify-between w-full;

        svg {
            @apply mr-0 ml-0;
        }
    }
}