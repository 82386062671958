.courseSkills {
    @apply border-t border-gray-300 pt-5;

    &-header {
        @apply flex justify-between items-center mb-5;
        h2 {
            @apply text-sm font-semibold text-gray-800;
        }
    }
}
.addSkillsModal {
    &-button {
        @apply text-xs text-primary-500 border border-primary-500 font-semibold px-3 py-1.5 flex items-center rounded
        hover:bg-blue-50 transition;
    }
}
.border-error {
    > div > div {
        @apply border border-red-500 rounded;
    }
}
