.previewContent {
    &-image {
        @apply w-full flex justify-center items-center;

        .mobile-view & {
            min-height: 667px;
        }
    }
    &-h5p {
        @apply w-full flex justify-center flex-wrap;

        > .h5p-viewer {
            @apply h-full w-full flex justify-center flex-wrap;
        }
        .mobile-view & {
            min-height: 667px;
        }
    }
    > .document {
        @apply w-full h-full flex justify-center items-center;

        .mobile-view & {
            min-height: 667px;
        }
    }
    .audio {
        @apply w-full;
    }
}
.largePreview {
    @apply bg-gray-600 rounded-lg shadow-sm w-[480px] px-6 py-4  m-auto flex items-center justify-center flex-col;

    .detail {
        @apply text-white text-center my-4;
    }
}

.no-render-file {
    @apply bg-gray-600 m-auto rounded-lg shadow-sm w-full max-w-[480px] px-6 py-4 flex items-center justify-center flex-col;
    > .detail {
        @apply text-lg text-center text-white;
    }
    > .btn-download {
        @apply mt-4 w-fit flex gap-1 items-center;
    }
}
