.courseOverview {
    @apply p-0 rounded-[16px] bg-white;

    &-title {
        @apply text-gray-800 text-lg font-semibold mb-4;
    }
    &-list {
        @apply flex flex-1 gap-5;
    }
    &-item {
        @apply w-full border border-gray-200 p-5 rounded-lg;
    }
    &-label {
        @apply text-gray-800 text-sm;
    }
    &-value {
        @apply text-[36px] text-gray-800;
    }
}
