.editProperty {
    min-height: calc(100vh - 310px);
    //min-height: 450px;
    // @apply flex;

    &-name {
        @apply bg-gray-50 font-semibold text-gray-900 px-4 py-2 border-b border-gray-300;
    }
    &-list {
        max-width: 288px;
        width: 30%;
    }
    &-content {
        @apply border-l border-gray-300 w-full;
    }
    &-header {
        @apply border-b border-gray-300 px-5 py-4 flex gap-2 text-xl text-gray-900 font-semibold items-center justify-between;
    }
    &-item {
        @apply flex flex-1 gap-5;
        > div {
            @apply flex-1;
        }
    }
}
.editPropertyOptions {
    @apply border-t border-gray-300 pt-5;

    &-header {
        @apply flex gap-4 text-gray-900 font-semibold text-base items-center;
    }
    &-content {
        @apply rounded-lg border border-gray-300 mt-5;
    }
    &-item {
        @apply w-full;
    }
    &-thead {
        @apply font-semibold text-sm text-gray-900 bg-gray-50 text-left;
        th {
            @apply py-2 px-4 border-t-0 sticky top-0 bg-gray-50  border-b border-gray-300;
        }
    }
    &-th {
        z-index: 11;
    }
    &-col {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        //z-index: 9;
        position: relative;
        @apply py-2 px-4  text-gray-800 font-normal text-sm text-left align-top;
    }
    &-input {
        @apply border border-gray-300 rounded px-2.5 py-1 font-normal h-[34px] text-sm;
    }
    &-footer {
        @apply flex border-t border-gray-300 p-2 font-semibold;
        .pagination {
            padding-right: 0 !important;
        }
    }
}
