.Members {
  &-main {
    @apply gap-x-2;
    .wrapper-content {
      @apply relative flex gap-2 flex-col items-stretch;
      >.scrollbar {
        @apply h-[calc(100vh-410px)] overflow-auto;
        >.loading {
          @apply flex items-center justify-center h-full;
        }
      }
      .pagination {
        @apply flex justify-end items-center opacity-100 mb-1 border-t;
      }
    }
  }
}