.emptyStage {
    min-height: calc(100vh - 48px);
    @apply w-full flex items-center;
    &.center-item {
        @apply flex-col justify-center items-center;
    }

    .nocontent-image {
        @apply max-w-xs w-full;
        
    }

    .nocontent-wrapper {
        @apply flex justify-center gap-2 flex-col mt-4;
        .title {
            @apply w-full text-center text-xl font-semibold;
        }
        .content {
            @apply w-full text-center;
        }
        .btn-create {
            @apply w-fit mt-2 flex items-center mx-auto;
            .icon {
                @apply w-5 h-5 mr-1;
            }
        }
    }

    &-list {
        @apply flex flex-col items-center w-full space-y-5;
    }
    &-item {
        @apply border border-transparent inline-block w-full overflow-hidden text-left relative bg-white px-6 py-5 rounded-xl transition
        hover:border-primary-500 hover:bg-blue-50;

        max-width: 500px;
        min-height: 96px;
        padding-left: 150px;
    }
    &-icon {
        width: 132px;
        height: 132px;
        top: -20px;
        left: -20px;
        @apply absolute rounded-full flex items-center justify-center;
    }
    &-title {
        @apply text-gray-900 font-semibold text-base;
    }
}