.Gradebook {
  @apply bg-gray-50 h-screen;
  &-Header {
    @apply h-12 border-b border-gray-300 bg-white flex justify-between items-center px-5 relative;
    &--headerIcon {
      @apply flex justify-start items-center gap-2 cursor-pointer;
      .background-icon {
        @apply w-8 h-8 rounded-full bg-gray-100 flex justify-center items-center text-base font-semibold;
        >.icon {
          @apply w-4 h-4;
        }
      }
    }
    &--title {
      @apply text-base font-semibold absolute left-1/2 -translate-x-1/2;
    }
    &--button {
      @apply flex gap-4
    }
  }
  &-Form {
    @apply h-auto w-full mt-5 px-5;
    &--section {
      @apply rounded-lg w-full bg-white p-5;
      >.label {
        @apply font-semibold w-full text-lg;
      }
      .Overview {
        @apply flex w-full gap-5 mt-4;
      }
      .Grade {
        @apply h-full w-full min-h-[calc(100vh-430px)] mt-4;
      }
    }
  }
}