.sessionList {
    &-main {
        .scrollbar{
            height: calc(100vh - 420px);
            @apply overflow-x-auto;
        }
        .loading {
            height: calc(100vh - 420px);
            @apply flex  justify-center items-center;
        }
    }
    &-filter {
        @apply px-5 py-4 border-b border-gray-300;
    }
    .wrapper-content {
        @apply mt-5;
    }
}