.newLeftMenu {
    @apply border-r border-gray-200 h-full flex-col transition;

    &--mobile {
        @apply flex md:w-64 md:fixed md:inset-y-0;
    }
    &--desktop {
        @apply  hidden md:flex md:w-64 md:fixed md:inset-y-0 z-60 h-full pb-2;
    }
    &--collapse {
        width: 68px;
    }
    &-item {
        @apply flex items-center cursor-pointer pr-3 py-4 text-xs font-semibold transition mt-1;

        .newLeftMenu--collapse & {
            @apply px-2 rounded-md py-3;
        }
    }
    &-menulist {
        .newLeftMenu--collapse & {
            @apply px-1;
        }
    }
}