.file {
  @apply flex gap-2 py-2 rounded items-center text-gray-800 px-2 mt-1 mb-2;
  .library-icon {
      @apply h-5 w-5;
  }
  .file-name {
      @apply text-sm font-normal w-full text-ellipsis truncate;
  }
  &.active {
       @apply text-primary-500 bg-blue-100;
  }
}
.file:hover {
  @apply text-primary-500 bg-blue-100;
}