.lessonHeader {
    @apply flex text-2xl text-gray-800 w-full items-center;
    &-dot {
        @apply bg-gray-200 rounded-full flex-none h-7 w-7 flex items-center justify-center;
        > div {
            @apply bg-gray-400 rounded-full h-4 w-4;
        }
    }
    &-action {
        @apply ml-auto w-[105px] flex flex-none justify-end;
    }
    &-badge {
        @apply bg-blue-50 rounded-full  p-1 text-base min-w-[32px] min-h-[24px] font-semibold
        flex items-center justify-center ml-3;
    }
    &-input {
        @apply w-full bg-blue-50;
    }
}