.assignmentGrade {
    &-header {
        @apply fixed top-0 left-0 w-full px-5 py-3 border-b border-gray-300 bg-white z-10 max-h-12;
    }
    &-container {
        padding-top: 48px;
        @apply flex bg-gray-50;
    }
    &-sideLeft {
        width: 300px;
        height: calc(100vh - 48px);
        @apply bg-white border-r border-gray-200 overflow-auto;
    }
    &-content {
        width: calc(100% - 300px);
    }
    &-learner {
        @apply bg-white py-4 px-5;
    }
    &-tab_content {
        @apply bg-white flex items-center px-5;
        button {
            @apply px-4 py-3 flex items-center space-x-1.5 text-sm text-gray-500 font-semibold;
        }
    }
    &-tab_content button.active {
        @apply text-enterprise relative;
    }
    &-tab_content button.active::before {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #0071ce;
    }
    &-tasks {
        height: calc(100vh - 268px);
        @apply p-5 overflow-auto flex flex-col space-y-5;

        &--isGrade {
            height: calc(100vh - 223px);
        }
    }
    &-waiting_for_grade {
        height: calc(100vh - 268px);
        @apply p-5 overflow-auto flex flex-col space-y-5;

        &--isGrade {
            height: calc(100vh - 223px);
        }
    }
}
.assignmentGradeHeader {
    @apply flex justify-between items-center;
    
    &-back {
        @apply flex items-center text-sm text-gray-800 hover:text-gray-700 font-semibold;
        
        span {
            @apply flex items-center justify-center w-8 h-8 bg-gray-100 hover:bg-gray-200 rounded-full mr-2 transition;
        }
    }
    &-name {
        @apply font-semibold text-base text-gray-800;
    }
}