.section-content-SupplementaryMaterial {
    @apply pl-1 pb-2;

    &-item {
        @apply flex mb-1 justify-between hover:bg-blue-100 hover:text-primary-500 rounded bg-white;

        &.active {
            @apply text-primary-500;
        }

        .flex-item {
            @apply flex p-2 gap-2 items-center w-full;
            max-width: calc(100% - 40px);

            .library-icon {
                @apply h-5 w-5;
            }

            .file-name {
                @apply text-sm font-normal w-full text-ellipsis truncate;
            }
        }
    }
}

.assignmentList {
    &-createBtn {
        @apply relative items-center text-base font-semibold text-primary-500 
        hover:text-primary-600 bg-white rounded px-4 py-2 w-full flex space-x-2 
        hover:bg-gray-50 border border-transparent hover:border-primary-500 
        hover:shadow-input transition cursor-pointer;
    }
}

.assignmentItem {
    @apply bg-white rounded px-2 h-10 flex items-center cursor-pointer mb-3;

    &--actived,
    &:hover {
        @apply bg-blue-100;
    }

    &-icon {
        width: 20px;
        height: 20px;
        @apply mr-2;

        .assignmentItem:hover & {
            @apply hidden;
        }
    }

    &-drag {
        @apply hidden mr-2 text-gray-500 w-5;

        .assignmentItem:hover & {
            @apply block;
        }
    }

    &-content {
        @apply flex items-center;
    }

    &-name {
        @apply text-gray-800 text-sm grid;

        .assignmentItem--actived & {
            @apply text-primary-500;
        }
    }

    &-delete {
        @apply mr-2 ml-auto text-gray-500 hidden;

        .assignmentItem:hover & {
            @apply block;
        }
    }

    &-input {
        min-width: 40px;
        max-width: 280px;
        @apply focus:bg-primary-500/30 focus:text-primary-500 bg-transparent;
    }
}