.dropdownAsync--icon {
    @apply h-4 w-4 mr-2 text-primary-500;
}

.filterAssignmentCourse {
    @apply w-full flex flex-wrap gap-2 items-center;
    &-searchInput {
        max-width: 240px !important;
        @apply w-full text-sm max-h-9;
    }
}
