.ProgressUploadFile {
    @apply fixed bottom-4 right-16 z-9999 bg-white  rounded-lg overflow-hidden shadow-lg;
    &-card {
        width: 320px;
        max-height: 384px;
        @apply p-5 overflow-auto;
    }
    &-sticky {
        @apply sticky top-0 z-30;
    }
    &-item {
        @apply flex  items-center justify-between;
        .itemUpload {
            @apply flex gap-1 items-center py-3 font-normal text-base text-gray-900 ;
        }
        .status-upload {
            @apply w-8;
        }
    }
    &-header {
        @apply flex justify-between items-center mb-2;
        .title {
            @apply flex  gap-3 text-gray-900 font-semibold text-base items-center;
        }
    }
}
