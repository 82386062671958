.btn-reset {
  @apply text-primary-500 px-0 border-none text-sm font-normal disabled:opacity-50 disabled:bg-white disabled:text-gray-800;
}
.line {
  @apply h-[27px] w-[1px] bg-gray-200;
}

.dropdownAsync--icon {
  @apply h-4 w-4 mr-2 text-primary-500
}

.filterBar {
  @apply w-full flex flex-wrap gap-2 items-center relative z-10; 
  .sessionFilter {
    &-searchInput {
      @apply w-full text-sm max-h-9;
      &.width-280 {
        width: 280px;
      }
      &.width-420 {
        width: 420px;
      }
    }
  }
  
  &-roleLocation {
    @apply border h-9 border-gray-300 p-1 rounded text-xs font-semibold flex gap-1;
    >.btn-default {
      @apply cursor-pointer w-28 hover:bg-blue-50 rounded hover:text-primary-500 flex justify-center items-center p-1;
    }
  }
}
