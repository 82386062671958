tbody tr td:first-child {
    /* background-color: red; */
    padding-top: 0;
    padding-bottom: 0;
}

th {
    padding-top: 0;
    padding-bottom: 0;
}

th {
    border-top: 1px solid rgba(209, 213, 219, 1);
    border-bottom: 1px solid rgba(209, 213, 219, 1);
    /* padding-top: 12px;
    padding-bottom: 12px; */
}
