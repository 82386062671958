.h5p-information {
  @apply fixed top-0 bottom-0 right-0 w-[520px] bg-white shadow-sm z-30;
  > .header {
    @apply flex px-4 py-4 h-16 justify-between border-b border-gray-300;
    .header-title {
      @apply text-gray-800 text-xl font-semibold flex items-center gap-2;
      .title {
        @apply max-w-[280px] truncate text-ellipsis;
      }
    }
    .action {
      @apply flex gap-3 items-center;
      .action-icon {
        @apply text-gray-800 w-6 h-6;
      }
    }
  }
  .detail {
    @apply rounded-lg bg-gray-50 p-3 m-5;
    > .header {
      @apply flex items-center;
      .label {
        @apply text-sm font-semibold text-black;
      }
       .line {
        height: 1px !important;
        @apply border-t border-gray-300 w-full mx-3;
      }
    }
    > .item {
      @apply mt-5 flex items-center gap-1;
      > .item-label {
        @apply text-sm font-semibold text-gray-500;
      }
      > .item-value {
        @apply flex items-center gap-1 truncate;
        > .value {
          @apply text-sm font-normal flex truncate;
          >.path {
            @apply text-sm flex ml-1;
            >.path-name {
              &.root {
                @apply text-gray-500;
              }
              @apply text-sm font-semibold flex gap-1 items-center text-gray-800;
              .icon {
                @apply w-4 h-4 text-gray-400 mr-1;
              }
            }
          }
        }
        > .avatar{
          width: 28px !important;
          height: 28px !important;
          @apply rounded-full overflow-hidden bg-gray-300 ml-0;
        }
      }
    }
    > .list-customfield {
      @apply max-h-[calc(100vh-450px)] overflow-auto flex flex-col gap-y-5 mt-5;
    }
  }
  &-container {
    max-height: calc(100vh - 120px);
    @apply overflow-y-auto;
  }
  &-tags {
    @apply px-5 mt-10;
  }
}