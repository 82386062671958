.AssignmentRecording {
  @apply w-full px-4;
  &-title {
    @apply absolute top-0 z-10 left-0 w-full bg-white px-5 py-3 text-xl text-gray-800 font-semibold flex justify-between border-b border-gray-300;
    .btnDelete {
      @apply text-gray-500 cursor-pointer;
    }
  }
  &-information {
    @apply max-h-[calc(100%-40px)] h-full overflow-auto;
    .wrapper-content {
      @apply border border-blue-300 bg-blue-50 p-3 rounded-xl flex gap-2 items-center pr-10 relative;
      >.icon {
        @apply text-primary-500 w-6 h-6;
      }
      >.text {
        @apply text-sm;
      }
    }
    >form {
      @apply mt-4;
    }
    >.preview {
      @apply mt-5 w-full;
      .label {
        @apply text-sm font-semibold text-gray-800;
      }
      .wrapper-preview {
        @apply mt-2 rounded-lg bg-gray-100 p-5;
        .title {
          @apply text-xl font-semibold text-gray-800;
        }
        .action {
          @apply flex items-center text-xs text-gray-400 cursor-pointer;
          .icon {
            @apply w-3 h-3 text-gray-400;
          }
        }
        .note {
          blockquote {
              overflow: hidden;
              padding-right: 1.5em;
              padding-left: 1.5em;
              margin-left: 0;
              margin-right: 0;
              font-style: italic;
              border-left: solid 5px hsl(0, 0%, 80%);
          }
          
          li {
            background-color: unset !important;
          }
          ul,
            ul li {
                list-style-type: inherit;
            }
          
          ul {
              padding-left: 28px;
          }
        
          ol,
          ol li {
              list-style-type: decimal;
          }
          ol {
            padding-left: 28px;
          }
        
          h2 {
              font-size: 24px !important;
              font-weight: bolder;
            }
          
          h3 {
              font-size: 18.72px !important;
              font-weight: bolder;
          }
          h4 {
              font-size: 	16px !important;
              font-weight: bolder;
          }
        
          p {
            font-size: 16px;
          }
        
          a:-webkit-any-link {
            color: -webkit-link;
            cursor: pointer;
            text-decoration: underline;
          }
        }
        
      }
    }
    
  }
}

