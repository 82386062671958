.BtnNext {
  @apply absolute w-8 h-8 -right-4 top-1/2 -translate-y-1/2 rounded-full transition-all shadow-lg z-10 bg-white/70;
  .icon {
    @apply text-primary-500 w-4 h-4 m-auto
  }
}
.BtnPre {
  @apply absolute w-8 h-8 -left-4 top-1/2 -translate-y-1/2 rounded-full transition-all shadow-lg z-10 bg-white/70;
  .icon {
    @apply text-primary-500 w-4 h-4 m-auto
  }
}

.Banner {
  @apply  py-6;
  &-cover {
    @apply m-auto px-5;
    .banner-img {
      @apply cursor-pointer w-full aspect-[5/1] object-cover rounded-2xl;
    }
  }
  .slick-dots {
    @apply bottom-5;
    li {
      @apply bg-transparent;
    }
  }
}

