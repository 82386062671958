.close-icon {
    top: 25%;
    right: 2%;
}

.error-form {
    font-size: 12px;
    color: red;
    font-weight: 500;
    position: absolute;
    bottom: 5%;
}

.input-form {
    border: 2px solid #e0e3f5;
}

.input-focus {
    border: 2px solid #3e7eff;
}

.input-error {
    border: 2px solid #fe3f56 !important;
}

.ckeditor__custom {
    .ck {
        .ck-editor__top .ck-toolbar {
            border-color: #EEF1FA;
            border-radius: 4px 4px 0 0 !important;

            // &:focus-within {
            //     border-color: #2684FF;
            // }
        }

        .ck-editor__main .ck-content {
            border-color: #EEF1FA;
            border-radius: 0 0 4px 4px !important;

            &:focus-within {
                border-color: #2684FF;
            }
        }

        &:hover .ck-editor__top .ck-toolbar,
        &:hover .ck-editor__main .ck-content {
            border-color: #B3B3B3;
        }
    }

    &.ckeditor__custom_error {
        .ck {

            .ck-editor__top .ck-toolbar,
            .ck-editor__main .ck-content {
                border-color: #EF4444;
            }
        }
    }

    .ck-placeholder {
        @apply text-gray-400 text-sm;
    }
}

.PhoneInput-container {
    .PhoneInput-custom {
        .PhoneInputCountry {
            border: 1px solid #d1d5db;
            border-radius: 4px;
            max-height: 38px;
            padding: 2px 10px;
            &:hover {
                border-color: #B3B3B3;
            }
            &:focus {
                border-color: #2684FF;
            }
            .PhoneInputCountryIcon {
                width: 26px;
                height: 16px;
                &.PhoneInputCountryIcon--border {
                    box-shadow: unset;
                    background-color: unset;
                }
                .PhoneInputCountryIconImg {
                    // border: 1px solid #d1d5db;
                    // width: auto;
                }
            }

        }
        .PhoneInputInput {
            border: 1px solid #d1d5db;
            border-radius: 4px;
            padding: 6px 10px;
            font-size: 12px;
            &:hover {
                border-color: #B3B3B3;
            }
            &:focus {
                border-color: #2684FF;
            }
        }
        &.PhoneInput-custom-error {
            .PhoneInputCountry {
                border-color: #ef4444;
            }
            .PhoneInputInput {
                border-color: #ef4444;
            }
        }
    }
}