.custom-calendar  {
     .fc-event-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .fc .fc-popover {
        z-index: 10;
    }

    .fc-day-other {
        opacity: 0.5;
        pointer-events: none;
    }

    .fc-more-link {
        color:white;
        margin: 8px;
    }
    &.booking-calendar {
        .fc-button-group{
            padding-top: 5px;
        }
        .fc-toolbar-title {
            padding-top: 2px !important;
        }
    }
}

.calendar-wrapper {
    .upcoming-section {
        max-height: calc(100vh - 200px);
        min-height: 490px;
        overflow-y: auto;
    }

    .fc .fc-header-toolbar .fc-toolbar-chunk:first-child {
        width: 50%;
        display: flex;
    }

    .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary:first-letter {
        text-transform: uppercase;
    }

    .fc .fc-header-toolbar .fc-toolbar-chunk .fc-today-button {
        border: 1px solid #2e3a59;
        color: #2E3A59;
        font-size: 12px;
        font-weight: 600;
        background-color: transparent;
        height: 32px;
        border-radius: 4px;
    }

    .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button {
        color: #8F90A6;
        font-size: 12px;
        font-weight: 600;
        background-color: transparent;
        height: 32px;
    }

    .fc .fc-header-toolbar .fc-toolbar-title {
        font-weight: 600;
        color: #282b5e;
        padding-top: 5px;
        font-size: 16px;
    }

    .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-timeGridDay-button,
    .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-timeGridWeek-button,
    .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-dayGridMonth-button {
        color: #8f90a6;
        border: none;
    }

    .fc .fc-button-primary:focus,
    .fc .fc-button-primary:not(:disabled):active:focus,
    .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
        box-shadow: none !important;
    }

    .fc .fc-header-toolbar .fc-button-group .fc-button.fc-button-active {
        min-width: 60px;
        background-color: #E5E7EB;
        color: #1F2937 !important;
        border-radius: 8px;
    }

    .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-next-button,
    .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-prev-button {
        background-color: #1F2937;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #fff !important;
        padding: 0;
        border: none;
        display: flex;
        justify-items: center;
        align-items: center;
    }

    .fc-theme-standard .fc-scrollgrid {
        border: 1px solid #e4e6e8;
        border-radius: 8px;
        overflow: hidden;
    }

    .fc-theme-standard th {
        color: #8f90a6 !important;
        font-size: 12px;
        font-weight: 600;
        height: 50px;
        background-color: #F2F5FA;
    }

    .fc-theme-standard th>div {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .fc .fc-daygrid-day-top {
        justify-content: center;
    }

    .fc .fc-daygrid-day-top>a {
        font-size: 12px;
        font-weight: 600;
    }

    .fc .fc-timegrid-slot-label-cushion,
    .fc .fc-timegrid-axis-cushion {
        font-size: 12px;
        font-weight: 600;
        color: #2e3a59;
    }

    .fc .fc-h-event,
    .fc .fc-popover-body .fc-event {
        color: #2e3a59;
        background-color: #cce8ff;
        border-color: #cce8ff;
        padding: 0 8px !important;
        border-radius: 8px !important;
        margin-bottom: 4px;
        font-size: 9px;
    }

    .fc .fc-h-event .fc-event-title,
    .fc .fc-daygrid-more-link {
        font-size: 9px;
        font-weight: 700;
        color: #2e3a59;
    }

    .fc .fc-daygrid-more-link {
        font-size: 10px;
        margin-left: 5px;
    }

    .fc-popover-body .fc-daygrid-event-dot,
    .fc-popover-body .fc-event-time {
        display: none !important;
    }

    .fc .fc-daygrid-body .fc-daygrid-day.fc-day-today {
        background-color: transparent;
    }

    .fc .fc-timegrid-body .fc-timegrid-col.fc-day-today {
        background-color: #f2f9ff;
    }

    .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #2e3a59;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .fc .fc-view .fc-h-event .fc-event-title,
    .fc .fc-daygrid-more-link {
        font-size: 8px;
    }

    .fc .fc-event-selected,
    .fc .fc-event:focus {
        box-shadow: none;
    }

    .fc .fc-col-header th {
        border-color: transparent;
    }

    .fc .fc-dayGridMonth-view .fc-daygrid-dot-event .fc-daygrid-event-dot,
    .fc .fc-dayGridMonth-view .fc-daygrid-dot-event .fc-event-time,
    .fc .fc-dayGridMonth-view .fc-event .fc-event-time {
        display: none;
    }

    .fc .fc-dayGridMonth-view .fc-event {
        height: 15px;
        font-size: 8px;
        font-weight: 600;
        background-color: #EEF1FA;
        border-radius: 20px;
        padding: 1px 8px;
        cursor: pointer;
        @apply font-inter truncate text-ooolab_dark_1;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .fc .fc-dayGridMonth-view .fc-daygrid-event-harness {
        padding-left: 5px;
        padding-right: 5px;
    }

    .fc .fc-dayGridMonth-view .fc-event .fc-event-title,
    .fc .fc-popover-body .fc-event .fc-event-title {
        padding: 0;
    }

    .fc .fc-dayGridMonth-view .fc-event:hover,
    .fc .fc-dayGridMonth-view .fc-event:hover .fc-event-title,
    .fc .fc-popover-body .fc-event:hover,
    .fc .fc-popover-body .fc-event:hover .fc-event-title {
        background-color: #cce8ff;
        color: #2e3a59;
    }

    .fc .fc-dayGridMonth-view .fc-event:hover {
        outline: none;
        border: none;
    }

    .fc .fc-view.fc-timeGridWeek-view,
    .fc .fc-view.fc-timeGridDay-view {
        border-radius: 8px;
        @apply shadow-ooolab_box_shadow_container;
    }

    .fc .fc-view.fc-timeGridWeek-view .fc-scrollgrid,
    .fc .fc-view.fc-timeGridDay-view .fc-scrollgrid {
        border-radius: 8px;
        border: none;
    }

    .fc .fc-view .fc-timegrid-event {
        background-color: #EEF1FA;
        box-shadow: none;
        color: #2e3a59;
        border: none;
        border-radius: 8px;
        padding: 8px;
        margin: 2px;
        cursor: pointer;
        @apply transition;
    }

    .fc .fc-dayGridMonth-view .fc-h-event {
        padding: 0;
    }

    .fc .fc-view .fc-timegrid-event:hover {
        background-color: #cce8ff;
    }

    .fc .fc-view .fc-timegrid-event .fc-event-time,
    .fc .fc-view .fc-timegrid-event .fc-event-title {
        color: #2e3a59;
        @apply transition;
    }

    .fc .fc-view .fc-timegrid-event .fc-event-time {
        white-space: normal;
    }

    // .fc .fc-view .fc-timegrid-event:hover .fc-event-time,
    // .fc .fc-view .fc-timegrid-event:hover .fc-event-title {
    //     color: #fff;
    // }

    .fc .fc-timegrid-slot {
        border: 2px solid #eef1fa;
    }

    .fc .fc-timegrid-slot-minor.fc-timegrid-slot {
        border: 1px solid #eef1fa;
    }

    .fc .fc-view .fc-timegrid-event .fc-event-title {
        @apply font-inter text-xs font-semibold text-ooolab_dark_1;
    }

    .fc .fc-dayGridMonth-view {
        border: 1px solid #eef1fa;
        border-radius: 8px;
        box-shadow: none;
        overflow: hidden;
        width: calc(100% - 1px);
        height: calc(100% - 1px);
    }

    .fc .fc-dayGridMonth-view .fc-scrollgrid {
        border: none;
    }

    .fc .fc-scrollgrid-section {
        border-width: 0;
    }
    .fc .fc-view.fc-timeGridDay-view .fc-timegrid-event,
    .fc .fc-view.fc-timeGridWeek-view .fc-timegrid-event {
        padding-top: 2px;
        padding-bottom: 2px;
        .fc-event-main-frame {
            justify-content: center;
        }
        .fc-event-title-container {
            display: none;
        }
    }
}