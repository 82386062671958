.lessonBuilderSidebar {
    &-header {
        @apply flex items-center px-4 py-3 text-sm font-semibold text-gray-900 cursor-pointer;
        &--active {
            @apply bg-primary-500 text-white;
        }
        &.showError {
            @apply border border-red-500 rounded;
        }
    }
    &-arrow {
        @apply w-6 h-6 flex items-center justify-center transition;

        .lessonBuilderSidebar-header--active & {
            @apply rotate-90;
        }
    }
    &-content {
        @apply bg-blue-50 p-4;
    }
    &-icon {
        @apply mr-1 ml-2;
        
        .lessonBuilderSidebar-header--active & {
            @apply text-white;
        }
    }
}
.error {
    @apply text-sm text-red-500 ml-6 my-1;
}