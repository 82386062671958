.courseSideLeft {
    &-label {
        @apply text-xs text-gray-800 mb-1;
    }
    &-nav {
        max-height: calc(100vh - 440px);
        min-height: 320px;
        @apply mt-5 pt-5 border-t border-gray-300 overflow-y-auto;

    }
    &-item {
        @apply mb-5 flex gap-2 text-sm;

        &-link {
            @apply text-primary-500 hover:underline cursor-pointer;
        }
    }
}