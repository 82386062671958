.learningPathInformation {
    &-item {
        @apply mb-4 relative;

        .error {
            @apply text-[10px] text-red-500 mt-0.5 block;
        }
    }
    &-label {
        @apply text-gray-800 mb-1 text-xs font-semibold;
    }
    &-input {
        max-height: 32px;
        @apply text-gray-800 text-sm font-normal border border-gray-300 bg-white w-full px-2.5 py-1.5 rounded;
    }
    &-textarea {
        @apply text-gray-800 text-sm font-normal border border-gray-300 bg-white w-full px-2.5 py-1.5
        h-[92px] rounded relative pb-4 focus:outline-none;
    }
    &-sub {
        @apply text-[10px] text-gray-500 absolute right-[3px] bottom-[7px] bg-white p-1;
    }
    &-disclosure {
        @apply border-t border-gray-300 pt-4 mb-4;
    }
    &-tags {
        @apply border-t border-gray-300 pt-4;
    }
    &-info {
        @apply text-xs text-gray-500 mt-2 leading-5 font-normal overflow-y-auto;

        button {
            @apply text-primary-500 underline hover:no-underline;
        }
        .taglist {
            @apply pt-0;
        }
        .tagItem {
            @apply px-0 mt-0 pb-3;
        }
        &--hasData {
            max-height: 180px;
        }
    }
    &-loading {
        min-height: 120px;
        @apply flex items-center justify-center;
    }
}