.unitContent {
    @apply flex flex-col gap-4;
    &-header {
        @apply flex items-center py-[9px] px-3;
    }
    &-name {
        @apply ml-1 text-gray-800 text-sm font-semibold;
    }
    &-action {
        @apply ml-auto z-1;
    }
    &-item {
        @apply border border-gray-300 rounded-lg;
    }
    &-content {
        @apply bg-gray-50 pl-[48px] py-4 pr-2;

        &-item {
            @apply flex gap-4;
        }
    }
    &-label {
        @apply w-[72px] flex-none text-gray-800 text-xs;
    }
    &-value {
        @apply flex text-gray-800 text-xs gap-4;

        &-item {
            @apply flex gap-1;
        }
    }
    &-info {
        @apply flex items-center gap-4;
    }
    &-scorm {
        @apply flex gap-1 px-2.5 py-1 rounded border border-gray-300 bg-gray-50 text-xs text-gray-500;
    }
}