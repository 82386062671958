.CreateInvoice .due_at .react-datepicker {
  display: flex;
 }

 .CreateInvoice .due_at .react-datepicker__header--time {
  height: 64px;
 }

 .CreateInvoice {
  &-grid {
    @apply mt-5 grid grid-cols-2 gap-5;
  }
  .react-datepicker__navigation--next {
    right: 20px;
  }
 }

 .react-datepicker {
  width: fit-content;
 }

 .react-datepicker__time-container {
  border: none;
 }

 .CreateInvoice .react-datepicker__navigation--next {
  right: 86px;
 }

 .CreateInvoice .schedule-date .react-datepicker__navigation--next {
  right: 0 !important;
 }
 