.courseCertificate {
    @apply p-5;
    &-title {
        @apply text-lg font-semibold text-dark-800;
    }
    &-tablist {
        @apply w-full text-xs flex gap-4 items-center border-b border-gray-300;
        .tagItem {
            @apply py-1 text-center font-semibold text-xs flex focus:outline-none;
            &.active {
                @apply text-primary-500 border-b-2 border-primary-500;
            }
            &.disable {
                @apply text-gray-500;
            }
        }
    }
    &-main {
        @apply gap-x-2;
        .wrapper-content {
            @apply relative flex gap-2 flex-col items-stretch;
            > .scrollbar {
                @apply h-[calc(100vh-380px)] overflow-auto;
                > .loading {
                    @apply flex items-center justify-center h-full;
                }
            }
        }
    }
}
