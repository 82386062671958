.settings {
    @apply grid grid-cols-2 gap-6;
    &-noContent {
        @apply text-base text-gray-500 text-center w-full font-normal;
    }
    &-list {
        @apply flex flex-wrap;
    }
    &-input {
        @apply text-sm  text-gray-800 py-[5px] px-2.5 border border-gray-300 rounded w-full;
    }
}
.taxRate {
    &-header {
        @apply text-sm text-gray-800 font-semibold grid grid-cols-2 gap-2 px-4 py-2 bg-gray-50 border-t border-t-gray-300 border-b border-b-gray-300;
    }
    &-item {
       @apply text-sm text-gray-800 font-normal grid grid-cols-2 gap-2 px-4 py-2;
    }
}
