.teachingHistoryDashboard {
    &-main {
        @apply pt-4 px-5;
        .teachingHistoryList {
            @apply h-[calc(100vh-330px)] w-full;
        }
    }
    &-header {
        @apply flex items-center justify-between py-[18px] px-5 border-b border-gray-300;
    }
    &-title {
        @apply text-xl text-gray-800 font-semibold;
    }
    &-date {
        @apply min-w-[242px] relative;
    }
    
}
