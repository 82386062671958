.File {
  @apply border border-gray-300 rounded-lg p-3 flex justify-between shadow-sm;
  &-Info {
    @apply flex items-center gap-2 cursor-pointer;
    &--Name {
      @apply text-sm pr-2 text-primary-500 max-w-[293px] truncate;
    }
    &--Detail {
      @apply text-xs flex gap-2 text-gray-500;
    }
  }
  &-Action {
    @apply border-l border-gray-300 pl-2 text-gray-500 flex gap-2 items-center;
    &--Icon {
      @apply cursor-pointer flex-none;
    }
  }
}