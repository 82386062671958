.loadingPopup {
    @apply fixed bottom-4 right-16 z-9999 bg-white  rounded-lg overflow-hidden shadow-lg;
    &-card {
        width: 240px;
        max-height: 384px;
        @apply p-5 overflow-auto;
    }
    &-sticky {
        @apply sticky top-0 z-30;
    }
    &-item {
        @apply flex gap-1 font-normal text-base text-gray-900 items-center;
    }


}