.LessonPublicPreview {
    @apply relative  bg-white;
    ::-webkit-scrollbar {
        display: none;
    }
    .header {
        @apply h-12 w-full fixed top-0 justify-between z-30;
        &-container {
            @apply h-12 w-full bg-white border-b border-gray-300 items-center justify-center px-5;
        }
        &-icon {
            @apply cursor-pointer flex items-center gap-2 text-base font-semibold;
            .background-icon {
                @apply bg-gray-200 p-1 rounded-full w-fit;
                .icon {
                    @apply w-5 h-5 text-gray-500 hover:text-gray-900;
                }
            }
        }
        &-title {
            @apply font-semibold text-base truncate text-ellipsis;
        }
        &-group-icon {
            @apply flex gap-4;
            .sub-icon {
                @apply bg-gray-200 flex justify-center items-center cursor-pointer rounded-full w-8 h-8 text-gray-500 hover:text-gray-900;
            }
        }
    }
    .main-content {
        @apply mt-[48px] relative m-auto;

        &.zoom {
            padding: 0;
        }

        .file-container {
            min-height: calc(100vh - 112px);
            //max-width: 970px;
            @apply flex justify-center mx-auto w-full flex-wrap;

            .h5p-viewer > div {
                padding-bottom: 70px;
            }
            .document {
                width: 100%;
                height: 100%;
            }
            .audio {
                @apply m-auto w-full h-10 flex justify-center;
            }

            &.zoom {
                height: calc(100vh - 30px) !important;
                @apply lg:w-[100vw]  overflow-scroll px-4;

                .document {
                    width: 100%;
                    height: 100%;
                    min-height: 100vh;
                }
                .previewContent-image {
                    height: auto;
                }
            }
            &-loading {
                @apply absolute inset-0 flex justify-center items-center bg-white;
            }
            .previewContent-image {
                min-height: 0;
                height: auto;
            }
            #pdf-viewer-container {
                height: calc(100vh - 80px) !important;
            }
        }
        .no-data {
            @apply flex items-center;
        }
    }
    .navigation {
        bottom: 0px;
        @apply fixed z-10 left-1/2 -translate-x-1/2 h-16 w-full;

        &-container {
            @apply py-3 justify-center gap-2 px-8;
        }
        &-dropdown {
            @apply h-10 bg-white w-[300px] text-gray-500 border border-gray-300 rounded-lg p-2 flex items-center truncate text-ellipsis gap-2 shadow-card_common;
            &-button {
                @apply flex justify-between items-center w-full;
                .file-name {
                    @apply text-ellipsis text-base truncate w-full text-left;
                    max-width: 225px;
                }
                .icon {
                    @apply w-4 h-4 text-gray-500;
                }
            }
        }
        &-panel {
            @apply absolute z-10 bg-white rounded shadow-md w-full bottom-full max-h-96 overflow-scroll;
            .lesson {
                @apply sticky top-0 px-4 py-3 text-sm text-gray-500 bg-white truncate text-ellipsis;
                &-item {
                    @apply cursor-pointer p-2 pl-10 text-sm font-normal hover:bg-gray-50 flex items-center justify-between;
                    > .name {
                        max-width: calc(100% - 40px);
                        @apply w-full truncate text-ellipsis;
                    }
                    &.active {
                        @apply text-white bg-blue-300;
                    }
                }
            }
        }
        &-button {
            @apply h-10 bg-white cursor-pointer border border-gray-300 rounded-lg p-2 flex items-center w-fit gap-2 shadow-card_common text-gray-400 pointer-events-none;
            .active {
                @apply pointer-events-auto text-gray-500 hover:text-gray-900;
            }
            .line {
                @apply h-full w-[1px] bg-gray-300;
            }
            .index-page {
                @apply border border-gray-300 px-3 py-0.5 text-sm text-gray-800 rounded;
            }
            .total-slide {
                @apply text-gray-700;
            }
        }
        .button-zoom {
            @apply h-10 w-10 bg-white cursor-pointer border text-gray-500 hover:text-gray-900 border-gray-300 rounded-lg p-2 flex items-center shadow-card_common;
        }
    }
    .h5p-viewer {
        @apply items-center flex-wrap;
    }
}
