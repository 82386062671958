.CourseList {
  &-filter {
    @apply flex justify-between items-center my-5 mx-4;
    .PlusIcon {
      @apply h-5 w-5;
    }
  }
  &-main {
    @apply gap-x-2  border-t border-gray-200 pt-5;
    .wrapper-content {
      @apply relative flex gap-2 flex-col items-stretch mx-4;
      >.scrollbar {
        @apply h-[calc(100vh-220px)] overflow-auto;
        >.loading {
          @apply flex items-center justify-center h-full;
        }
      }
      .paging {
        @apply flex justify-between items-center opacity-100 mb-1 border-t;
        >.switch-view {
          @apply w-1/4 h-full flex items-center ml-3;
        }
      }
    }
  }
}