.booking {
    &-container {
        @apply min-h-[calc(100vh-140px)] p-6 bg-gray-50;
    }
    &-content {
        @apply flex gap-5;
    }
    &-details {
        @apply xl:w-[360px] w-[250px]  flex-none;
    }
    &-resource {
        @apply w-full;
    }
    &-instructor {
        @apply mt-5 bg-white rounded-lg;
    }
    &-loading {
        @apply flex w-full items-center rounded-lg bg-white h-[calc(100vh-180px)] justify-center;
    }
}