.avatar {
  @apply w-7 h-7 min-w-[28px] border rounded-full border-light-100 -ml-3;
}
.status {
  @apply text-sm font-normal border rounded px-3 py-0.5 inline-block capitalize;
  &.active {
    @apply border-primary-300 bg-primary-50 text-primary-500;
  }
  &.expired {
    @apply border-orange-300 bg-orange-50 text-orange-500;
  }
  &.enrolled {
    @apply border-green-300 bg-green-50 text-green-500;
  }
  &.deactivate {
    @apply border-gray-300 bg-gray-50 text-gray-500;
  }
}