.tabTrash {
    &-header {
        @apply border-b py-4 px-5 flex justify-between outline-none;
    }
    &-headerItems {
        @apply w-full flex flex-wrap gap-2 items-center justify-between;
    }
    &-headerSearch {
        font-size: 14px !important;
        @apply w-full max-w-md;
    }
    &-tableLoading {
        height: calc(100vh - 275px);
        @apply flex items-center justify-center mt-4;
    }
    &-tableBody {
        height: calc(100vh - 275px);
        @apply  overflow-y-scroll;
    }
    &-pagination {
        @apply flex items-center justify-between border-t border-ooolab_bar_color;
    }
    &-paginationCount {
        @apply text-ooolab_dark_2 text-ooolab_xs font-semibold leading-6 hidden lg:block pl-5;
    }
    &-modalTitleStyle {
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #111827 !important;
    }
    &-modalStyles {
        @apply max-w-md;
        &--bodyTitle {
            @apply text-gray-800 text-sm;
        }
        &--bodyButton {
            @apply flex gap-4 justify-center items-center mt-5
        }
    }
}
