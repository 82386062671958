.react-datepicker-custom-container {
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      .react-datepicker__close-icon {
        right: 22px;
        &::after {
          background-color: unset;
          color: #1f2937;
          font-size: 20px;
        }
      }
    }
  }
}