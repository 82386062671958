.courseBuilder {
    &-main {
        @apply  bg-gray-50 overflow-y-auto flex p-5 gap-5;
    }
    &-side {
        @apply w-[320px] flex-none bg-white p-5 sticky rounded-lg top-0;
    }
    &-content {
        width: calc(100% - 340px);
        height: calc(100vh - 175px);
        @apply overflow-auto;
    }
    &-container {
        @apply bg-white rounded-lg h-full;
    }
    &-title {
        @apply text-lg text-gray-800 font-semibold mb-5;
    }
}