.emptyStateChart {
    @apply text-center;

    &-img {
        width: 210px;
        @apply mx-auto;
    }
    &-text {
        @apply text-base text-gray-500;
    }
}