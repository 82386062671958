.detailsFilePopup {
    &-tags {
        @apply px-5 mt-10;
    }
    &-line {
        height: 1px;
        @apply bg-gray-300 w-full;
    }
}

//details

.imageCover {
    @apply mb-10 mx-5 mt-5 bg-gray-50 h-56 flex items-center justify-center rounded-lg;
    > img {
        @apply w-1/4 h-auto;
    }
}

.content-item {
    @apply text-sm font-semibold text-gray-500 pb-3 flex gap-1;
    .avatar {
        @apply  items-center flex w-full;
        &-img {
            @apply mr-1 flex-none w-6;
            > img {
                @apply h-6 w-6 rounded-full
            }
        }
    }
}
.itemLabel {
    @apply mr-3 flex items-center;
}
.content-value {
    @apply text-gray-800 font-normal;
}
.item-center {
    @apply flex items-center;
}

//materials

.lessonTab {
    @apply w-full sticky top-0 z-30 bg-white flex  border-b pt-8;
    &-item {
        cursor: pointer;
        @apply pb-2 w-1/2  border-b font-semibold text-xs text-center;
    }
    &-active {
        @apply text-primary-500  border-primary-500;
    }
    &-default {
        @apply text-gray-500 border-gray-300;
    }
}

.lessonGoal {
    @apply flex items-center text-sm font-semibold text-gray-500 pb-3;
    > .label {
        @apply w-24;
    }
    > .value {
        @apply text-gray-800 font-normal truncate w-full ml-1;
    }
}

.sectionList {
    @apply px-3 py-2 border border-gray-200 rounded-lg bg-white mt-4;
    &-title {
        max-width: calc(100% - 50px);
        @apply text-sm font-semibold text-ellipsis truncate;
    }
    &-file {
        @apply text-sm;
    }
    &-item {
        @apply flex gap-1 items-center py-2;
         > .icon {
            @apply w-5 h-5;
        }
        > .content-name {
            @apply text-sm font-normal text-ellipsis truncate;
        }
    }
}

.blockContent {
    @apply mx-5 bg-gray-50 rounded-lg p-3 my-5;
    &-header {
        @apply flex items-center mb-5;
        >.title {
            @apply text-sm font-semibold text-black mr-3 w-fit whitespace-nowrap flex items-center gap-2;
            >.number {
                @apply bg-gray-200 rounded text-gray-600 text-sm font-normal py-0.5 px-2
            }
        }
    }
}