.dropdown-list {
    display: block;
    width: 100%;
    position: relative;
    p {
        margin: 0;
    }
    .dropdown-label {
        display: block;
        font-size: 14px;
        font-weight: 600;
        color: #1F2937;
        margin-bottom: 4px;
    }
    .dropdown-input {
        position: relative;
        color: #2e3a59;
        cursor: pointer;
        border: 1px solid #ebebf0;
        padding: 8px;
        border-radius: 4px;
        &:hover {
            border-color: #bfdbfe;
        }
        input {
            cursor: pointer;
            border: none;
            outline: none;
            font-size: 15px;
            font-weight: 400;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 20px;
        }
        .icon-xicon,
        .icon-chevron-up,
        .icon-chevron-down {
            position: absolute;
            top: 31%;
            right: 8px;
            color: #8f90a6;
            height: 12px;
        }
    }
    .dropdown-option-wrapper {
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 100;
        background-color: #ffffff;
        &.bottom {
            top: 72px;
        }
        &.top {
            top: -160px;
        }
        .dropdown-option {
            border-radius: 4px;
            box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
            .option {
                padding: 8px;
                position: relative;
                cursor: pointer;
                &:hover {
                    background-color: #bfdbfe;
                }
                p {
                    color: #2e3a59;
                    font-weight: 600;
                    font-size: 14px;
                }
                .icon-chevron-right {
                    position: absolute;
                    top: 31%;
                    right: 8px;
                    color: #2e3a59;
                    height: 12px;
                }
            }
            .value-wrapper {
                .value-header {
                    display: flex;
                    align-items: center;
                    padding: 8px;
                    cursor: pointer;
                    p {
                        margin-left: 15px;
                        color: #2e3a59;
                        font-weight: 600;
                        font-size: 14px;
                    }
                }
                .value {
                    cursor: pointer;
                    padding: 8px;
                    display: flex;
                    align-items: center;
                    &:hover {
                        background-color: #bfdbfe;
                    }
                    .icon-circle {
                        height: 8px;
                        &.select {
                            color: blue;
                        }
                    }
                    p {
                        margin-left: 12px;
                        color: #2e3a59;
                        font-weight: 400;
                        font-size: 14px;
                        &.select {
                            color: blue;
                        }
                    }
                }
            }
        }
    }
}