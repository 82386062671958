@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');


@layer base {
    [data-theme="ooolab"] {
        --color-primary-500: 0, 113, 206;
        --color-blue-50: 239, 246, 255;
    }
    .tooltip {
        @apply invisible absolute;
    }

    .has-tooltip:hover .tooltip {
        @apply visible z-50;
    }

    body {
        border-style: none;
        @apply font-display;
    }
    input {
        @apply focus:ring-0 focus:outline-none;
    }
}

@import './assets/styles/card.scss';
@import './assets/styles/heading.scss';

/* Change Autocomplete styles in Chrome*/
/* @media screen and (-webkit-min-device-pixel-ratio: 0) {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: none;
        -webkit-text-fill-color: #8f90a6;
        -webkit-box-shadow: 0 0 #000 inset;
        transition: background-color 5000s ease-in-out 0s;
        -webkit-font-smoothing: antialiased;
        font-weight: 500;
    }
} */

.opacity-container {
    @apply opacity-50;
}

.ooolab_btn_primary_1 {
    @apply w-1 h-1 bg-primary-500 rounded-sub_tab text-white text-ooolab_sm font-medium;
}
.ooolab_btn_second_1 {
    @apply w-1 h-1 border border-ooolab_border_logout bg-white rounded-sub_tab text-primary-500 text-ooolab_sm font-medium;
}
.ooolab_btn_second_animate_parent_1 {
    @apply hover:bg-ooolab_blue_0 focus:bg-primary-500;
}
.ooolab_btn_second_animate_child_1 {
    @apply fill-ooolab_blue_1 group-hover:fill-ooolab_blue_7 group-focus:fill-ooolab_white;
}
.ooolab_btn_second_animate_parent_2 {
    @apply hover:bg-ooolab_bg_bar focus:bg-primary-500;
}
.ooolab_input_1 {
    @apply h-10 rounded-sub_tab box-border border text-ooolab_base border-ooolab_border_logout text-ooolab_text_bar_inactive;
}
.ooolab_input_2 {
    @apply lg:h-10 rounded-lg box-border border text-ooolab_base text-ooolab_text_bar_inactive;
}
.ooolab_input_icon_1 {
    @apply absolute flex w-4 h-4 items-center inset-x-12;
}
.ooolab_text_base {
    @apply text-ooolab_base font-medium leading-ooolab_22px;
}
.ooolab_text_sm {
    @apply text-ooolab_xs font-medium leading-ooolab_24px;
}
.ooolab_text_md {
    @apply text-ooolab_xl font-medium leading-ooolab_28px;
}
.ooolab_text_lg {
    @apply text-ooolab_32px font-semibold leading-ooolab_44px;
}
.ooolab_paragraph_1 {
    @apply ooolab_text_base mt-3;
}

.ooolab_input_otp {
    width: 40px !important;
    height: 40px;
    @apply rounded-xl border text-2xl lg:text-3xl text-primary-500 font-semibold;
}

.ooolab_input_otp_normal {
    @apply border-ooolab_border_logout;
}

.ooolab_input_otp_error {
    @apply border-red-500;
}

.ooolab_pagination_parent_1 {
    @apply flex box-border justify-between w-96;
}
.ooolab_pagination_item_1 {
    @apply w-3 h-3 border rounded-ooolab_circle border-ooolab_gray_5;
}
.ooolab_pagination_item_active_1 {
    @apply bg-primary-500  rounded-lg w-8 h-3 shadow-ooolab_login_1;
}

.dialog-background {
    background-color: rgba(0, 0, 0, 0.5);
}

.list-2-column {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
}

.top-level {
    z-index: 99999 !important;
}

.trs-target:focus {
    transition-property: top;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    transition-delay: 0;
}

.ctools-locked {
    color: red;
    border: 1px solid red;
    padding: 1em;
}

.ctools-owns-lock {
    background: #ffd none repeat scroll 0 0;
    border: 1px solid #f0c020;
    padding: 1em;
}

.syntaxhighlighter a,
.syntaxhighlighter div,
.syntaxhighlighter code,
.syntaxhighlighter table,
.syntaxhighlighter table td,
.syntaxhighlighter table tr,
.syntaxhighlighter table tbody,
.syntaxhighlighter table thead,
.syntaxhighlighter table caption,
.syntaxhighlighter textarea {
    -moz-border-radius: 0 0 0 0 !important;
    -webkit-border-radius: 0 0 0 0 !important;
    background: 0 0 !important;
    border: 0 !important;
    bottom: auto !important;
    float: none !important;
    height: auto !important;
    left: auto !important;
    line-height: 1.1em !important;
    margin: 0 !important;
    outline: 0 !important;
    overflow: visible !important;
    padding: 0 !important;
    position: static !important;
    right: auto !important;
    text-align: left !important;
    top: auto !important;
    vertical-align: baseline !important;
    width: auto !important;
    box-sizing: content-box !important;
    font-family: consolas, bitstream vera sans mono, courier new, Courier, monospace !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 1em !important;
    min-height: inherit !important;
    min-height: auto !important;
}

.syntaxhighlighter {
    width: 100% !important;
    margin: 1em 0 !important;
    position: relative !important;
    overflow: auto !important;
    font-size: 1em !important;
}

.syntaxhighlighter.source {
    overflow: hidden !important;
}

.syntaxhighlighter .bold {
    font-weight: 700 !important;
}

.syntaxhighlighter .italic {
    font-style: italic !important;
}

.syntaxhighlighter .line {
    white-space: pre !important;
}

.syntaxhighlighter table {
    width: 100% !important;
}

.syntaxhighlighter table caption {
    text-align: left !important;
    padding: 0.5em 0 0.5em 1em !important;
}

.syntaxhighlighter table td.code {
    width: 100% !important;
}

.syntaxhighlighter table td.code .container {
    position: relative !important;
}

.syntaxhighlighter table td.code .container textarea {
    box-sizing: border-box !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
    border: none !important;
    background: #fff !important;
    padding-left: 1em !important;
    overflow: hidden !important;
    white-space: pre !important;
}

.syntaxhighlighter table td.gutter .line {
    text-align: right !important;
    padding: 0 0.5em 0 1em !important;
}

.syntaxhighlighter table td.code .line {
    padding: 0 1em !important;
}

.syntaxhighlighter.nogutter td.code .container textarea,
.syntaxhighlighter.nogutter td.code .line {
    padding-left: 0 !important;
}

.syntaxhighlighter.show {
    display: block !important;
}

.syntaxhighlighter.collapsed table {
    display: none !important;
}

.syntaxhighlighter.collapsed .toolbar {
    padding: 0.1em 0.8em 0 !important;
    font-size: 1em !important;
    position: static !important;
    width: auto !important;
    height: auto !important;
}

.syntaxhighlighter.collapsed .toolbar span {
    display: inline !important;
    margin-right: 1em !important;
}

.syntaxhighlighter.collapsed .toolbar span a {
    padding: 0 !important;
    display: none !important;
}

.syntaxhighlighter.collapsed .toolbar span a.expandSource {
    display: inline !important;
}

.syntaxhighlighter .toolbar {
    position: absolute !important;
    right: 1px !important;
    top: 1px !important;
    width: 11px !important;
    height: 11px !important;
    font-size: 10px !important;
    z-index: 10 !important;
}

.syntaxhighlighter .toolbar span.title {
    display: inline !important;
}

.syntaxhighlighter .toolbar a {
    display: block !important;
    text-align: center !important;
    text-decoration: none !important;
    padding-top: 1px !important;
}

.syntaxhighlighter .toolbar a.expandSource {
    display: none !important;
}

.syntaxhighlighter.ie {
    font-size: 0.9em !important;
    padding: 1px 0 !important;
}

.syntaxhighlighter.ie .toolbar {
    line-height: 8px !important;
}

.syntaxhighlighter.ie .toolbar a {
    padding-top: 0 !important;
}

.syntaxhighlighter.printing .line.alt1 .content,
.syntaxhighlighter.printing .line.alt2 .content,
.syntaxhighlighter.printing .line.highlighted .number,
.syntaxhighlighter.printing .line.highlighted.alt1 .content,
.syntaxhighlighter.printing .line.highlighted.alt2 .content {
    background: 0 0 !important;
}

.syntaxhighlighter.printing .line .number {
    color: #bbb !important;
}

.syntaxhighlighter.printing .line .content {
    color: #000 !important;
}

.syntaxhighlighter.printing .toolbar {
    display: none !important;
}

.syntaxhighlighter.printing a {
    text-decoration: none !important;
}

.syntaxhighlighter.printing .plain,
.syntaxhighlighter.printing .plain a {
    color: #000 !important;
}

.syntaxhighlighter.printing .comments,
.syntaxhighlighter.printing .comments a {
    color: #008200 !important;
}

.syntaxhighlighter.printing .string,
.syntaxhighlighter.printing .string a {
    color: blue !important;
}

.syntaxhighlighter.printing .keyword {
    color: #069 !important;
    font-weight: 700 !important;
}

.syntaxhighlighter.printing .preprocessor {
    color: gray !important;
}

.syntaxhighlighter.printing .variable {
    color: #a70 !important;
}

.syntaxhighlighter.printing .value {
    color: #090 !important;
}

.syntaxhighlighter.printing .functions {
    color: #ff1493 !important;
}

.syntaxhighlighter.printing .constants {
    color: #06c !important;
}

.syntaxhighlighter.printing .script {
    font-weight: 700 !important;
}

.syntaxhighlighter.printing .color1,
.syntaxhighlighter.printing .color1 a {
    color: gray !important;
}

.syntaxhighlighter.printing .color2,
.syntaxhighlighter.printing .color2 a {
    color: #ff1493 !important;
}

.syntaxhighlighter.printing .color3,
.syntaxhighlighter.printing .color3 a {
    color: red !important;
}

.syntaxhighlighter.printing .break,
.syntaxhighlighter.printing .break a {
    color: #000 !important;
}

.syntaxhighlighter {
    background-color: #1b2426 !important;
}

.syntaxhighlighter .line.alt1 {
    background-color: #1b2426 !important;
}

.syntaxhighlighter .line.alt2 {
    background-color: #1b2426 !important;
}

.syntaxhighlighter .line.highlighted.alt1,
.syntaxhighlighter .line.highlighted.alt2 {
    background-color: #323e41 !important;
}

.syntaxhighlighter .line.highlighted.number {
    color: #b9bdb6 !important;
}

.syntaxhighlighter table caption {
    color: #b9bdb6 !important;
}

.syntaxhighlighter .gutter {
    color: #afafaf !important;
}

.syntaxhighlighter .gutter .line {
    border-right: 3px solid #435a5f !important;
}

.syntaxhighlighter .gutter .line.highlighted {
    background-color: #435a5f !important;
    color: #1b2426 !important;
}

.syntaxhighlighter.printing .line .content {
    border: none !important;
}

.syntaxhighlighter.collapsed {
    overflow: visible !important;
}

.syntaxhighlighter.collapsed .toolbar {
    color: #5ba1cf !important;
    background: #000 !important;
    border: 1px solid #435a5f !important;
}

.syntaxhighlighter.collapsed .toolbar a {
    color: #5ba1cf !important;
}

.syntaxhighlighter.collapsed .toolbar a:hover {
    color: #5ce638 !important;
}

.syntaxhighlighter .toolbar {
    color: #fff !important;
    background: #435a5f !important;
    border: none !important;
}

.syntaxhighlighter .toolbar a {
    color: #fff !important;
}

.syntaxhighlighter .toolbar a:hover {
    color: #e0e8ff !important;
}

.syntaxhighlighter .plain,
.syntaxhighlighter .plain a {
    color: #b9bdb6 !important;
}

.syntaxhighlighter .comments,
.syntaxhighlighter .comments a {
    color: #878a85 !important;
}

.syntaxhighlighter .string,
.syntaxhighlighter .string a {
    color: #5ce638 !important;
}

.syntaxhighlighter .keyword {
    color: #5ba1cf !important;
}

.syntaxhighlighter .preprocessor {
    color: #435a5f !important;
}

.syntaxhighlighter .variable {
    color: #ffaa3e !important;
}

.syntaxhighlighter .value {
    color: #090 !important;
}

.syntaxhighlighter .functions {
    color: #ffaa3e !important;
}

.syntaxhighlighter .constants {
    color: #e0e8ff !important;
}

.syntaxhighlighter .script {
    font-weight: 700 !important;
    color: #5ba1cf !important;
    background-color: none !important;
}

.syntaxhighlighter .color1,
.syntaxhighlighter .color1 a {
    color: #e0e8ff !important;
}

.syntaxhighlighter .color2,
.syntaxhighlighter .color2 a {
    color: #fff !important;
}

.syntaxhighlighter .color3,
.syntaxhighlighter .color3 a {
    color: #ffaa3e !important;
}

/* @font-face {
    font-family: h5p;
    src: url(/sites/all/modules/h5p/library/fonts/h5p-core-27.eot?9pg14);
    src: url(/sites/all/modules/h5p/library/fonts/h5p-core-27.eot?9pg14#iefix) format('embedded-opentype'), url(/sites/all/modules/h5p/library/fonts/h5p-core-27.ttf?9pg14) format('truetype'), url(/sites/all/modules/h5p/library/fonts/h5p-core-27.woff?9pg14) format('woff'), url(/sites/all/modules/h5p/library/fonts/h5p-core-27.svg?9pg14#h5p-core-27) format('svg');
    font-weight: 400;
    font-style:normal
}

@font-face {
    font-family: h5p-hub-publish;
    src: url(/sites/all/modules/h5p/library/fonts/h5p-hub-publish.eot?wy8ylc);
    src: url(/sites/all/modules/h5p/library/fonts/h5p-hub-publish.eot?wy8ylc#iefix) format('embedded-opentype'), url(/sites/all/modules/h5p/library/fonts/h5p-hub-publish.ttf?wy8ylc) format('truetype'), url(/sites/all/modules/h5p/library/fonts/h5p-hub-publish.woff?wy8ylc) format('woff'), url(/sites/all/modules/h5p/library/fonts/h5p-hub-publish.svg?wy8ylc#h5p-hub) format('svg');
    font-weight: 400;
    font-style: normal;
    font-display:block
} */

html.h5p-iframe,
html.h5p-iframe > body {
    font-family: Sans-Serif;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.h5p-semi-fullscreen,
.h5p-fullscreen,
html.h5p-iframe .h5p-container {
    overflow: hidden;
}

.h5p-content {
    position: relative;
    background: #fefefe;
    border: 1px solid #eee;
    border-bottom: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.h5p-noselect {
    -khtml-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

html.h5p-iframe .h5p-content {
    font-size: 16px;
    line-height: 1.5em;
    width: 100%;
    height: auto;
}

html.h5p-iframe .h5p-fullscreen .h5p-content,
html.h5p-iframe .h5p-semi-fullscreen .h5p-content {
    height: 100%;
}

.h5p-content.h5p-no-frame,
.h5p-fullscreen .h5p-content,
.h5p-semi-fullscreen .h5p-content {
    border: 0;
}

.h5p-container {
    position: relative;
    z-index: 1;
}

.h5p-iframe-wrapper.h5p-fullscreen {
    background-color: #000;
}

body.h5p-semi-fullscreen {
    position: fixed;
    width: 100%;
    height: 100%;
}

.h5p-container.h5p-semi-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.h5p-content-controls {
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
}

.h5p-fullscreen .h5p-content-controls {
    display: none;
}

.h5p-content-controls > a:link,
.h5p-content-controls > a:visited,
a.h5p-disable-fullscreen:link,
a.h5p-disable-fullscreen:visited {
    color: #e5eef6;
}

.h5p-enable-fullscreen:before {
    font-family: h5p;
    content: '\e88c';
}

.h5p-disable-fullscreen:before {
    font-family: h5p;
    content: '\e891';
}

.h5p-enable-fullscreen,
.h5p-disable-fullscreen {
    cursor: pointer;
    color: #eee;
    background: #000;
    background: rgba(0, 0, 0, 0.3);
    line-height: 0.975em;
    font-size: 2em;
    width: 1.125em;
    height: 1em;
    text-indent: 0.04em;
}

.h5p-disable-fullscreen {
    line-height: 0.925em;
    width: 1.1em;
    height: 0.9em;
}

.h5p-enable-fullscreen:focus,
.h5p-disable-fullscreen:focus {
    outline-style: solid;
    outline-width: 1px;
    outline-offset: 0.25em;
}

.h5p-enable-fullscreen:hover,
.h5p-disable-fullscreen:hover {
    background: rgba(0, 0, 0, 0.5);
}

.h5p-semi-fullscreen .h5p-enable-fullscreen {
    display: none;
}

div.h5p-fullscreen {
    width: 100%;
    height: 100%;
}

.h5p-iframe-wrapper {
    width: auto;
    height: auto;
}

.h5p-fullscreen .h5p-iframe-wrapper,
.h5p-semi-fullscreen .h5p-iframe-wrapper {
    width: 100%;
    height: 100%;
}

.h5p-iframe-wrapper.h5p-semi-fullscreen {
    width: auto;
    height: auto;
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100001;
}

.h5p-iframe-wrapper.h5p-semi-fullscreen .buttons {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
}

.h5p-iframe-wrapper iframe.h5p-iframe {
    width: 10px;
    min-width: 100%;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow: hidden;
    border: 0;
    display: block;
}

.h5p-content ul.h5p-actions {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    overflow: hidden;
    list-style: none;
    padding: 0 10px;
    margin: 0;
    height: 25px;
    font-size: 12px;
    background: #fafafa;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    clear: both;
    font-family: Sans-Serif;
}

.h5p-fullscreen .h5p-actions,
.h5p-semi-fullscreen .h5p-actions {
    display: none;
}

.h5p-actions > .h5p-button {
    float: left;
    cursor: pointer;
    margin: 0 0.5em 0 0;
    background: 0 0;
    padding: 0 0.75em 0 0.25em;
    vertical-align: top;
    color: #707070;
    text-decoration: none;
    outline: none;
    line-height: 22px;
}

.h5p-actions > .h5p-button:hover {
    color: #333;
}

.h5p-actions > .h5p-button:active,
.h5p-actions > .h5p-button:focus,
.h5p-actions .h5p-link:active,
.h5p-actions .h5p-link:focus {
    color: #666;
}

.h5p-actions > .h5p-button:focus,
.h5p-actions .h5p-link:focus {
    outline-style: solid;
    outline-width: thin;
    outline-offset: -2px;
    outline-color: #9ecaed;
}

.h5p-actions > .h5p-button:before {
    font-family: h5p;
    font-size: 20px;
    line-height: 23px;
    vertical-align: bottom;
    padding-right: 0;
}

.h5p-actions > .h5p-button.h5p-export:before {
    content: '\e90b';
}

.h5p-actions > .h5p-button.h5p-copyrights:before {
    content: '\e88f';
}

.h5p-actions > .h5p-button.h5p-embed:before {
    content: '\e892';
}

.h5p-actions .h5p-link {
    float: right;
    margin-right: 0;
    font-size: 2em;
    line-height: 23px;
    overflow: hidden;
    color: #999;
    text-decoration: none;
    outline: none;
}

.h5p-actions .h5p-link:before {
    font-family: h5p;
    content: '\e88e';
    vertical-align: bottom;
}

.h5p-actions > li {
    margin: 0;
    list-style: none;
}

.h5p-popup-dialog {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    z-index: 100;
    padding: 2em;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    opacity: 0;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
    background: #000;
    background: rgba(0, 0, 0, 0.75);
}

.h5p-popup-dialog.h5p-open {
    opacity: 1;
}

.h5p-popup-dialog .h5p-inner {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    background: #fff;
    height: 100%;
    max-height: 100%;
    position: relative;
}

.h5p-popup-dialog .h5p-inner > h2 {
    position: absolute;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    margin: 0;
    background: #eee;
    display: block;
    color: #656565;
    font-size: 1.25em;
    padding: 0.325em 0.5em 0.25em;
    line-height: 1.25em;
    border-bottom: 1px solid #ccc;
    z-index: 2;
}

.h5p-popup-dialog .h5p-inner > h2 > a {
    font-size: 12px;
    margin-left: 1em;
}

.h5p-embed-dialog .h5p-inner,
.h5p-reuse-dialog .h5p-inner,
.h5p-content-user-data-reset-dialog .h5p-inner {
    min-width: 316px;
    max-width: 400px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
}

.h5p-embed-dialog .h5p-embed-code-container,
.h5p-embed-size {
    resize: none;
    outline: none;
    width: 100%;
    padding: 0.375em 0.5em 0.25em;
    margin: 0;
    overflow: hidden;
    border: 1px solid #ccc;
    box-shadow: 0 1px 2px 0 #d0d0d0 inset;
    font-size: 0.875em;
    letter-spacing: 0.065em;
    font-family: sans-serif;
    white-space: pre;
    line-height: 1.5em;
    height: 2.0714em;
    background: #f5f5f5;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.h5p-embed-dialog .h5p-embed-code-container:focus {
    height: 5em;
}

.h5p-embed-size {
    width: 3.5em;
    text-align: right;
    margin: 0.5em 0;
    line-height: 2em;
}

.h5p-popup-dialog .h5p-scroll-content {
    border-top: 2.25em solid transparent;
    padding: 1em;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    color: #555;
    z-index: 1;
}

.h5p-popup-dialog.h5p-open .h5p-scroll-content {
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
}

.h5p-popup-dialog .h5p-scroll-content::-webkit-scrollbar {
    width: 8px;
}

.h5p-popup-dialog .h5p-scroll-content::-webkit-scrollbar-track {
    background: #e0e0e0;
}

.h5p-popup-dialog .h5p-scroll-content::-webkit-scrollbar-thumb {
    box-shadow: 0 0 10px #000 inset;
    border-radius: 4px;
}

.h5p-popup-dialog .h5p-close {
    cursor: pointer;
    font-size: 2em;
    position: absolute;
    right: 0;
    top: 0;
    width: 1.125em;
    height: 1.125em;
    line-height: 1.125em;
    color: #656565;
    cursor: pointer;
    text-indent: -0.065em;
    z-index: 3;
}

.h5p-popup-dialog .h5p-close:after {
    font-family: h5p;
    content: '\e894';
}

.h5p-popup-dialog .h5p-close:hover:after,
.h5p-popup-dialog .h5p-close:focus:after {
    color: #454545;
}

.h5p-popup-dialog .h5p-close:active:after {
    color: #252525;
}

.h5p-poopup-dialog h2 {
    margin: 0.25em 0 0.5em;
}

.h5p-popup-dialog h3 {
    margin: 0.75em 0 0.25em;
}

.h5p-popup-dialog dl {
    margin: 0.25em 0 0.75em;
}

.h5p-popup-dialog dt {
    float: left;
    margin: 0 0.75em 0 0;
}

.h5p-popup-dialog dt:after {
    content: ':';
}

.h5p-popup-dialog dd {
    margin: 0;
}

.h5p-expander {
    cursor: pointer;
    font-size: 1.125em;
    margin: 0.5em 0 0;
    display: inline-block;
}

.h5p-expander:before {
    content: '+';
    width: 1em;
    display: inline-block;
    font-weight: 700;
}

.h5p-expander.h5p-open:before {
    content: '-';
    text-indent: 0.125em;
}

.h5p-expander:hover,
.h5p-expander:focus {
    color: #303030;
}

.h5p-expander:active {
    color: #202020;
}

.h5p-expander-content {
    display: none;
}

.h5p-expander-content p {
    margin: 0.5em 0;
}

.h5p-content-copyrights {
    border-left: 0.25em solid #d0d0d0;
    margin-left: 0.25em;
    padding-left: 0.25em;
}
/* 
.h5p-throbber {
    background: url(/sites/all/modules/h5p/library/images/throbber.gif?ver=1.2.1) 10px center no-repeat;
    padding-left: 38px;
    min-height: 30px;
    line-height:30px
} */

.h5p-dialog-ok-button {
    cursor: default;
    float: right;
    outline: none;
    border: 2px solid #ccc;
    padding: 0.25em 0.75em 0.125em;
    background: #eee;
}

.h5p-dialog-ok-button:hover,
.h5p-dialog-ok-button:focus {
    background: #fafafa;
}

.h5p-dialog-ok-button:active {
    background: #efe;
}

.h5p-big-button {
    line-height: 1.25;
    display: block;
    position: relative;
    cursor: pointer;
    width: 100%;
    padding: 1em 1em 1em 3.75em;
    text-align: left;
    border: 1px solid #dedede;
    background: linear-gradient(#ffffff, #f1f1f2);
    border-radius: 0.25em;
}

.h5p-big-button:before {
    font-family: h5p;
    content: '\e893';
    line-height: 1;
    font-size: 3em;
    color: #2747f7;
    position: absolute;
    left: 0.125em;
    top: 0.125em;
}

.h5p-copy-button:before {
    content: '\e905';
}

.h5p-big-button:hover {
    border: 1px solid #2747f7;
    background: #eff1fe;
}

.h5p-big-button:active {
    border: 1px solid #dedede;
    background: #dfe4fe;
}

.h5p-button-title {
    color: #2747f7;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0.5em;
}

.h5p-button-description {
    color: #757575;
}

.h5p-horizontal-line-text {
    border-top: 1px solid #dadada;
    line-height: 1;
    color: #474747;
    text-align: center;
    position: relative;
    margin: 1.25em 0;
}

.h5p-horizontal-line-text > span {
    background: #fff;
    padding: 0.5em;
    position: absolute;
    top: -1em;
    left: 50%;
    transform: translateX(-50%);
}

.h5p-toast {
    font-size: 0.75em;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    z-index: 110;
    position: absolute;
    padding: 0 0.5em;
    line-height: 2;
    border-radius: 4px;
    white-space: nowrap;
    pointer-events: none;
    top: 0;
    opacity: 1;
    visibility: visible;
    transition: opacity 1s;
}

.h5p-toast-disabled {
    opacity: 0;
    visibility: hidden;
}

.h5p-content code,
.h5peditor code {
    color: #3d3d3d;
    background: #e0e0e0;
    border-radius: 2px;
    padding: 0 5px;
}

.h5p-content pre > code,
.h5peditor pre > code {
    background-color: #fafafa;
    padding: 5px;
    display: block;
    line-height: normal;
    border: 1px solid #c7c7c7;
    border-left-width: 4px;
    max-width: 100%;
    white-space: pre;
    overflow: auto;
}

.h5peditor-semi-fullscreen {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 101;
}

iframe.h5peditor-semi-fullscreen {
    background: #fff;
    z-index: 100001;
}

.h5p-content.using-mouse * :not(textarea):focus {
    outline: none !important;
}

.h5p-content-hub-button:before {
    font-family: h5p;
    margin-right: 0.5em;
    font-size: 0.7em;
    line-height: 1;
}

.h5p-content-hub-button.unpublish:before {
    content: '\e916';
}

.h5p-content-hub-button.waiting:before,
.h5p-content-hub-button.sync:before {
    content: '\e917';
}

.h5p-content-hub-button.waiting:before {
    display: inline-block;
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.h5p-confirmation-dialog-background {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(44, 44, 44, 0.9);
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.1s, linear 0s, visibility 0s linear 0s;
    transition: opacity 0.1s linear 0s, visibility 0s linear 0s;
    z-index: 201;
}

.h5p-confirmation-dialog-background.hidden {
    display: none;
}

.h5p-confirmation-dialog-background.hiding {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.1s, linear 0s, visibility 0s linear 0.1s;
    transition: opacity 0.1s linear 0s, visibility 0s linear 0.1s;
}

.h5p-confirmation-dialog-popup:focus {
    outline: none;
}

.h5p-confirmation-dialog-popup {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    max-width: 35em;
    min-width: 25em;
    top: 2em;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    color: #555;
    box-shadow: 0 0 6px 6px rgba(10, 10, 10, 0.3);
    -webkit-transition: transform 0.1s ease-in;
    transition: transform 0.1s ease-in;
}

.h5p-confirmation-dialog-popup.hidden {
    -webkit-transform: translate(-50%, 50%);
    -ms-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
}

.h5p-confirmation-dialog-header {
    padding: 1.5em;
    background: #fff;
    color: #356593;
}

.h5p-confirmation-dialog-header-text {
    font-size: 1.25em;
}

.h5p-confirmation-dialog-body {
    background: #fafbfc;
    border-top: solid 1px #dde0e9;
    padding: 1.25em 1.5em;
}

.h5p-confirmation-dialog-text {
    margin-bottom: 1.5em;
}

.h5p-confirmation-dialog-buttons {
    float: right;
}

button.h5p-confirmation-dialog-exit:visited,
button.h5p-confirmation-dialog-exit:link,
button.h5p-confirmation-dialog-exit {
    position: absolute;
    background: 0 0;
    border: none;
    font-size: 2.5em;
    top: -0.9em;
    right: -1.15em;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}

button.h5p-confirmation-dialog-exit:focus,
button.h5p-confirmation-dialog-exit:hover {
    color: #e4ecf5;
}

.h5p-confirmation-dialog-exit:before {
    font-family: h5p;
    content: '\e890';
}

.h5p-core-button.h5p-confirmation-dialog-confirm-button {
    padding-left: 0.75em;
    margin-bottom: 0;
}

.h5p-core-button.h5p-confirmation-dialog-confirm-button:before {
    content: '\e601';
    margin-top: -6px;
    display: inline-block;
}

.h5p-confirmation-dialog-popup.offline .h5p-confirmation-dialog-buttons {
    float: none;
    text-align: center;
}

.h5p-confirmation-dialog-popup.offline .count-down {
    font-family: Arial;
    margin-top: 0.15em;
    color: #000;
}

.h5p-confirmation-dialog-popup.offline .h5p-confirmation-dialog-confirm-button:before {
    content: '\e90b';
    font-weight: 400;
    vertical-align: text-bottom;
}

.throbber-wrapper {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(44, 44, 44, 0.9);
}

.throbber-wrapper.show {
    display: block;
}

.throbber-wrapper .throbber-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.throbber-wrapper .sending-requests-throbber {
    position: absolute;
    top: 7em;
    left: 50%;
    transform: translateX(-50%);
}

.throbber-wrapper .sending-requests-throbber:before {
    display: block;
    font-family: h5p;
    content: '\e90b';
    color: #fff;
    font-size: 10em;
    animation: request-throbber 1.5s infinite linear;
}

@keyframes request-throbber {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(359deg);
    }
}

button.h5p-core-button:visited,
button.h5p-core-button:link,
button.h5p-core-button {
    font-family: open sans, sans-serif;
    font-weight: 600;
    font-size: 1em;
    line-height: 1.2;
    padding: 0.5em 1.25em;
    border-radius: 2em;
    background: #2579c6;
    color: #fff;
    cursor: pointer;
    border: none;
    box-shadow: none;
    outline: none;
    display: inline-block;
    text-align: center;
    text-shadow: none;
    vertical-align: baseline;
    text-decoration: none;
    -webkit-transition: initial;
    transition: initial;
}

button.h5p-core-button:focus {
    background: #1f67a8;
}

button.h5p-core-button:hover {
    background: rgba(31, 103, 168, 0.83);
}

button.h5p-core-button:active {
    background: #104888;
}

button.h5p-core-button:before {
    font-family: h5p;
    padding-right: 0.15em;
    font-size: 1.5em;
    vertical-align: middle;
    line-height: 0.7;
}

button.h5p-core-cancel-button:visited,
button.h5p-core-cancel-button:link,
button.h5p-core-cancel-button {
    border: none;
    background: 0 0;
    color: #a00;
    margin-right: 1em;
    font-size: 1em;
    text-decoration: none;
    cursor: pointer;
}

button.h5p-core-cancel-button:hover,
button.h5p-core-cancel-button:focus {
    background: 0 0;
    border: none;
    color: #e40000;
}

.h5p-content-type-actions {
    text-align: center;
    overflow: hidden;
    margin-top: 2em;
    border: solid 20px #efefef;
    padding: 2em 2em 1.5em;
    max-width: 1092px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
}

.h5p-content-type-actions p {
    max-width: 700px;
    margin: 1em auto;
    font-size: 0.875em;
}

.node-type-h5p-content h2.h5p-create-your-own {
    margin-top: 0;
}

#block-system-main .h5p-content-type-actions h2 {
    color: #000;
    font-size: 1.5em;
}

#block-system-main .h5p-content-type-actions h2.h5p-create-your-own {
    color: #2882d3;
    margin-bottom: 0.75em;
}

.h5p-content-type-actions .h5p-action-block-button {
    font-size: 1em;
}

.h5p-content-type-action-button {
    display: inline-block;
    color: #fff;
    padding: 0.3em 0.8em;
    border-radius: 4px;
    font-weight: 400;
    background-color: #d09;
    font-size: 1.1em;
    margin-top: 0.5em;
}

.h5p-content-type-action-button:hover {
    background-color: rgba(221, 0, 153, 0.8);
}

.h5p-content-type-action-button.h5p-wordpress-action-button:before {
    margin-right: 0.5em;
    color: #fff;
    font-family: FontAwesome;
    content: '\f19a';
}

.h5p-content-type-action-button.h5p-drupal-action-button:before {
    margin-right: 0.5em;
    color: #fff;
    font-family: FontAwesome;
    content: '\f1a9';
}
/* 
.h5p-content-type-action-button.h5p-moodle-action-button {
    padding-left: 46px;
    padding-bottom: 6px;
    background-image: url(/sites/all/themes/professional_themec/images/white-moodle-icon.png);
    background-repeat: no-repeat;
    background-position:12px 9px
} */

.systems-like-this {
}

.systems-like-this .system-like-this img {
    display: block;
}

.systems-like-this:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
}

.system-like-this {
    width: 14.2857%;
    float: left;
    box-sizing: border-box;
    padding: 0 5px;
}

.systems-like-this .more {
    margin-top: 9px;
}

.systems-like-this .more a {
    color: #d09;
    font-weight: 700;
    line-height: 10px;
    text-decoration: none;
    font-size: 12px;
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-left: auto;
    border-radius: 6px;
    border: dashed 1px #e5e5e5;
    text-align: center;
    white-space: nowrap;
    padding: 10% 0;
    line-height: 1;
    text-overflow: ellipsis;
    overflow: hidden;
}

.systems-like-this .more a:hover {
    background-color: #fff;
    text-decoration: none;
    border-color: #d09;
}

.systems-like-this .more a:after {
    font-family: FontAwesome;
    content: '\f105';
    margin-left: 10px;
    font-size: 10px;
}

.field-collection-container {
    border-bottom: 1px solid #d3d7d9;
    margin-bottom: 1em;
}

.field-collection-container .field-items .field-item {
    margin-bottom: 10px;
}

.field-collection-container .field-items .field-items .field-item {
    margin-bottom: 0;
}

.field-collection-view {
    padding: 1em 0 0.3em;
    margin: 0 1em;
    border-bottom: 1px dotted #d3d7d9;
}

.field-collection-view-final {
    border-bottom: none;
}

.field-collection-view .entity-field-collection-item {
    float: left;
}

.field-collection-view ul.field-collection-view-links {
    float: right;
    font-size: 0.821em;
    list-style-type: none;
    width: auto;
    margin: 0 1em;
    padding: 0;
}

.field-collection-view .field-label {
    width: 25%;
}

.field-collection-view .content {
    margin-top: 0;
    width: 100%;
}

.field-collection-view .entity-field-collection-item {
    width: 100%;
}

ul.field-collection-view-links li {
    float: left;
}

ul.field-collection-view-links li a {
    margin-right: 1em;
}

.field-collection-container ul.action-links-field-collection-add {
    float: right;
    padding: 0 0.5em 0 0;
    margin: 0 0 1em 2em;
    font-size: 0.821em;
}

#feedback_simple a {
    cursor: pointer;
    display: block;
    overflow: hidden;
    position: fixed;
    text-decoration: none !important;
    z-index: 9999;
}

#feedback_simple img {
    border: none;
}

.feedback_simple-left {
    left: -2px;
}

.feedback_simple-right {
    right: -2px;
}

.headline-text {
    @apply text-sm font-semibold text-gray-700 hover:text-gray-500;
}

.primary-button {
    @apply relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-xs font-semibold rounded text-white bg-primary-500 hover:bg-primary-500 focus:outline-none;
}
.secondary-button {
    @apply relative inline-flex items-center px-4 py-2 border border-primary-500 shadow-sm text-xs font-semibold rounded text-primary-500 bg-white hover:bg-blue-50 focus:outline-none;
}
.primary-button-apollo {
    @apply relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-primary-500 focus:outline-none;
}
.primary-button-apollo-custom {
    padding: 8px 10px;
    @apply relative inline-flex items-center border border-transparent shadow-sm text-xs font-semibold rounded-md text-white bg-primary-500 focus:outline-none;
}
.primary-button.disable,
.secondary-button.disable {
    @apply bg-ooolab_dark_50 cursor-not-allowed;
}

.width30 {
    width: 30%;
}

.width70 {
    width: 70%;
}

.swale2-title-custom {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    padding-top: 0 !important;
}

.swale2-icon-custom {
    margin: 0 auto !important;
    border: none !important;
}

.swale2-confirmButton-custom {
    background: rgba(55, 65, 81, 1) !important;
    padding: 6px 34px !important;
}

.swale2-container-custom {
    z-index: 9999 !important;
}

.swale2-popup-custom {
    width: 30rem !important;
}

.swale2-title-popupconfirm {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    padding-top: 0 !important;
    color: #2e3a59 !important;
}

.swale2-icon-popupconfirm {
    margin: 16px auto !important;
    border: none !important;
    width: 7em !important;
    height: 5em !important;
}

.swale2-confirmButton-popupconfirm {
    background: #0071ce !important;
    padding: 6px 34px !important;
    border-radius: 8px;
    margin: 5px 10px !important;
}

.swale2-cancelButton-popupconfirm {
    background: #ffffff !important;
    padding: 6px 34px !important;
    border-radius: 8px;
    border: 1px solid #8f90a6 !important;
    color: #2e3a59 !important;
    margin: 5px 10px !important;
}

.swale2-container-popupconfirm {
    z-index: 9999 !important;
}

.swale2-htmlContainer-popupconfirm {
    font-size: 18px !important;
    font-weight: 400 !important;
    color: #2e3a59 !important;
}

.swale2-popup-popupconfirm {
    width: 30rem !important;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:first-child {
    width: 50%;
    display: flex;
}
.fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary:first-letter {
    text-transform: uppercase;
}
.fc .fc-header-toolbar .fc-toolbar-chunk .fc-today-button {
    border: 1px solid #2e3a59;
    color: #2e3a59;
    font-size: 12px;
    font-weight: 600;
    background-color: transparent;
    height: 32px;
}
.fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group  {
    display: flex;
    align-items: center;
}
.fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button {
    color: #2e3a59;
    font-size: 12px;
    font-weight: 600;
    background-color: transparent;
    height: 32px;
}
.fc .fc-header-toolbar .fc-toolbar-title {
    font-weight: 600;
    color: #282b5e;
    padding-top: 0px !important;
    font-size: 16px;
    display: flex;
    align-items: center;
}
.fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-timeGridDay-button,
.fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-timeGridWeek-button,
.fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-dayGridMonth-button {
    color: #8f90a6;
    border: none;
}
.fc .fc-button-primary:focus,
.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none !important;
}
.fc .fc-header-toolbar .fc-button-group .fc-button.fc-button-active {
    min-width: 60px;
    background-color: #fafafc;
    color: #2e3a59 !important;
}
.fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-next-button,
.fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-prev-button {
    margin-top: 0px;
    background-color: #2e3a59;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #fff !important;
    padding: 0;
}
.fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button .fc-icon {
    height: 20px;
}
.fc .fc-button-group .fc-prev-button {
    margin-right: 8px;
}
.fc-theme-standard .fc-scrollgrid {
    border: 1px solid #e4e6e8;
    border-radius: 8px;
    overflow: hidden;
}
.fc-theme-standard th {
    color: #8f90a6;
    font-size: 12px;
    font-weight: 700;
    height: 50px;
    background-color: #f2f2f5;
}
.fc-theme-standard th > div {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fc .fc-daygrid-day-top {
    justify-content: center;
}
.fc .fc-daygrid-day-top > a {
    font-size: 12px;
    font-weight: 600;
}
.fc .fc-timegrid-slot-label-cushion,
.fc .fc-timegrid-axis-cushion {
    font-size: 12px;
    font-weight: 600;
    color: #2e3a59;
}
.fc .fc-h-event,
.fc .fc-popover-body .fc-event {
    color: #2e3a59;
    background-color: #cce8ff;
    border-color: #cce8ff;
    padding: 0 8px !important;
    border-radius: 20px !important;
    margin-bottom: 4px;
    font-size: 9px;
}
.fc .fc-h-event .fc-event-title,
.fc .fc-daygrid-more-link {
    font-size: 9px;
    font-weight: 700;
    color: #2e3a59;
}
.fc .fc-daygrid-more-link {
    font-size: 10px;
    margin-left: 5px;
}
.fc-popover-body .fc-daygrid-event-dot,
.fc-popover-body .fc-event-time {
    display: none !important;
}
.fc .fc-daygrid-body .fc-daygrid-day.fc-day-today {
    background-color: transparent;
}
.fc .fc-timegrid-body .fc-timegrid-col.fc-day-today {
    background-color: #f2f9ff;
}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #2e3a59;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    margin-bottom: 4px;
}
.fc .fc-view .fc-h-event .fc-event-title,
.fc .fc-daygrid-more-link {
    font-size: 8px;
}
.fc .fc-event-selected,
.fc .fc-event:focus {
    box-shadow: none;
}
.fc .fc-col-header th {
    border-color: transparent;
}
.fc .fc-dayGridMonth-view .fc-daygrid-dot-event .fc-daygrid-event-dot,
.fc .fc-dayGridMonth-view .fc-daygrid-dot-event .fc-event-time,
.fc .fc-dayGridMonth-view .fc-event .fc-event-time {
    display: none;
}
.fc .fc-dayGridMonth-view .fc-event {
    height: 15px;
    font-size: 8px;
    font-weight: 600;
    background-color: #cce8ff;
    border-radius: 20px;
    padding: 1px 8px;
    cursor: pointer;
    @apply font-inter truncate text-ooolab_dark_1;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}
.fc .fc-dayGridMonth-view .fc-daygrid-event-harness {
    padding-left: 5px;
    padding-right: 5px;
}
.fc .fc-dayGridMonth-view .fc-event .fc-event-title,
.fc .fc-popover-body .fc-event .fc-event-title {
    padding: 0;
}
.fc .fc-dayGridMonth-view .fc-event:hover,
.fc .fc-dayGridMonth-view .fc-event:hover .fc-event-title,
.fc .fc-popover-body .fc-event:hover,
.fc .fc-popover-body .fc-event:hover .fc-event-title {
    background-color: #0071ce;
    color: #fff;
}
.fc .fc-dayGridMonth-view .fc-event:hover {
    outline: none;
    border: none;
}
.fc .fc-view.fc-timeGridWeek-view,
.fc .fc-view.fc-timeGridDay-view {
    border-radius: 8px;
    @apply shadow-ooolab_box_shadow_container;
}
.fc .fc-view.fc-timeGridWeek-view .fc-scrollgrid,
.fc .fc-view.fc-timeGridDay-view .fc-scrollgrid {
    border-radius: 8px;
    border: none;
}
.fc .fc-view .fc-timegrid-event {
    background-color: #cce8ff;
    box-shadow: none;
    color: #2e3a59;
    border: none;
    border-radius: 8px;
    padding: 8px;
    margin: 2px;
    cursor: pointer;
    @apply transition;
}
.fc .fc-dayGridMonth-view .fc-h-event {
    padding: 0;
}
.fc .fc-view .fc-timegrid-event:hover {
    background-color: #0071ce;
}
.fc .fc-view .fc-timegrid-event .fc-event-time,
.fc .fc-view .fc-timegrid-event .fc-event-title {
    color: #2e3a59;
    @apply transition;
}
.fc .fc-view .fc-timegrid-event .fc-event-time {
    white-space: normal;
}
.fc .fc-view .fc-timegrid-event:hover .fc-event-time,
.fc .fc-view .fc-timegrid-event:hover .fc-event-title {
    color: #fff;
}
.fc .fc-timegrid-slot {
    border: 2px solid #eef1fa;
}
.fc .fc-timegrid-slot-minor.fc-timegrid-slot {
    border: 1px solid #eef1fa;
}
.fc .fc-view .fc-timegrid-event .fc-event-title {
    @apply font-inter text-xs font-semibold text-ooolab_dark_1;
}
.fc .fc-dayGridMonth-view {
    border: 1px solid #eef1fa;
    border-radius: 8px;
    box-shadow: none;
    overflow: hidden;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
}
.fc .fc-dayGridMonth-view .fc-scrollgrid {
    border: none;
}
.grayscale {
    --tw-grayscale: grayscale(100%);
    filter: grayscale(1);
}
.backgroud-white {
    background: #fff;
}

.margin-0 {
    margin: 0 !important;
}

.percent::after {
    content: '%';
}
.text-first-uppercase {
    display: inline-block;
}
.text-first-uppercase:first-letter {
    text-transform: uppercase;
}
.rc-color-picker-wrap {
    height: 32px;
    padding-top: 5px;
}
.rc-color-picker-wrap .rc-color-picker-trigger {
    width: 32px;
    height: 32px;
    padding: 0;
    border: 0;
    border-radius: 100%;
    box-shadow: none;
}
.fc .fc-button .fc-icon {
    width: 20px;
}
.fc .fc-event-lock {
    display: none;
}
.fc .fc-event-past .fc-event-lock {
    display: block;
    right: -2px;
    bottom: 0px;
}

.truncate-2-lines {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}
#zohohc-asap-web-helper-main {
    display: none;
}
.admin #zohohc-asap-web-helper-main {
    display: block;
}
#zohohc-asap-web-helper-main #zohohc-asap-web-launcherbox {
    bottom: 10px;
    right: 10px;
}
.pagination {
    padding-right: 77px;
}
.noPadding .pagination {
    padding-right: 0;
}
.h5p-hub .button.button-primary {
    background-color: #0071ce !important;
    border-color: #0071ce !important;
}
.gradebookList-table tbody tr td {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
}
h1.page-title {
    color: #111827;
    font-size: 20px;
    font-weight: 600;
}
.react-select__custom_error {
    border-radius: 6px;
    /* border: 1px solid rgb(239 68 68 / 1) !important; */
    border-color: #EF4444;
}
.react-datepicker__navigation-icon {
    top: 5px;
}

img {
    -webkit-user-drag: none;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px grey; 
    border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #d1d5db; 
    border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: grey; 
}
.bodyTop .newLeftMenu--desktop {
    padding-top: 60px;
}
body.bodyTop {
    padding-top: 60px;
}
body.bodyTop.Noabsence {
    padding-top: 0px;
}
.Noabsence .newLeftMenu--desktop {
    padding-top: 0px;
}
.recipientAbsenceRequestPage-notification {
    z-index: 9999;
    @apply bg-amber-500 py-3 px-4 text-sm flex gap-2 items-center justify-between text-white fixed top-0 left-0
    w-full;
}
.Noabsence .recipientAbsenceRequestPage-notification {
    display: none !important;
}

body.bodyTop .assigmentBuilder-header,
body.bodyTop .lessonBuilder-header,
body.bodyTop .documentPreview-header,
body.bodyTop .learningPathBuilder-header {
    top: 60px;
}
body.bodyTop.Noabsence .assigmentBuilder-header,
body.bodyTop.Noabsence .lessonBuilder-header,
body.bodyTop.Noabsence .documentPreview-header,
body.bodyTop.Noabsence .learningPathBuilder-header {
    top: 0px;
}
