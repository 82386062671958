.UploadFile {
  &-label {
    @apply text-xs font-semibold;
    .required-text {
      @apply text-red-500;
    }
  }
  &-upload {
    @apply mt-2 rounded-md bg-gray-50 p-4;
    &--border {
      @apply rounded-md bg-white border border-gray-300 border-dashed p-5 text-sm flex flex-col items-center;
      .icon-upload {
        @apply w-20 h-20 text-gray-100;
      }
      .text-upload {
        @apply text-center mt-3;
        .text-link {
          @apply text-primary-500 cursor-pointer;
        }
      }
      .text-information {
        @apply w-full text-xs mt-2;
        &--detail {
          @apply text-gray-500 text-center w-full;
        }
      }
    }
  }

}