.header_skill {
  @apply text-gray-800 font-normal;
  .status {
    @apply text-sm font-normal capitalize;
  }
  .status-draft {
    @apply border border-gray-300 rounded bg-gray-50 text-gray-500;
  }
  .status-published {
    @apply border border-green-500 rounded bg-green-50 text-green-500;
  }
}