.filePreview {
    &-container {
        min-height: calc(100vh - 80px);
        padding-top: 80px;
        @apply flex items-center justify-center pb-8;

        iframe {
            max-width: 100%;
            height: 100%;
            min-width: 800px;
            min-height: 450px;

            &.document {
                min-height: calc(100vh - 120px);
            }
        }

    }
    &-nocontent {
        @apply text-white;
    }
}

.filePreviewHeader {
    @apply flex p-4 fixed w-full;

    &-name {
        @apply text-sm font-semibold text-white;
    }
    &-actions {
        @apply ml-auto;
    }
    &-download {
        @apply w-8 h-8 text-white rounded-full transition p-1 cursor-pointer
        hover:bg-gray-500;
    }
}