.img-bg {
    background-image: url('assets/SVG/img-avatar.svg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}

.subtitle {
    background-color: #2d61db;
    height: 30%;
    width: 100%;
}

.img-bg-resgis {
    background-color: #3e7eff;
    background-image: url('assets/SVG/enterprise-banner.svg');
    background-image: url('assets/SVG/enterprise-banner.svg'),
        linear-gradient(
            166.85deg,
            rgba(31, 71, 183, 0.45) 26.49%,
            rgba(62, 126, 255, 0.45) 89.81%
        );
    background-repeat: no-repeat;
    background-size: cover;
}
.img-bg-login {
    
}
.img-login {
    background-image: url('assets/img/Backgrounds_login.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.logo-ooolab {
    position: absolute;
    top: 3%;
    left: 5%;
}

.btn-more {
    background-color: #f64b7f;
    border-radius: 10px;
}
