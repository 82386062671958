label.InputCheckbox {
    border: 1px solid #6B7280;
    @apply w-5 h-5;
}
label.InputCheckbox.disabled {
    @apply border border-gray-400
}
label.InputCheckbox svg {
    display: none;
}
input[type='checkbox']:checked +label.InputCheckbox {
    @apply border-primary-500 bg-primary-500;
}
input[type='checkbox']:checked:disabled +label.InputCheckbox {
    @apply border-gray-400 bg-gray-400;
}
input[type='checkbox']:checked +label.InputCheckbox svg {
    display: inline-block;
}