.customFields {
    &-content {
        height: calc(100vh - 320px);
        @apply  px-5 py-4  mb-10 overflow-y-auto;
        
        &--isAdd {
            height: calc(100vh - 430px);
        }
    }
    &-list {
        @apply relative;
    }
    &-item {
        @apply flex items-center border-b border-neutral-20 p-4;
    }
    &-name {
        width: 200px;
        @apply flex-none text-sm font-semibold text-gray-800;
    }
    &-action {
        @apply flex ml-auto space-x-3;
        button {
            @apply w-8 h-8 hover:bg-gray-200 rounded flex items-center justify-center transition disabled:cursor-not-allowed;
        }
    }
    &-line {
        width: 1px;
        @apply h-6 bg-neutral-20 mx-4;
    }
    &-type {
        @apply flex space-x-2 flex-wrap;
        span {
            @apply bg-blue-200 text-sm text-blue-600 px-2 py-1 rounded;
        }
    }
    &-addnew {
        @apply px-4 pt-4;
        
    }
}