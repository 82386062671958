.learning_materials {
    .status {
        @apply font-normal text-sm border px-2 py-0;

        &-completed {
            @apply text-green-500 border-green-300 bg-green-50;
        }

        &-in_progress {
            @apply text-blue-500 border-blue-300 bg-blue-50;
        }

        &-not_start {
            @apply text-gray-500 border-gray-300 bg-gray-50;
        }
    }
	.learning_materials-empty_data {
		// position: unset;
		// transform: unset;
	}
}