.documentPreview {
    &-header {
        @apply flex fixed w-full p-4 top-0 left-0 z-9999;
    }
    &-name {
        @apply text-sm ml-2;
    }
    &-actions {
        @apply flex ml-auto text-white space-x-5;
    }
    &-content {
        padding-top: 80px;
        width: 80%;
        height: calc(100% - 80px);
        margin: 0 auto;
        min-height: calc(100vh - 80px);
        @apply flex items-center justify-center mb-10;
        img {
            max-width: 100%;
        }
        audio {
            max-width: 600px;
        }
        
    }
    &-nocontent {
        width: 480px;
        @apply bg-gray-600 rounded-lg text-center text-white px-6 py-4 text-lg;
    }
}