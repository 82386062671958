
  .default {
    @apply capitalize text-xs text-gray-500 border rounded px-2 py-[3px] w-fit;
    &.active {
      @apply text-green-500 bg-green-50 border-green-300;
    }
    &.deactivate {
      @apply text-red-500 bg-red-50 border-red-300;
    }
    &.invite {
      @apply text-orange-500 bg-orange-50 border-orange-300;
    }
    &.registered {
      @apply text-primary-500 bg-primary-50 border-primary-300;
    }
  }