.Notification {
  @apply  flex items-center gap-4;
  .title {
    @apply w-12 h-12 rounded-full relative;
    &.upcoming_assignment {
      @apply bg-pink-500
    }
    &.upcoming_class, &.learner_absence_request {
      @apply bg-primary-500;
    }
    &.pending_session_attendance {
      @apply bg-cyan-50;
    }
    .icon {
      @apply w-5 h-5 text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
    }
  }
  .content {
    @apply flex flex-col gap-1;
    &-type {
      @apply text-sm font-semibold;
    }
    &-subtitle {
      @apply text-sm;
    }
  }
}