.CoursePeople {
  @apply p-5;
  &-title {
    @apply text-lg font-semibold text-dark-800;
  }
  &-tablist {
    @apply w-full text-xs flex gap-4 items-center border-b border-gray-300;
    .tagItem {
      @apply cursor-pointer  py-1 text-center font-semibold text-xs flex focus:outline-none;
      &.active {
        @apply text-primary-500 border-b-2 border-primary-500;
      }
      &.disable {
        @apply text-gray-500;
      }
    }
  }
}