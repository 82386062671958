.tasks {
    @apply bg-white rounded-lg;
    &-header{
        @apply flex flex-col w-full;

        &-name {
            @apply flex flex-row gap-2 w-full items-center;
        }
        
    }
    &-name {
        @apply flex font-semibold text-base text-gray-800;
    }
    &-grade {
        @apply flex items-center ml-auto text-gray-700 text-sm;
        span {
            @apply text-lg text-gray-800 font-semibold ml-2;
        }
    }
    &-icon {
            
    }
    &-status {
        @apply px-2 py-[1px] text-green-500 text-sm border border-green-300 rounded bg-green-50;

        &.waiting {
            @apply text-orange-500 text-sm bg-orange-50 border border-orange-300;
        }
        &.assigned {
            @apply text-gray-500 border-gray-300 bg-gray-50;
        }
    }
    &-info {
        @apply flex flex-row gap-3;
        &-item {
            @apply flex items-center;
        }
        &-label {
            @apply text-gray-500 text-sm mr-2;
        }
        &-value {
            @apply text-gray-800 text-sm;
        }
        &-line {
            @apply h-[22px] w-[1px] bg-gray-300;
        }
    }
    &-container {
        @apply mt-3;
    }
    &-action {
        @apply flex gap-2;
    }
    &-link {
        @apply text-primary-500 text-sm underline pr-2;
    }
    &-btn {
        @apply flex items-center text-primary-500 text-xs gap-1 rounded border border-primary-500 font-semibold py-[3px] px-2;
    }
    table {
        @apply w-full;
        tr {
            th {
                @apply text-left p-2 border-b border-t-0 border-gray-300 text-gray-500 text-xs font-normal;
                &:last-child {
                    @apply pr-0;
                }
            }
            td {
                @apply p-2 border-gray-300 text-gray-800 text-sm;

                &:last-child {
                    @apply pr-0;
                }
            }
        }
    }
    .submission {
        &-description {

            blockquote {
                overflow: hidden;
                padding-right: 1.5em;
                padding-left: 1.5em;
                margin-left: 0;
                margin-right: 0;
                font-style: italic;
                border-left: solid 5px hsl(0, 0%, 80%);
            }

            ol {
                padding-left: 28px;

                li {
                    list-style-type: auto;
                    background-color: unset !important;
                }
            }

            ul {
                padding-left: 40px;

                li {
                    list-style-type: disc;
                    background-color: unset !important;
                }
            }

            h2 {
                @apply font-semibold text-lg text-gray-800;
            }

            strong {
                @apply font-semibold;
            }

            h2,
            p {
                @apply mb-4;
            }

            p,
            a {
                @apply text-gray-800 text-base;
            }

            img {
                @apply rounded-lg;
            }

            i {
                @apply italic;
            }

            ol {
                @apply list-decimal ml-8 mt-4 mb-2;
            }

            ul {
                @apply list-disc ml-8 mt-4 mb-2;
            }

            li {
                @apply text-gray-800 pb-2 text-base;
            }

            a {
                @apply text-enterprise;

                &:hover {
                    @apply underline;
                }
            }
        }
    }
}

audio::-webkit-media-controls-enclosure {
    background-color: transparent;
}