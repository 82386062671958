.buttonBack {
    @apply flex items-center space-x-2;

    &-icon {
        @apply w-8 h-8 rounded-full bg-gray-100 text-gray-900 inline-block p-1 transition;

        .buttonBack:hover & {
            //@apply bg-primary-600 text-white;
        }
    }
    &-text {
        @apply text-base text-gray-800 font-semibold transition;
        .buttonBack:hover & {
            @apply  text-gray-900;
        }
    }
}