  .reminder-description {
    @apply text-sm font-normal mt-1;
  }
  .main-setting {
    @apply mt-5 px-5;
    .reminder-title {
      @apply text-base font-semibold;
    }
    .reminder-description {
      @apply mt-1 text-sm font-normal text-gray-500;
    }
    .reminder {
      @apply flex gap-4 mt-2 py-2 px-4 rounded bg-gray-50 items-start w-fit ml-4;
      .sub-title {
        @apply text-sm text-gray-500 mt-1.5;
      }
      .input {
        @apply flex gap-4 items-start;
      }
    }
  }

  .button-add-reminder {
    @apply flex gap-2 mt-4;
  }
