.LocationDetails {
   @apply w-full flex items-center space-x-1 text-sm font-semibold ml-1;
   &-nameDefault {
    @apply text-gray-500
   }
   &-currentPath {
    @apply text-gray-800 truncate

   }
   span > svg {
      display: inline-block;
   }
}