.sessions {
    &-header {
        @apply flex flex-col py-4 px-5 gap-3 relative z-50;
    }

    &-name {
        @apply flex text-xl text-gray-800 font-semibold flex-none;
    }

    &-search {
        @apply flex gap-2 w-full justify-end;
    }

    &-title {
        @apply flex items-center justify-between w-full;
    }

    &-contentpage {
        @apply p-5 pb-0 border-t border-gray-300;

        &--main {
            min-height: calc(100vh - 330px);

            .loading {
                height: calc(100vh - 330px);
                @apply flex  justify-center items-center;
            }

            .table-container {
                min-height: 420px;
            }
        }
    }
}