.buttonOutline {
    padding: 5px 12px;
    @apply flex space-x-1.5 items-center rounded text-sm font-semibold text-gray-800 border border-gray-300 
    disabled:cursor-not-allowed hover:bg-gray-100
    transition;

    &--isPrimary {
        @apply border-primary-500 hover:border-primary-600 text-primary-500 hover:text-primary-600 disabled:bg-gray-100 disabled:border-gray-400 disabled:text-gray-400;
    }
    &-M {
        @apply text-sm h-8;
    }

    &--isDisable {
        @apply text-gray-400 border border-gray-300 cursor-not-allowed bg-gray-100;
    }
}
