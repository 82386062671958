.custom-date-picker-session .react-datepicker {
  display: flex;
  width: 100%;
}
.custom-date-picker-session .react-datepicker-popper {
  width: auto;
}
.custom-date-picker-session .react-datepicker__header--time {
  height: 64px;
}

.custom-date-picker-session .react-datepicker__time-container {
  width: auto;
}