.bookingInstructor {
    &-title {
        @apply text-gray-800 font-semibold text-lg px-5 py-4;
    }
    &-content {

    }
    &-empty {
        @apply bg-white mt-4;
    }
    &-noInstructor .fc{
        max-height: 117px;
        @apply overflow-hidden;
    }
}

.bookingResourceTime {
    min-height: 506px;
    @apply relative;

    .fc-view-harness {
        min-height: 300px;
        max-height: calc(100vh - 490px);
    }
    .fc-timeline-slot {
        vertical-align: middle;
    }
    .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-next-button,
    .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-prev-button {
        @apply bg-primary-500 hover:bg-primary-500 border-none flex justify-center items-center;
    }
    .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button {
        @apply border-none flex justify-center items-center;
    }
    
    .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-next-button,
    .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-prev-button {
        @apply hover:bg-primary-500 bg-primary-500 border-none flex justify-center items-center;
    }

    .fc .fc-header-toolbar .fc-toolbar-chunk .fc-today-button {
        @apply text-sm font-medium border-gray-300;
    }

    .fc .fc-header-toolbar .fc-button-group .fc-button.fc-button-active {
        color: rgb(0, 113, 206) !important;
        @apply bg-blue-50 text-primary-500 font-normal rounded;
    }
    
    .fc .fc-toolbar.fc-header-toolbar {
        @apply mb-2 pt-4 pb-2 border-t border-b-0 border-gray-300 px-5 flex items-center;

        .fc-toolbar-chunk {
            @apply items-center;
        }
    }
    .fc .fc-h-event, .fc .fc-popover-body .fc-event {
        border-radius: 0px !important;
    }
    .fc .fc-h-event, .fc .fc-popover-body .fc-event {
        margin: 0 !important;
    }
    .fc-event[style] {
        height: 100% !important;
        padding: 0 !important;
    }
    .fc .fc-timeline-event  {
        border: none !important;
    }
    
    .fc .fc-header-toolbar .fc-button-group .fc-button {
        display: inline-block;
        text-transform: capitalize;
    }
    .fc .fc-view .fc-timegrid-event {
        margin: 0 !important;
        overflow: hidden;
        border-radius: 0;
    }
    .fc-timegrid-event .fc-event-main {
        padding: 0;
    }
    .fc-direction-ltr .fc-timegrid-col-events {
        margin: 0!important;
    }
    .fc .fc-timeline-event-harness, .fc .fc-timeline-more-link {
        padding: 1px;
    }
    .fc .fc-timegrid-body .fc-timegrid-col.fc-day-today {
        background-color: transparent;
    }
    .fc .fc-bg-event {
        opacity: 0.8;
    }
    .fc-highlight {
        display: none;
    }
    .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link {
        display: none;
    }
    .fc-media-screen .fc-timegrid-cols {
        overflow: hidden;
    }
    .fc .fc-timeline-slot-cushion {
        text-transform: uppercase;
    }
    .fc-datagrid-cell-main {
        @apply text-gray-800 font-semibold text-sm;
    }
    .fc .fc-datagrid-cell-cushion {
        @apply truncate;
    }
    .fc-theme-standard th {
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 400;
        text-transform: uppercase;
        //@apply bg-transparent;
    }
    .fc-resourceTimeGridDay-view .fc-col-header-cell-cushion {
        @apply text-sm font-semibold text-gray-900 capitalize;
    }
    .fc .dayivew-bg {
        @apply border;

        &.overlapse {
            @apply bg-green-50 z-60 text-green-500 opacity-0 transition;
        }
    }
    .fc .eventContent:hover {
        .overlapse {
            @apply opacity-100 cursor-pointer;
        }
    }
    .fc .fc-timeline-slot-label {
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        @apply bg-white;
    }
    .fc .fc-scrollgrid-liquid {
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        border-radius: 0;
    }
    .fc .fc-scrollgrid-section-header > *, .fc .fc-scrollgrid-section-footer > * {
        border-color: transparent;
        background-color: #fff;
        border-bottom:1px solid #ddd;
    }
    .fc-theme-standard th{
        background-color: #fff;
    }
    .fc-theme-standard td:first-child{
        border-left: 1px solid #ddd;
    }
    .fc .fc-resource-timeline-divider {
        width: 1px;
    }
    .fc-resourceTimeGridDay-view .fc-col-header th  {
        border-bottom: 1px solid #ddd;
    }
    .fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion {
        min-width: 60px;
        text-align: center;
    }
    .header-day {
        @apply mb-2 h-7 w-7 flex mx-auto items-center justify-center rounded-full text-sm font-semibold;

        &.header-today {
            @apply bg-primary-500 text-white;
        }
    }
    .fc th .fc-datagrid-cell-cushion .fc-datagrid-cell-main {
        @apply pl-4 capitalize text-gray-500 text-sm font-normal;
    }
}