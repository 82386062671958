.general {
    @apply text-gray-800;
    &-header {
        @apply w-full flex justify-between mb-8;
        h1 {
            @apply text-xl font-semibold;
        }
    }
    &-form {
        @apply  grid grid-cols-2 gap-5 border-t border-t-gray-300 pt-5;
    }
   
    .workspacePhoto {
        &-title {
            @apply text-sm text-gray-500 mb-3;
        }
        &-upload {
            @apply flex items-center mb-6;
        }
    }

    .dropdown-language {
        button {
            @apply border-gray-300;
        }
        &.dropdown--selected {
            button {
                background-color: #FFFFFF;
                @apply border-gray-300;
            }
        } 
    }
}
