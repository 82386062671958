.panelSetting {
    @apply border border-blue-300 bg-blue-50 rounded-xl p-4 flex gap-3 mb-5;
    
    &-title {
        @apply text-gray-700 text-base font-semibold mb-2;
    }
    p {
        @apply text-gray-700 text-sm;
    }
    &-action {
        @apply ml-auto flex  items-center gap-2.5;
    }
    &-button {
        @apply flex flex-none w-[157px] rounded border border-primary-500 text-sm text-primary-500 py-[5px] px-3 gap-2.5 hover:bg-primary-600 transition hover:text-white;
    }
}