.buttonPrimary {
    padding: 5px 12px;
    @apply rounded text-sm font-semibold text-white bg-primary-500 border border-primary-500
    disabled:cursor-not-allowed disabled:bg-gray-400 disabled:border-gray-400
    hover:bg-primary-600 transition
    flex gap-1 items-center justify-center;

    &-L {
        @apply py-[7px] text-base;
    }
    &-M {
        @apply text-sm;
    }
    &-danger {
        @apply bg-red-500 border-red-500 hover:bg-red-500/80 transition;
    }
    &-secondary {
        @apply text-red-500 border-gray-300 bg-transparent hover:bg-gray-300/80;
    }
}