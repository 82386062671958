.close-icon {
    top: 25%;
    right: 2%;
}

.error-form {
    font-size: 12px;
    color: red;
    font-weight: 500;
    position: absolute;
    bottom: 5%;
}

.input-form {
    border: 2px solid #e0e3f5;
}

.input-focus {
    border: 2px solid #3e7eff;
}

.input-error {
    border: 2px solid #fe3f56 !important;
}

.form-container {
    transition: all 0.3s ease-in-out;
}

