.descripttionRole {
    @apply mb-5 relative col-span-2 w-full;
    &-textarea {
        @apply text-gray-800 text-sm font-normal border border-gray-300 bg-white w-full px-2.5 py-1.5
    h-[92px] rounded relative pb-4 focus:outline-none;
    }
    &-textarea::placeholder {
        @apply text-xs;
    }
    &-label {
        @apply text-gray-800 mb-1 text-xs font-semibold;
    }
    &-sub {
        @apply text-[10px] text-gray-500 absolute right-[3px] bottom-[7px] bg-white p-1;
    }
}
