.addNewLibrary {
    @apply flex items-center w-full p-3 gap-2 bg-white sticky bottom-0 shadow-card;

    .btn-action {
        @apply w-full bg-gray-50 rounded py-3 cursor-pointer hover:text-primary-500;

        .btn-content {
        @apply flex text-sm font-semibold  flex-col justify-center items-center w-full h-full gap-1;
        }
    }
    .line {
        @apply h-12 w-ooolab_w_0.0625 bg-gray-200 mx-1;
    }
}