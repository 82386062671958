.customFieldsForm {
    @apply bg-gray-50 py-4 px-5 border-b border-neutral-20 w-full;

    &-header {
        @apply text-gray-800 text-sm font-semibold mb-2;
    }
    &-item {
        @apply flex;

        label {
            margin-bottom: 5px;
            @apply text-gray-800 text-xs font-semibold block;
        }
        input {
            padding: 1px 8px;
            min-width: 200px;
            @apply border border-gray-300 rounded text-gray-800 text-sm w-full;
        }
    }
    &-error {
        @apply text-red-500 text-xs mt-1 block font-normal;
    }
    &-name {
        width: 200px;
        @apply flex-none text-sm font-semibold text-gray-800;
    }
    &-line {
        width: 1px;
        @apply h-8 bg-gray-200 mx-4 mt-1;
    }
    &-typeList {
        @apply flex space-x-3;

        label {
            @apply text-gray-800 text-sm flex items-center font-normal mb-0;
        }
        input {
            @apply border border-gray-300 h-5 w-5 mr-2 rounded;
        }
    }
    &-actions {
        @apply flex ml-auto space-x-2 h-8;
    }
    &-loading {
        @apply flex items-center;
    }
}