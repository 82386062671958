.gradebookDetailsCourse {
    @apply flex flex-col gap-4;
    &-header {
        @apply bg-white rounded-lg px-5;
    }
    &-title {
        @apply flex items-center mt-4 text-gray-800 font-semibold text-lg;
        button {
            @apply flex items-center justify-center w-8 h-8 rounded-full bg-gray-100 mr-2.5 hover:bg-gray-50;
        }
    }
    &-info {
        @apply py-5 flex items-center justify-between;
    }
    &-list {
        @apply flex items-center gap-6;
    }
    &-label {
        @apply text-gray-500 text-xs;
    }
    &-value {
        @apply text-gray-800 text-xl font-semibold;
    }
    &-line {
        @apply w-[1px] h-6 bg-gray-200;
    }
    &-overall {
        @apply bg-gray-100 min-h-[52px] rounded-lg py-2 px-4 flex items-center text-xs;
        span {
            @apply text-gray-800 text-[28px] font-semibold ml-2;
        }
    }
    &-content {
        @apply rounded-lg p-5 bg-white;
    }
    &-container {
        @apply py-4 bg-white rounded-lg rounded-tl-none;
    }
}
.gradebookDetailsTab {
    @apply flex;

    &-item {
        min-width: 146px;
        @apply bg-gray-100 flex items-center justify-center mr-1 cursor-pointer
            text-center h-8 rounded-tl-lg rounded-tr-lg text-xs font-semibold text-gray-500 py-1.5 px-3
            hover:text-primary-600;
        &--actived {
            @apply text-primary-500 bg-white;
        }
    }
}