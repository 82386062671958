.settings {
    &-header {
        @apply px-6 py-4 text-base text-gray-900 font-semibold
        border-b border-gray-300 flex items-center;
    }
    &-close {
        @apply ml-auto w-6 h-6
        flex items-center justify-center transition;
    }
    &-container {
        @apply flex flex-row;
    }
    &-sideNav {
        width: 196px;
        @apply flex-none border-r border-neutral-20 py-3;
        ul {
            li {
                @apply px-4 py-3 text-sm font-semibold text-blue-500 border-l-2 border-blue-500;
            }
        }
    }
    &-main {
        @apply w-full;
        &-header{
            @apply border-b border-neutral-20 py-3 px-5 w-full;
        }
        &-title {
            @apply text-gray-900 text-xl font-semibold mb-1;
        }
        &-description {
            @apply text-gray-500 text-sm font-normal;
        }
        &-actions {
            @apply px-5 py-4 flex border-b border-neutral-20;
            >.btn-new {
                @apply ml-auto;
                &-content {
                    @apply flex w-fit;
                    >.icon {
                        @apply w-4 h-4 mr-1;
                    }
                }
            }
        }
        &-search {
            max-width: 320px;
            @apply w-full;
        }
    }
}