.pagination {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 48px;
}

li {
    padding: 0.5rem 0;
    cursor: pointer;
    background-color: #ffffff;
    color: #707885;
    position: relative;
}
li a {
    padding: 0 0.5rem;
    display: inline-block;
}
.selected {
    color: #0071ce;
    font-weight: 600;
}

.selected::before {
    position: absolute;
    top: -2px;
    left: 2px;
    width: 80%;
    height: 3px;
    border-radius: 4px;
    background-color: #0071ce;
    content: '';
}

.disabled {
    cursor: not-allowed;
}
