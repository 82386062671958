.avatar {
  @apply w-7 h-7 min-w-[28px] border rounded-full border-light-100;
}
.sessionList {
  &-status {
    @apply text-sm font-normal border rounded px-2 py-0.5 inline-block capitalize whitespace-nowrap;
    &--scheduled {
      @apply border-primary-300 bg-primary-50 text-primary-500;
    }
    &--learner_booking {
      @apply border-pink-300 bg-pink-50 text-pink-500;
    }
    &--admin_booking {
      @apply border-purple-300 bg-purple-50 text-purple-500;
    }
    &--not_started {
      @apply border-amber-300 bg-amber-50 text-amber-500;
    }
    &--in_progress {
      @apply border-blue-300 bg-blue-50 text-blue-500;
    }
    &--completed {
      @apply border-green-300 bg-green-50 text-green-500;
    }
    &--cancelled {
      @apply border-red-300 bg-red-50 text-red-500;
    }
    &--attendance_not_available {
      @apply border-gray-300 bg-gray-50 text-gray-500;
    }
    &--attendance_absent {
      @apply border-red-300 bg-red-50 text-red-500;
    }
    &--attendance_present {
      @apply border-green-300 bg-green-50 text-green-500;
    }
    &--attendance_late {
      @apply border-amber-300 bg-amber-50 text-amber-500;
    }
    &--attendance_cancelled {
      @apply border-purple-300 bg-purple-50 text-purple-500;
    }
  }
}