.CourseOverviewGradebookList {
    @apply bg-white rounded-lg;

    &-title {
        @apply text-gray-800 text-lg font-semibold mb-4;
    }
    &-filter {
        @apply flex gap-2;
    }
    &-table {
        height: 500px;
        @apply mt-4 overflow-y-auto;
        &--loading {
            height: 500px;
            @apply flex items-center justify-center
        }
    }
}