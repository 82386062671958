.sectionListWrapper {
    @apply max-h-[calc(100vh-280px)] overflow-y-auto overflow-x-hidden;
}

.section {
    &-item {
        @apply bg-white rounded-lg w-full mb-2 gap-1
        text-sm text-gray-800 font-semibold border border-transparent items-center
        hover:bg-gray-50 hover:border-primary-500 hover:shadow-input transition cursor-pointer;
        &.active {
            @apply bg-gray-50 border-primary-500 hover:shadow-input;
        }
        &-center {
            @apply flex pl-1 pr-2 py-2.5;
        }
    }
    &-reorder {
        @apply text-gray-400 w-5 flex-none;
    }
    &-badge {
        min-width: 20px;
        @apply rounded-full justify-center text-primary-500 h-5 px-1.5 flex items-center font-semibold
        bg-blue-50 ml-2;

        .section-item:hover & {
            @apply hidden;
        }
    }
    &-delete {
        @apply ml-auto hidden;

        .section-item:hover & {
            @apply block;
        }
        svg {
            @apply w-4 h-4;
        }
    }
    &-input {
        @apply focus:bg-primary-500/30 focus:text-primary-500 bg-transparent w-full;
    }
    &-name {
        max-width: calc(100% - 70px);
        @apply flex;
    }
    &-list {
        @apply transition;
    }
}

.newSection {
    @apply text-base font-semibold text-primary-500 hover:text-primary-600 bg-white rounded-lg px-4 py-2 w-full flex items-center justify-center space-x-2
    hover:bg-gray-50 border border-transparent hover:border-primary-500 hover:shadow-input transition cursor-pointer;
}

.sticky-btnNewSection {
    @apply  bg-blue-50 sticky bottom-0;
}

.section-content {
    @apply pl-1 pr-2 pb-2;
    &.parent {
        @apply pr-0 pb-0 cursor-pointer;
    }
    &-item {
        @apply ml-5 flex mb-1 justify-between hover:bg-blue-100 hover:text-primary-500 rounded;
        &.parent-item {
            @apply ml-0 bg-white mb-2 py-0.5;
        }
        &.active {
            @apply bg-blue-100;
        }
        &.parent-active {
            @apply text-primary-500;
        }
        .flex-item {
            @apply flex p-2 gap-2 items-center w-full;
            max-width: calc(100% - 40px);
            .library-icon {
                @apply h-5 w-5;
            }
            .file-name {
                @apply text-sm font-normal w-full text-ellipsis truncate;
            }
        }
    }
    >.assignmentItem {
        @apply hover:bg-white hover:text-primary-500 border border-primary-500;
        &.active {
            @apply bg-white border border-primary-500;
        }
    }
}

.maxLengthText {
    @apply text-red-500 text-xs font-normal ml-14 -translate-y-1;
    &.maxLength-section {
        @apply -translate-y-2 ml-6;
    }
}