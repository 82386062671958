.sessions {
    &-page {
        @apply bg-gray-50 min-h-screen;
    }

    &-tabs {
        @apply flex px-5 pt-2 w-full border-b border-gray-50 bg-white;

        a {
            @apply text-gray-500 font-semibold text-xs py-2 px-3;

            &.active {
                @apply border-b border-primary-600 text-primary-600;
            }
        }
    }

    &-content {
        @apply p-5;
    }

    &-container {
        @apply bg-white rounded;
    }

    &-container .Calendar-sessions {
        padding: 24px;
        padding-top: 0;

        .fc .fc-toolbar.fc-header-toolbar {
            margin-left: -24px;
            margin-right: -24px;
            padding: 20px 24px;
        }
    }
}