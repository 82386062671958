
  .FileWithAction {
      @apply flex mb-1 justify-between hover:bg-blue-100 hover:text-primary-500 rounded mb-2;
      &.standalone-item {
          @apply ml-0 bg-white mb-2 py-0.5;
      }
      &.active {
          @apply bg-blue-100;
      }
      &.stanndalone-active {
          @apply text-primary-500;
      }
      .flex-item {
          @apply flex p-2 gap-2 items-center w-full;
          max-width: calc(100% - 40px);
          .library-icon {
              @apply h-5 w-5;
          }
          .file-name {
              @apply text-sm font-normal w-full text-ellipsis truncate;
          }
      }
  }

  .maxLengthFileName {
    @apply ml-8 text-red-500 text-xs;
  }
  