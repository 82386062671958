.CourseType { 
  @apply flex gap-2 text-sm items-center h-full flex-wrap capitalize;
  &-item {
    @apply px-2 h-fit text-center rounded border border-gray-300 capitalize bg-gray-50 text-xs text-gray-800;
  }
  &-viewmore {
    @apply px-2 h-fit text-center rounded border border-gray-300 capitalize  bg-gray-50 text-xs text-gray-800 hover:border-primary-500 hover:text-primary-500;
  }
}
.CourseStatus {
  @apply text-sm font-normal border rounded px-3 py-[1px] inline-block capitalize;
  &.active {
    @apply text-green-500 border-green-300 bg-green-50;
  }
  &.inactive {
    @apply text-gray-500 border-gray-300 bg-gray-50 text-xs;
  }
}
.courseAction {
  @apply h-full flex items-center justify-center;
}