label.inputRadio {
    @apply w-5 h-5 border border-gray-400 mt-0.5;
}
label.inputRadio svg {
    display: none;
}
input[type='radio']:checked +label.inputRadio {
    background-color: #0071CE;
    border-color: #0071CE;
}
input[type='radio']:checked +label.inputRadio svg {
    display: inline-block;
}