.from-library {
  .header {
    @apply flex px-5 pb-5 justify-between border-b border-gray-300;
    &-title {
      @apply text-base font-semibold;
    }
    &-icon {
      @apply w-5 h-5 text-gray-800;
    }
  }
  .breadcrumb {
    @apply flex justify-between outline-none px-1;
    &-container {
      @apply z-10 sticky top-0;
    }
  }
  .filter {
    @apply border-b border-gray-300 py-4 flex justify-between outline-none px-5;
  }
  .footer {
    @apply flex justify-between items-center px-5 py-4 border-t border-gray-300;
    .fileSelected {
      @apply text-sm text-gray-400 font-normal;
    }
  }
}

.tableLibrary {
  min-height: 290px;
  &-header {
    @apply  w-full flex text-gray-800 text-sm font-semibold px-5 border-b border-gray-300;
    &-item {
      flex: 1;
      @apply min-w-[10rem] py-2;
      &.name {
        max-width: 50%;
        @apply w-full py-2;
      }
      &.action {
        @apply flex items-center gap-1 text-primary-500;
        >.icon {
          @apply w-4 h-4 ml-1;
        }
      }
    }
  }

  &-row {
    @apply flex hover:bg-blue-50 px-5 bg-white;
    &.active {
      @apply bg-blue-50;
    }
    &.disabled {
      @apply opacity-30 pointer-events-none;
    }
    &-item {
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
      flex: 1;
      @apply min-w-[10rem] py-2 text-sm text-gray-800;
      &.name {
        max-width: 50%;
        @apply w-full ;
      }
      >.center-item {
        @apply flex items-center w-full;
        >.icon {
          @apply h-6 w-6 rounded-full;
        }
        >.content-name {
          @apply w-full pl-1 font-normal text-sm text-gray-800 cursor-pointer truncate pr-2;
        }

      }
    }
  }

  &-loading {
    @apply flex items-center justify-center mt-4;
  }

  &-scrollbar {
    @apply overflow-scroll h-60 pb-6;
  }
}