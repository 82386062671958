.modalmoveto {
  @apply pt-2;

  &-group-button {
    @apply flex justify-center gap-4 mt-8;
  }

  &-popover {
    @apply relative;

    &-button {
      @apply rounded border border-gray-300 p-2 text-sm font-normal w-full flex justify-between items-center;

      .folder-name {
        max-width: 340px;
        @apply text-ellipsis truncate;
      }
      >.icon {
        @apply w-4 h-4 text-gray-500;
      }
    }

    &-panel {
      max-height: 300px;
      @apply absolute z-10 bg-white shadow-md left-0 w-full rounded overflow-auto;

      .back-btn {
        @apply flex text-sm p-2 gap-1 items-center cursor-pointer;
        >.icon {
          @apply w-4 h-4 text-gray-900;
        }
      }

      .folder-item {
        &.folder-item-active {
          @apply bg-blue-400 text-white;
        }

        &.folder-item-normal {
          @apply bg-white text-gray-800 hover:bg-blue-200 hover:text-white;
        }

        @apply p-2 cursor-pointer flex items-center justify-between;

        &-detail {
          @apply flex gap-1 items-center text-sm text-gray-500;

          &-name {
            max-width: 340px;
            @apply text-gray-800 text-ellipsis truncate;
          }
        }

        >.icon {
          @apply w-4 h-4 text-gray-800;
        }
      }
    }
  }
}