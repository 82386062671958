.slotDetails {
    &-header {
        @apply flex px-4 border-b border-gray-300 text-sm text-gray-800 font-semibold pb-3 items-center break-all;
    }
    &-close {
        @apply ml-auto;
    }
    &-content {
        @apply py-5 px-4;
    }
    &-slot {
        @apply flex items-center border border-green-300 bg-green-50 text-base text-green-500 py-2 px-3 rounded max-h-10;
    }
    &-list {
        @apply flex flex-wrap gap-2;
    }
    &-title {
        @apply text-base text-gray-800 mb-2;
    }
    &-add {
        @apply px-4 mb-4;
        .buttonOutline {
            @apply border-primary-500 text-primary-500 h-10;
        }
    }
    &-slotBooked {
        @apply bg-gray-200 text-gray-500 border-gray-200 cursor-not-allowed;
    }
}