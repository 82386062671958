.learningPathLesson {
    &-title {
        @apply text-gray-500 text-base font-semibold;
    }
    &-add {
        @apply border border-gray-300 px-8 py-3 text-primary-500 rounded-lg w-full 
        text-sm flex items-center mt-4 hover:text-primary-600 hover:bg-blue-50 hover:border-gray-400 transition
        relative;
    }
    &-dot {
        @apply w-3 h-3 border border-gray-400 rounded-full inline-block -left-[33px] absolute bg-white;
    }
}