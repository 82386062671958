.custom-property {
  .tab-property-container {
    position: relative;
    &::after {
      position: absolute;
      bottom: -8px;
      left: 0;
      content: '';
      width: 95%;
      height: 8px;
      background-color: white;
    }
  }
  .tab-property {
    position: relative;
    &::after {
      position: absolute;
      bottom: 0px;
      left: 0;
      content: '';
      width: 100%;
      height: 0.5px;
      background-color: #f4f5f6;
    }
    &-active::after {
      background-color: white;
    }
  }
  .show-hide-property .pagination {
    padding-right: 20px;
  }
  .property-custom-scrollbar::-webkit-scrollbar-track {
    margin-top: 5px;
  }
}