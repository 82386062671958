.guideAddingBank {
    @apply text-center py-2;
    h2 {
        @apply text-gray-700 text-sm font-normal;
    }
    p {
        @apply text-gray-500 text-xs font-normal mt-1 leading-5;
    }
    a {
        @apply text-primary-500 hover:underline items-center ml-1;
    }
}