.monthPicker .react-datepicker__triangle {
    display: none;
}
.monthPicker .react-datepicker {
    @apply rounded border border-gray-300 w-full;
}
.monthPicker .react-datepicker-popper {
    margin-top: -5px;
    right: 0;
}
.monthPicker .react-datepicker__navigation {
    padding-top: 13px;
    width: 15px;
}
.monthPicker .react-datepicker__month {
    margin: 0;
    max-height: 145px;
    overflow: auto;
}
.monthPicker .react-datepicker__month-text  {
    width: 100%;
    padding: 0.5rem 1rem;
    margin: 0;
    @apply text-gray-800 font-display;
}
.monthPicker .react-datepicker__month-text:hover,
.monthPicker .react-datepicker__month-text.react-datepicker__month--selected {
    border-radius: 0;
    @apply bg-blue-50 text-gray-800;
}
.monthPicker .react-datepicker__header {
    border: none;
}