.presentation-mode-assignment {
    @apply relative h-full min-h-screen overflow-auto bg-gray-50;

    .header {
        @apply h-12 w-full fixed top-0 justify-between z-30;

        &-container {
            @apply h-12 w-full min-h-0 bg-white border-b border-gray-300 items-center justify-between px-5;
        }
        &-icon {
            @apply cursor-pointer flex items-center gap-2 text-base font-semibold;
            .background-icon {
                @apply bg-gray-200 p-1 rounded-full w-fit;
                .icon {
                    @apply w-5 h-5 text-gray-500 hover:text-gray-900;
                }
            }
        }
        &-title {
            @apply font-semibold text-base;
        }
        &-group-icon {
            @apply flex gap-4;
            .sub-icon {
                @apply bg-gray-200 flex justify-center items-center cursor-pointer rounded-full w-8 h-8 text-gray-500 hover:text-gray-900;
            }
        }
    }
    .main-content {
        padding-top: 4rem;
        @apply flex items-center justify-center relative m-auto overflow-auto;

        .file-container {
            min-height: calc(100vh - 9rem);
            max-width: 1173px;
            @apply bg-white w-full flex justify-center overflow-auto h-auto mb-16 items-center relative;

            .document {
                width: 100%;
                height: calc(100vh - 10rem);
            }

            .audio {
                @apply w-full mx-auto flex items-center;

                > audio {
                    margin: auto;
                }
            }

            &.zoom {
                max-width: 100%;
                @apply lg:w-[100vw] h-auto overflow-scroll px-4;

                .document {
                    width: 100%;
                    height: 100%;
                    min-height: 100vh;
                }
                .previewContent-image {
                    height: 100vh;
                }
            }
            &-loading {
                @apply absolute inset-0 flex justify-center items-center bg-white;
            }
        }
        .no-data {
            @apply flex items-center;
        }
    }
    .navigation {
        @apply fixed z-10 left-1/2 -translate-x-1/2 px-8 h-16;
        width: 600px;
        bottom: 4px;

        &-container {
            @apply py-3 justify-center gap-2 rounded-lg;
        }
        &-dropdown {
            @apply h-10 bg-white w-[300px] text-gray-500 border border-gray-300 rounded-lg p-2 flex items-center truncate text-ellipsis gap-2 shadow-card_common;
            &-button {
                @apply flex justify-between items-center w-full;
                .file-name {
                    @apply text-ellipsis text-base truncate w-full text-left;
                    max-width: 225px;
                }
                .icon {
                    @apply w-4 h-4 text-gray-500;
                }
            }
        }
        &-panel {
            @apply absolute z-10 bg-white rounded shadow-md w-full bottom-full max-h-96 overflow-scroll;

            .assignment {
                @apply sticky top-0 px-4 py-3 text-sm text-gray-500 bg-white truncate text-ellipsis;
                &-item {
                    @apply cursor-pointer p-2 pl-4 text-sm truncate font-normal hover:bg-gray-50;
                    &.active {
                        @apply text-white bg-blue-300;
                    }
                }
            }
        }
        &-button {
            @apply h-10 bg-white cursor-pointer border border-gray-300 rounded-lg p-2 flex items-center w-fit gap-2 shadow-card_common text-gray-400 pointer-events-none;
            .active {
                @apply pointer-events-auto text-gray-500 hover:text-gray-900;
            }
            .line {
                @apply h-full w-[1px] bg-gray-300;
            }
            .index-page {
                @apply border border-gray-300 px-3 py-0.5 text-sm text-gray-800 rounded;
            }
            .total-slide {
                @apply text-gray-700;
            }
        }
        .button-zoom {
            @apply h-10 w-10 bg-white cursor-pointer border text-gray-500 hover:text-gray-900 border-gray-300 rounded-lg p-2 flex items-center shadow-card_common;
        }
    }
}
