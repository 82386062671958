.inputFile {
    @apply relative bg-gray-100 border border-gray-200 rounded-lg relative flex items-center justify-center;

    input {
        @apply absolute z-[11] top-0 left-0 w-full h-full opacity-0 cursor-pointer;
    }
    svg {
        @apply mx-auto mb-2;
    }
    &-content {
        @apply text-gray-500 text-center absolute w-full h-full z-[1] flex flex-col justify-center bg-gray-100 transition;
        .inputFile:hover & {
            opacity: 100;
        }
    }
    &-text {
        @apply text-gray-500 text-xs leading-5;
    }
}