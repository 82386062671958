.lessonContent {
    @apply border border-gray-300 rounded-lg my-2 relative;

    .lessonContent--drag & {
        @apply overflow-hidden;
    }
    &-wrapper {
        @apply relative;
    }
    &-title {
        @apply text-gray-800 text-sm font-semibold py-3 px-2 cursor-move
        flex items-center text-left w-full hover:bg-blue-50 transition rounded-lg;
    }
    &-scorm {
        @apply rounded border border-gray-300 bg-gray-50 flex text-xs text-gray-500 py-1 px-1.5 space-x-1;
    }
    &-content {
        @apply bg-gray-50 border-t border-gray-300 px-[38px] py-4 relative;
    }
    &-label {
        @apply w-[72px] flex-none text-xs text-gray-800 mr-4;
    }
    &-value {
        @apply text-xs text-gray-800 flex flex-wrap w-full;
        > div {
            @apply mr-4 mb-0.5;
        }
    }
    &-dot {
        top: 50%;
        margin-top: -6px;
        @apply w-3 h-3 bg-gray-400 rounded-full inline-block -left-[32px] absolute;

        .lessonContent--drag & {
            @apply hidden;
        }
    }
    &-wrapper {
        @apply relative;
    }
}