.LearnerGradebookAssignment {
    @apply flex flex-col gap-4;

    &-content {
        height: calc(100vh - 515px);
        overflow: auto;
    }
    .status-assignment {
        padding: 2px 8px;
        @apply font-normal text-xs border border-gray-300 bg-gray-50 text-gray-500 rounded;
        &-completed {
            @apply text-green-500 border-green-300 bg-green-50;
        }
        &-in_progress {
            @apply text-blue-500 border-blue-300 bg-blue-50;
        }
        &-waiting_for_grade {
            @apply text-orange-500 border-orange-300 bg-orange-50;
        }
        &-overdue {
            @apply text-red-500 border-red-300 bg-red-50;
        }
    }
}