.lessonActions {
    @apply relative z-10;

    &-panel {
        @apply absolute z-[999] min-w-[200px] top-[25px] right-0 shadow-card_common rounded-lg bg-white;
    }
    &-item {
        @apply flex text-gray-800 font-normal text-sm px-4 py-2 space-x-2 hover:bg-gray-100 w-full transition;
        &--border {
            @apply border-b border-gray-300;
        }
    }
}