.lessonBuilder-content {
  @apply h-[calc(100vh-190px)] w-full pb-4;

  &--noDelete {
    height: calc(100vh - 140px);
  }

  &-delete {
    @apply cursor-pointer text-gray-500 hover:text-gray-700;
  }

  .current-section-bar {
    max-width: calc(100vw - 400px);
    @apply py-3 px-6 text-xl text-gray-800 font-semibold border-b border-gray-300 flex justify-between bg-white;
    &-title {
      @apply max-w-[calc(100%-36px)] text-ellipsis truncate;
    }
    .icon {
      @apply w-5 h-5 text-gray-500 mt-1;
    }
  }

  .lessonBuilder-render-content {
    min-height: calc(100vh - 206px);
    &.lessonBuilder-render-assignment {
      min-height: calc(100vh - 120px);
    }
    @apply h-full px-10 py-8 m-auto overflow-auto;
    .previewContent-content {
      height: 100%;
      .previewContent {
        justify-content: flex-start;
        padding-top: 0px;
        overflow-x: hidden;
        overflow-y: scroll;
        ::-webkit-scrollbar {
          display: none;
        }
        &-header {
          position: sticky;
          top: 0px;
          left: 0px;
          right: 0px;
          width: 100%;
          .wrapper {
            max-width: calc(100% - 74px);
            > .title {
              max-width: calc(100vw - 585px);
            }
          }
        }
        > .audio {
          margin: auto;
          width: 100%;
          max-width: 450px;
          height: 40px;
          padding: 0 20px;
        }
      }
    }
    &.mobile-view {
      ::-webkit-scrollbar {
        display: none;
      }
      @apply h-full;
      .previewContent {
        width: 375px;
        @apply mx-auto;
        > .document {
          min-height: unset;
        }
      }
    }
  }

  

  .action-bar {
    @apply flex items-center w-full p-3 gap-2 bg-white sticky bottom-0 shadow-card;

    .btn-action {
      @apply w-full bg-gray-50 rounded py-3 cursor-pointer hover:text-primary-500;

      .btn-content {
        @apply flex text-sm font-semibold  flex-col justify-center items-center w-full h-full gap-1;
      }
    }
    .line {
      @apply h-12 w-ooolab_w_0.0625 bg-gray-200 mx-1;
    }
  }
}