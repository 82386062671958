.courseInformation {
    @apply p-5 bg-white rounded-lg;
    &-label {
        @apply text-xs font-semibold text-gray-800 mb-1 block;
    }
    &-input {
        @apply text-sm  text-gray-800 py-[5px] px-2.5 border border-gray-300 rounded w-full;
    }
    .error {
        @apply text-[10px] ml-2.5;
    }
    &-item {
        @apply mb-5;
    }
    &-templateCard {
        @apply bg-white rounded p-5;
    }
    .video-js {
        min-width: 0 !important;
        min-height: 0 !important;
        height: 240px;
    }
}
