:root {
  --fc-now-indicator-color: #EF4444;
}

.Calendar {
  color: #1F2937;

  .upcoming-section {
    max-height: calc(100vh - 180px);
    min-height: 490px;
    overflow-y: auto;
  }

  .fc .fc-header-toolbar .fc-toolbar-chunk:first-child {
      width: 50%;
      display: flex;
  }

  .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-primary:first-letter {
      text-transform: uppercase;
  }

  .fc .fc-header-toolbar .fc-toolbar-chunk .fc-today-button {
      border: 1px solid #2e3a59;
      color: #2E3A59;
      font-size: 12px;
      font-weight: 600;
      background-color: transparent;
      height: 32px;
      border-radius: 4px;
  }

  .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button {
      color: #8F90A6;
      font-size: 12px;
      font-weight: 400;
      background-color: transparent;
      height: 32px;
  }

  .fc .fc-header-toolbar .fc-toolbar-title {
      font-weight: 600;
      color: #282b5e;
      padding-top: 5px;
      font-size: 16px;
  }

  .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-timeGridDay-button,
  .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-timeGridWeek-button,
  .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-dayGridMonth-button {
      color: #8f90a6;
      border: none;
  }

  .fc .fc-button-primary:focus,
  .fc .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
      box-shadow: none !important;
  }

  .fc .fc-header-toolbar .fc-button-group .fc-button.fc-button-active {
      min-width: 60px;
      background-color: #E5E7EB;
      color: #1F2937 !important;
      border-radius: 8px;
  }

  .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-next-button,
  .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-prev-button {
      background-color: #1F2937;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      color: #fff !important;
      padding: 0;
      border: none;
      display: flex;
      justify-items: center;
      align-items: center;
  }

  .fc-theme-standard .fc-scrollgrid {
      border: none;
      border-radius: 8px;
      overflow: hidden;
  }

  .fc-theme-standard th {
      color: #8f90a6 !important;
      font-size: 12px;
      font-weight: 600;
      height: 50px;
      background-color: #F2F5FA;
  }

  .fc-theme-standard th>div {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    
      .fc-col-header-cell-cushion {
        padding-left: 6px;
        padding-right: 6px;
      }
  }

  .fc-timeGridDay-view .fc-scrollgrid-sync-inner{
    justify-content: left;
  }

  .fc-col-header-cell-cushion {
    font-size: 14px;
    color: #1F2937;

    .header-day {
      font-style: normal;
      font-weight: 600;
      text-align: center;

      &.header-today {
        margin-top: 2px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #0071CE;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .fc .fc-daygrid-day-top {
      justify-content: center;
  }

  .fc .fc-daygrid-day-top>a {
      font-size: 14px;
      font-weight: 400;
  }

  .fc .fc-timegrid-slot-label-cushion,
  .fc .fc-timegrid-axis-cushion {
      font-size: 14px;
      font-weight: 400;
      padding-right: 6px;
  }

  .fc .fc-h-event,
  .fc .fc-popover-body .fc-event {
      color: #2e3a59;
      background-color: #cce8ff;
      border-color: #cce8ff;
      padding: 0 8px !important;
      border-radius: 8px !important;
      margin-bottom: 4px;
      font-size: 9px;
  }

  .fc .fc-h-event .fc-event-title,
  .fc .fc-daygrid-more-link {
      font-size: 9px;
      font-weight: 700;
      color: #2e3a59;
  }

  .fc .fc-daygrid-more-link {
      font-size: 10px;
      margin-left: 5px;
  }

  .fc-popover-body .fc-daygrid-event-dot,
  .fc-popover-body .fc-event-time {
      display: none !important;
  }

  .fc .fc-daygrid-body .fc-daygrid-day.fc-day-today {
      background-color: transparent;
  }

  .fc .fc-timegrid-body .fc-timegrid-col.fc-day-today {
      background-color: #ffffff; // #f2f9ff
  }

  .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #0071CE;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4px;
      margin-bottom: 4px;
  }

  .fc .fc-view .fc-h-event .fc-event-title,
  .fc .fc-daygrid-more-link {
      font-size: 8px;
  }

  .fc .fc-event-selected,
  .fc .fc-event:focus {
      box-shadow: none;
  }

  .fc .fc-col-header th {
      border-color: transparent;
  }

  .fc .fc-dayGridMonth-view .fc-daygrid-dot-event .fc-daygrid-event-dot,
  .fc .fc-dayGridMonth-view .fc-daygrid-dot-event .fc-event-time,
  .fc .fc-dayGridMonth-view .fc-event .fc-event-time {
      display: none;
  }

  .fc .fc-dayGridMonth-view .fc-event {
      height: 100%;
      font-size: 8px;
      font-weight: 600;
      background-color: #EEF1FA;
      padding: 1px 8px;
      cursor: pointer;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
  }
    .fc-daygrid-day-bottom {
      position: absolute;
      top: -3px !important;
      bottom: 0px !important;
      inset: 0;
      text-align: center;
      padding: 6px;
      display: flex;
      margin-top: 0px;
      .fc-daygrid-more-link {
        width: 100%;
            margin-left: 0px;
      }
    }
    
    .fc {
      .fc-daygrid-day-bottom {
        margin-top: 0px !important;
      }
      .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
        height: 75%;
        padding: 2px 6px 6px 6px;
      }
      .fc-dayGridMonth-view {
        .fc-event {
          font-size: 8px;
          font-weight: 600;
          cursor: pointer;
          // overflow: auto !important;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: Lexend, sans-serif;
          --tw-text-opacity: 1;
          width: 100%;
          margin-left: 0;
          margin-right: 0;
          background-color: unset !important;
          border-radius: unset !important;
          border: none;
          padding: 0px;
        }
      }
      .fc-dayGridMonth-view {
        .fc-event {
          background-color: unset !important;
          border-radius: unset !important;
          border: none;
          padding: 0px;
        }
        .fc-daygrid-event-harness {
          padding: 0;
          height: 95%;
        }
      }
      .fc-daygrid-event-harness-abs {
        position: absolute;
        max-width: 100%;
      }
      .fc-daygrid-day-frame {
        height: 95%;
        min-height: 80px;
      }
      .fc-h-event, .fc .fc-popover-body .fc-event {
        padding: 0 4px !important;
      }
      .fc-popover {
        visibility: hidden;
      }
      .fc-view .fc-timegrid-event {
        padding: 0;
        background-color: unset;
      }

      .fc-view .fc-timegrid-event:hover {
        background-color: unset;
      }
    } 
    .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-next-button, .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-prev-button {
      @apply bg-primary-500 hover:bg-primary-500 border-none flex justify-center items-center;
    }
    .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-next-button, .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button-group .fc-button.fc-prev-button {
      @apply hover:bg-primary-500 bg-primary-500 border-none flex justify-center items-center ml-2;
    }
    .fc .fc-header-toolbar .fc-toolbar-chunk .fc-today-button {
      @apply text-sm font-medium border-gray-300;
    }
    .fc .fc-header-toolbar .fc-button-group .fc-button.fc-button-active {
      color: rgb(0, 113, 206) !important;
      @apply bg-blue-50 text-primary-500 font-normal rounded;
    }
    .fc-theme-standard th {
      background-color: unset !important;
    }
    .fc .fc-scrollgrid-section-liquid > td {
      @apply border border-gray-300;
    }
    .fc .fc-scrollgrid-section > * {
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0;
    }
    .fc .fc-dayGridMonth-view {
      border: unset;
    }
    .fc-theme-standard th {
      @apply text-sm uppercase text-gray-500 font-normal;
    }
    .fc .fc-toolbar.fc-header-toolbar{
      @apply mx-[-20px] mb-2 py-5 border-t border-b border-gray-300 px-5;
    }

    .fc .fc-timegrid-col-frame {
      // overflow: auto;
    }

    .fc-timegrid-col-events {
      .fc-timegrid-event-harness {
        // min-width: 80px;
        // width: 100%;
      }
    }

    .fc-dayGridMonth-view {
      .fc-day-past {
        // filter: grayscale(100%);
        .multi-event {
          @apply border-gray-300 bg-gray-50;
          .multi-event-text {
            @apply text-gray-500;
          }
        }
      }
    }


    .fc-day-today  {
      .fc-scrollgrid-sync-inner {
        .fc-col-header-cell-cushion {
          width: 40px;
        }
      }
    }

    .fc-day-future, .fc-day-past {
      .fc-scrollgrid-sync-inner {
        .fc-col-header-cell-cushion  {
          width: 48px;
        }
      }
    }
    
    .fc .fc-timegrid-col-frame {
      // overflow: auto;
    }
    
    .fc .fc-scroller {
      overflow: hidden hidden !important;
    }
    .fc .fc-scroller-liquid-absolute {
      overflow: auto !important;
    }
    .fc-daygrid-event-harness {
      z-index: 1;
    }
    .fc-scrollgrid-section, .fc .fc-scrollgrid-section table, .fc .fc-scrollgrid-section>td {
      // overflow: hidden;
    }

    .fc-theme-standard th > div {
      height: 52px;
    }
    .fc-v-event{
      border: none;
    }
    .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link {
      box-shadow: none;
    }
    .fc .fc-timegrid-body .fc-timegrid-col.fc-day-today {
      // overflow: auto;
    }
  
  .fc-timegrid-now-indicator-arrow {
    display: none;
  }

  .fc-timeGridDay-view,
  .fc-timeGridWeek-view {
    .fc-timegrid-now-indicator-container {
      overflow: unset;

      .fc-timegrid-now-indicator-line {
        border-width: 1px;
        &::before {
          content: "";
          position: absolute;
          left: -6.5px;
          top: -5px;
          width: 10px;
          height: 10px;
          background: var(--fc-now-indicator-color);
          border-radius: 100%;
          z-index: 4;
        }
      }
    }
  }
}





