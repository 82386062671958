.scorm {
    &-header {
        @apply flex bg-white;
    }
    &-iframe {
        height: calc(100vh - 260px);
        min-height: 670px;
        @apply w-full overflow-y-auto;
    }
    &-nocontent {
        min-height: calc(100vh - 142px);
        @apply flex items-center justify-center w-full;
    }
    &-nav {
        width: 270px;
        @apply pb-4 border-r border-gray-300;
        &-content {
            max-height: calc(100vh - 220px);
            @apply overflow-y-auto;
        }
    }

    &-loading {
        height: calc(100vh - 142px);
        @apply flex items-center justify-center w-full;
    }
}
.scormHeader {
    @apply h-12 w-full fixed top-0 justify-between z-30;

    &-container {
        @apply flex h-12 w-full bg-white border-b border-gray-300 items-center justify-between px-5;
    }
    &-icon {
        @apply cursor-pointer flex items-center gap-2 text-base font-semibold;

        .background-icon {
            @apply bg-gray-200 p-1 rounded-full w-fit;
            .icon {
                @apply w-5 h-5 text-gray-500 hover:text-gray-900;
            }
        }
    }
    &-title {
        @apply font-semibold text-base;
    }
}
