.pdf-format.invoice,
.pdf-format.receipt {
  width: 8.5in;
  height: 11in;
  padding: 0.5in;
  background-color: white;
  margin: 0 auto;
  line-height: 18px;
  font-size: 10px;
  color: #1F2937;
  font-weight: 400;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header-pdf {
    .company {
      display: flex;
      justify-content: space-between;

      .logo {
        width: 48px;
        height: 48px;
        object-fit: contain;
        margin: 0 auto;
      }

      .company-info {
        text-align: right;
        max-width: 400px;

        .company-info-name {
          font-weight: 600;
        }

        .company-info-address {
          margin-top: 2px;
        }

        .company-info-tax {
          margin-top: 2px;
        }
      }
    }

    .bill {
      margin-top: 28px;
      display: flex;
      justify-content: space-between;
      padding: 16px 0;

      .learner {
        .billed-to {
          color: #6B7280;
        }

        .learner-name {
          font-weight: 600;
          margin-top: 4px;
        }
      }

      .bill-info {
        background-color: #E5F4FF;
        border-radius: 20px;
        padding: 8px 16px;
        display: flex;
        flex-direction: column;
        column-gap: 4px;
        width: 213px;

        .bill-info-item {
          display: flex;
          justify-content: space-between;

          span:nth-child(1) {
            color: #6B7280;
          }

          span:nth-child(2) {
            font-weight: 600;
            text-align: right;
          }
        }
      }
    }

    .table {
      padding-top: 16px;
      width: 100%;

      .table-header,
      .table-body .row,
      .table-footer .row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .product-name {
          font-weight: 600;
        }
        .col {
          padding: 4px 8px;
        }
        .col-1 {
          flex: 4;
        }
        .col-2 {
          text-align: right;
          flex: 1;
        }
        .col-3 {
          text-align: right;
          flex: 2;
        }
        .col-4 {
          text-align: right;
          flex: 2.5;
        }
      }
      .table-header {
        background-color: #F9FAFB;
        border: 1px solid #D1D5DB;
        border-width: 1.5px 0 1px 0;
      }
      .table-body {
        border-bottom: 1px solid #D1D5DB;
      }
      .table-footer {
        .row:nth-child(1) .col-3,
        .row:nth-child(1) .col-4 {
          border-bottom: 1px solid #D1D5DB;
        }
        .row:nth-child(3) .col-3,
        .row:nth-child(3) .col-4 {
          border: 1.5px solid #16A34A;
          border-width: 1.5px 0;
          color: #16A34A;
          font-weight: 600;
        }
      }
    }
  }

  .footer-pdf {
    display: flex;
    justify-content: space-between;
    align-items: end;
    .thank {}
    .payment-detail {
      min-width: 265.5px;
      padding: 8px 16px;
      background-color: #E5F4FF;
      border-radius: 20px;
    }
  }
}