.btn-reset {
  @apply text-primary-500 px-0 border-none text-sm font-normal disabled:opacity-50 disabled:bg-white disabled:text-gray-800;
}
.line {
  @apply h-[27px] w-[1px] bg-gray-200;
}

.dropdownAsync--icon {
  @apply h-4 w-4 mr-2 text-primary-500
}

.filterLearningPath {
  @apply w-full flex flex-wrap gap-2 items-center; 
  &-searchInput {
    max-width: 240px !important;
    @apply w-full text-sm max-h-9 ;
  }
}
