
.sessionEdit {
    &-header {
        @apply flex items-center px-6 border-b border-gray-300 pb-3;
        &-action {
            @apply flex gap-2 items-center;
        }
        &-infor {
            @apply flex items-center gap-2 text-sm font-semibold text-gray-500;
        }
    }
    &-content {
        @apply p-6;
    }
    &-title {
        @apply text-gray-900 text-base font-semibold;
    }
    &-infor {
        @apply flex items-center bg-blue-50 text-sm text-gray-500 px-3 py-2 rounded gap-2;

        .sessionList-status {
            @apply px-1.5 text-xs;
        }
        .dot {
            @apply w-1 h-1 bg-gray-500;
        }
    }
    &-form {
        .field {
            @apply flex items-center mt-3;
        }
        &-label {
            @apply flex-none w-[88px] text-xs font-semibold text-gray-800;
        }
        &-input {
            @apply w-full;
            
            > div {
                @apply h-auto;
            }
        }
    }
    &-footer {
        @apply flex items-center border-t border-gray-300 py-4 px-6;

        &-delete {
            @apply flex items-center gap-2 h-8 
                rounded border border-gray-300 px-3 py-1.5 text-sm font-semibold hover:bg-gray-100 transition; 
        }
    }
}