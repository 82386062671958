.tooltipContainer {
    &-info {
        @apply whitespace-nowrap px-3 py-1 bg-gray-500 shadow-xl rounded right-0  -top-7 text-xs invisible absolute  text-white;
    }
    &-info::after {
        content: '';
        right: 7%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #8f90a6 transparent transparent transparent;
        @apply absolute top-full;
    }
    &-infoleft:after {
        content: '';
        right: 100%;
        top: 40%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent #8f90a6 transparent transparent;
        @apply absolute;
    }
    &-infoCenter::after {
        content: '';
        right: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #8f90a6 transparent transparent transparent;
        @apply absolute top-full;
    }
    &-infoNoneArrow::after {
        content: '';
        right: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: none;
        border-color: none;
        @apply absolute top-full;
    }
}
.tooltipContainer:hover .tooltipContainer-info {
    @apply visible z-70;
}
