.notePopup {
    width: 25vw;
    top: 48px;
    @apply fixed right-0 bottom-0 z-10;
    .container {
        min-height: 80vh;
        @apply text-center;
        .content {
            @apply inline-block px-0 text-left min-h-screen align-middle transition-all transform bg-white shadow-xl overflow-hidden w-full;
            &-body {
                @apply px-5 text-gray-900 text-sm font-normal py-2;
            }
            &-notes {
                @apply px-5 pb-6  overflow-auto;
                &.default {
                    max-height: calc(100vh - 100px);
                }
                &.fullscreen {
                    max-height: 30vh;
                }
                > .content-body {
                    //blockQuote
                    max-height: calc(100vh - 195px);
                    overflow-y: auto;
                    blockquote {
                        overflow: hidden;
                        padding-right: 1.5em;
                        padding-left: 1.5em;
                        margin-left: 0;
                        margin-right: 0;
                        font-style: italic;
                        border-left: solid 5px hsl(0, 0%, 80%);
                    }
                    //numberedList
                    ol {
                        padding-left: 28px;
                        li {
                            list-style-type: auto;
                        }
                    }
                    //bulletedList
                    ul {
                        padding-left: 40px;
                        li {
                            list-style-type: disc;
                        }
                    }
                    h2 {
                        @apply font-semibold text-lg text-dark-800;
                    }
                    strong {
                        @apply font-semibold;
                    }
                    h2,
                    p {
                        @apply mb-4;
                    }
                    p,
                    a {
                        @apply text-dark-800 text-base;
                    }
                    img {
                        @apply rounded-lg;
                    }
                    i {
                        @apply italic;
                    }
                    ol {
                        @apply list-decimal ml-8 mt-4 mb-2;
                    }
                    ul {
                        @apply list-disc ml-8 mt-4 mb-2;
                    }
                    li {
                        @apply text-dark-800 pb-2 text-base;
                    }
                    a {
                        @apply text-enterprise;
                        &:hover {
                            @apply underline;
                        }
                    }
                }
                &--empty {
                    @apply flex justify-center text-gray-400 text-sm;
                }
            }
        }
        .contentHeader {
            @apply mb-4 flex justify-between px-5 text-gray-700  py-3 sticky top-0 bg-white rounded-lg;
            &-label {
                @apply flex items-center gap-2;
            }
            &-text {
                @apply text-base font-semibold;
            }
        }
    }
}
.fullScreenMode {
    height: 100%;
    min-height: 0;
    top: auto;
    width: 20vw;
    max-height: 50vh;
    @apply right-6;

    .container {
        min-height: 0;
        .content-body {
            min-height: 0;
            max-height: calc(50vh - 68px);
            @apply rounded-lg overflow-auto;
        }
        .contentHeader {
            @apply bg-gray-50;
        }
    }
}
