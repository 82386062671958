.InvoiceInformation {
  &-title {
    @apply text-xl font-semibold;
  }
  &--invoice-field-label {
    @apply mt-4 text-xs text-gray-500;
  }
  &--invoice-field-value {
    @apply text-2xl font-semibold mt-4;
  }
  &--transaction-table {
    @apply py-4 mt-5 border-t border-b border-gray-300 grid grid-cols-7;
    .transaction-item {
      @apply col-span-1;
      &.border-style {
        @apply border-r border-gray-300;
      }
      &.padding-left {
        padding-left: 12%;
      }
      &--label {
        @apply text-sm text-gray-500;
      }
      &--value {
        @apply text-sm font-semibold mt-2;
      }
    }
  }
  &-information {
    @apply mt-5 flex gap-5;
    &--label {
      @apply min-w-[120px] text-sm text-gray-500;
    }
    &--value {
      @apply text-sm text-gray-800 font-semibold text-ellipsis truncate;
    }
  }
}

.InvoiceDetail {
  @apply bg-gray-50 min-h-screen relative;
  &-StatusBar {
    @apply p-5  bg-white flex justify-between items-center shadow-md top-[59px] sticky ;
    &--status {
      @apply flex gap-4 items-center;
      .name {
        @apply text-xl font-semibold;
      }
    }
    &--action {
      @apply flex gap-4;
    }
  }
  &-information {
    @apply m-5 p-5 rounded-lg bg-white shadow-md;
  }
  &-doc {
    @apply flex m-5 gap-5;
    &--invoice {
      @apply w-1/2 p-5 rounded-lg bg-white shadow-md;
      .title {
        @apply text-xl font-semibold;
      }
      .no-data {
        @apply mt-5 text-sm text-gray-500 text-center;
      }
    }
  }
  .transaction-table {
    .title {
      @apply text-xl font-semibold mb-4;
    }
    .table-invoice {
      max-height: 400px;
      height: 400px;
      width: 100%;
      position: relative;
      @apply overflow-auto;
    }
  }
}