.recipientAbsenceRequestPage {
    &-header {
        @apply px-5 py-3 bg-white;
        h1 {
            @apply text-gray-900 font-semibold text-xl; 
        }
        p {
            @apply text-gray-500 font-normal text-sm; 
        }
    }
    &-container {
        @apply bg-gray-50 p-5;
    }
    &-content {
        min-height: calc(100vh - 175px);
        @apply bg-white p-6 rounded;
    }
    &-search {
        @apply flex justify-between;
    }
    &-notification {
        z-index: 9999;
        @apply bg-amber-500 py-3 px-4 text-sm flex gap-2 items-center justify-between text-white fixed top-0 left-0
        w-full;
    }
}