.gradebook::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    background: #ebebf0;
}
.gradebook::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 10px;
}
.gradebook::-webkit-scrollbar:vertical {
    display: none;
}
