.paymentGateways {
    @apply flex gap-6 justify-around;

    &-item {
        @apply border border-gray-300 w-1/2 rounded-lg p-4 items-center flex;
    }
    &-connected {
        @apply text-xl font-semibold text-primary-500 w-full;
    }
    &-method {
        @apply flex gap-2 cursor-pointer justify-center w-full;
    }
    &-brand {
        @apply flex items-center gap-2 mb-2 justify-between w-full;
    }
    &-info {
        @apply flex items-center gap-2;
    }
    .label {
        @apply block text-gray-500 text-sm font-normal;
    }
    &-status {
        @apply border border-gray-300 bg-gray-50 rounded py-[1px] px-1.5 inline-block text-xs text-gray-500 font-normal;
        &--enabled {
            @apply border-green-500 text-green-500  bg-green-50;
        }
        &--restricted {
            @apply border-red-300 bg-red-50 text-red-500;
        }
    }
    &-value {
        @apply text-gray-800 text-sm font-semibold mt-1;
    }
    &-connect {
        @apply text-xl font-semibold text-primary-500;
    }
}

.stripeTerm {
    @apply text-dark-800 max-h-[70vh] overflow-auto;
    .overview {
        @apply px-6 py-4 bg-gray-100;
        &-header {
            @apply flex items-center justify-between;
        }
    }
    .textDetail {
        @apply text-sm font-normal;
    }
    .textHeader {
        @apply text-base font-semibold;
    }
    .textSubHeader {
        @apply text-sm font-semibold;
    }
    &-item {
        @apply px-6 py-3;
    }
    ul,
    ul li {
        list-style-type: inherit;
        @apply text-dark-800 bg-none p-0;
    }
    ul {
        padding-left: 28px;
    }

    ol,
    ol li {
        list-style-type: decimal;
    }
    ol {
        padding-left: 28px;
    }
}
.confirmActions {
    @apply flex items-center justify-center gap-4 py-3 border-t;
}
