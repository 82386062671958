.syllabusLearningPathUnit {
    @apply flex flex-col;
    &-header {
        @apply flex py-3 px-4;
    }
    &-info {
        @apply flex gap-2;
    }
    &-name {
        @apply text-base text-gray-900 pl-[5px] font-semibold text-left grid;
    }
    &-badge {
        @apply flex items-center justify-center text-sm text-primary-500 h-[22px] min-w-[24px] font-semibold
        bg-blue-50 rounded-full mt-0.5;
    }
    &-content {
        @apply px-[52px] pt-3 pb-5;
    }
}