.courseSyllabus {
    &-title {
        @apply text-gray-800 text-lg font-semibold px-5 pt-4 pb-2;
    }
    &-tab {
        @apply flex border-b border-gray-300 px-5;

        &-item {
            @apply px-3 py-2 text-xs text-gray-500 font-semibold border-b border-transparent cursor-pointer;

            &.active {
                @apply border-primary-500 text-primary-500;
            }
        }
    }
    &-name {
        @apply text-gray-900 text-base font-semibold;
    }
    &-header {
        @apply flex items-center w-full px-5 py-4;
    }
}