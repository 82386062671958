.featureExtends {
    @apply  grid grid-cols-5 gap-x-4 gap-y-2 pt-2 bg-gray-100;
    &-header {
        @apply col-span-5 text-sm font-semibold flex items-center gap-2 px-4 ;
    }
    &-featureName {
        @apply  text-sm font-semibold;
    }
    &-list {
        @apply bg-white col-span-5 grid grid-cols-5 gap-x-4 gap-y-2  px-2 py-2;
    }
    &-item {
        @apply bg-white col-span-5 grid grid-cols-5 gap-x-4 gap-y-2 px-2 py-2;
        .name {
            @apply col-span-2;
        }
        .readEdit {
            @apply col-span-1 flex justify-center;
        }
        .readOnly {
            @apply col-span-1 flex justify-center;
        }
        .noPermission {
            @apply col-span-1 flex justify-center;
        }
    }
}
