.checkbox-container input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.checkbox-container label {
    position: relative;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #2e3a59;
}

.checkbox-container label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid #8f90a6;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    display: inline-block;
    position: relative;
    vertical-align: bottom;
    cursor: pointer;
    margin-right: 8px;
}

.checkbox-container input:checked + label:before {
    background-color: #0071ce;
    border-color: #0071ce;
}

.checkbox-container input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 7px;
    width: 6px;
    height: 12px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.checkbox-container input:disabled + label {
    color: #b8b8b8;
    cursor: auto;
}

.checkbox-container input:disabled + label:before {
    box-shadow: none;
    background: #dddddd;
    cursor: auto;
    border: none;
}

.checkbox-container.checkbox-table {
    display: flex;
    justify-content: center;
}

.checkbox-container.checkbox-table input:checked + label:after {
    top: 2px;
}

.checkbox-container.checkbox-table label::before {
    border-color: #d1d5db;
    margin-right: 0;
}


.checkbox-container input:indeterminate + label:before {
    background-color: #0071ce;
    border-color: #0071ce;
}

.checkbox-container input:indeterminate + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 9px;
    left: 5px;
    width: 10px;
    height: 2px;
    background-color: #ffffff;
}
