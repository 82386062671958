.assigmentBuilderSidebar {
    &-header {
        @apply flex items-center px-4 py-3 text-sm font-semibold text-gray-900 cursor-pointer;
        &--active {
            @apply bg-primary-500 text-white;
        }
    }
    &-arrow {
        @apply w-6 h-6 flex items-center justify-center transition;

        .assigmentBuilderSidebar-header--active & {
            @apply rotate-90;
        }
    }
    &-content {
        @apply bg-blue-50 p-4;
    }
    &-icon {
        @apply mr-1 ml-2;
        
        .assigmentBuilderSidebar-header--active & {
            @apply text-white;
        }
    }
    &-bagde {
        @apply px-1.5 rounded-full font-semibold text-sm bg-blue-50 text-primary-500
        inline-block ml-2;
    }
}