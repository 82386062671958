.assigmentBuilder {
    @apply bg-gray-50;

    &-header {
        @apply fixed w-full top-0 left-0 bg-white z-10;
    }
    &-main {
        padding-top: 48px;
        min-height: calc(100vh);
        @apply flex;
        
    }
    .missing-content {
        @apply text-red-500 text-sm my-1 ml-6;
    }
    .missing-content-border {
        @apply border border-red-500 rounded-lg;
    }
    &-sidebar {
        width: 400px;
        @apply flex-none border-r border-gray-200 bg-white;

        &-button {
            @apply w-full;
        }
    }
    &-content {
        @apply w-full;
    }
}