.InstructorNotification {
  @apply m-5 border border-gray-100 rounded-xl bg-white p-5;
  &-details {
      h2 {
        @apply font-semibold text-lg text-dark-300;
      }
      strong {
        @apply font-semibold;
      }
      h2, p {
        @apply mb-4;
      }
      p, a {
        @apply text-dark-300 text-base;
      }
      img {
        @apply rounded-lg;
      }
      i {
        @apply italic;
      }
      ol {
        @apply list-decimal ml-8 mt-4 mb-2;
      }
      ul {
        @apply list-disc ml-8 mt-4 mb-2;
        
      }
      li {
        @apply text-dark-300 pb-2 text-base;
  
      }
      a {
        @apply text-primary-500;
        &:hover {
          @apply underline;
        }
      }
  }
}