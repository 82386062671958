.paymentItem {
    @apply border border-gray-200 shadow-card_common p-6 rounded-2xl;
    &-header {
        @apply flex justify-between items-center mb-4;
        h2 {
            @apply text-xl font-semibold text-gray-800;
        }
    }
    &-info {
        @apply flex items-center;
        label {
            @apply text-gray-500 text-sm font-normal;
        }
    }
    &-value {
        @apply text-gray-800 text-base font-semibold mt-1;
    }
    &-action {
        @apply flex items-center gap-2 font-normal text-xs;
    }
    &-line {
        @apply w-[1px] bg-gray-200;
    }
}