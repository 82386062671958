.workspaceMenu {
    @apply flex items-center  rounded-lg p-1.5;

    &-expaned {
        @apply border border-primary-500;
    }
    &-collapsed {
        img {
            @apply border border-primary-500;
        }
    }
    &-listSetting {
        @apply flex items-center justify-center right-1 bottom-1
        w-5 h-5 border-2 border-gray-200 text-gray-900 rounded-full absolute bg-white;
    }
}