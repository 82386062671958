.bookingForm {
    @apply flex flex-col gap-5;

    &-learner {
        @apply flex gap-4 flex-1;
        
    }
    &-label {
        @apply min-w-[50px] flex-none text-gray-800 text-xs font-semibold mb-1;
    }
    &-value {
        @apply text-gray-800 text-sm font-normal;
    }
    &-date {
        @apply flex items-center;

        .bookingForm-label {
            @apply mb-0;
        }
    }
    &-user {
        @apply flex gap-2;

        &-avatar {
            @apply text-sm text-white flex-none flex items-center justify-center w-6 h-6 rounded-full bg-gray-300 bg-cover;
        }
        &-name {
            @apply text-gray-800 text-sm mt-[2px] font-normal;
        }
    }
    &-timeList {
        @apply flex flex-wrap gap-3;

        &-item {
            @apply px-3 py-2 text-base text-primary-500 border border-primary-500 rounded hover:bg-blue-50 transition;

            &.actived {
                @apply bg-primary-500 text-white;
            }
            &:disabled {
                @apply bg-gray-300 opacity-50 cursor-not-allowed text-white border-gray-300;
            }
        }

    }
    &-action {
        @apply flex justify-center gap-2 mb-3;
    }
}