.popoverNewResource {
    &-panel {
        @apply absolute z-10 bg-white w-[240px] shadow-md right-0 rounded;
    }
    &-item {
        @apply flex items-center w-full gap-2 py-2 px-4 cursor-pointer hover:bg-blue-50 transition;
    }
    &-text {
        @apply  text-sm font-normal group-hover:text-primary-500;
    }
}