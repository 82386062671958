.page403 {
  background-image: url('/assets/SVG/bg-404.svg');
  background-position: left top;
  background-repeat: repeat;
  background-size: 100%;
  @apply min-h-screen p-4;

  &-container {
    min-height: calc(100vh - 200px);
    @apply flex w-full items-center justify-center;
  }
  &-logo {
    @apply mt-5 lg:mt-11 ml-10;
  }
}