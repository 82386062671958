.InvoiceList {
  @apply w-full h-screen;
  &-Breadcrum {
    @apply z-10 sticky top-0;
  }
  &-Tab {
    @apply px-5  flex border-b border-gray-300 gap-2 text-gray-500 font-semibold text-sm;
    .TabItem {
      @apply p-4 hover:text-primary-500 hover:border-b hover:border-primary-500 cursor-pointer;
      &.active {
        @apply text-primary-500 border-b border-primary-500;
      }
    }
  }
}