.ck-editor__editable_inline {
    min-height: 132px;
}

.ck.ck-content ul,
.ck.ck-content ul li {
    list-style-type: inherit;
}

.ck.ck-content ul {
    padding-left: 28px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
    list-style-type: decimal;
}
.ck.ck-content ol {
    padding-left: 28px;
}

.ck.ck-content h2 {
    font-size: 24px;
    font-weight: bolder;
}

.ck.ck-content h3 {
    font-size: 18.72px;
    font-weight: bolder;
}
.ck.ck-content h4 {
    font-size: 	16px;
    font-weight: bolder;
}

.notification .ck.ck-content p {
    font-size: 16px;
}

.ck.ck-content a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
}
