.noteSection {
    min-height: calc(100% + 64px);
    @apply w-full bg-white absolute -right-10 -top-8 shadow-card_common;

    &-header {
        @apply flex px-4 py-2 w-full;
    }
    &-close {
        @apply ml-auto text-gray-500 hover:text-gray-600 mr-2 cursor-pointer;
    }
    &-text {
        @apply font-semibold text-gray-700 text-base ml-2;
    }
    &-editor {
        .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused),
        .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, 
        .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
            @apply border-0;
        }
        .ck.ck-editor__main {
            @apply border-t border-gray-300;
        }
        .ck-placeholder {
            @apply text-gray-400 text-sm;
        }
        .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
            @apply px-4;
        }
        .ck.ck-editor__main>.ck-editor__editable.ck-focused {
            @apply outline-none border-0 shadow-none px-4;
        }
    }
}