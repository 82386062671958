.EnrollmentDetail { 
  @apply min-h-[calc(100vh-180px)] py-4 bg-white rounded w-full ;
  .BackBtn {
    @apply flex gap-2 items-center;
    > div {
      @apply  text-gray-800 text-lg font-semibold;
    }
  }
  &-Tablist {
    @apply w-full text-xs flex gap-4 items-center border-b border-gray-300 mt-3;
    .tab-default {
      @apply py-1 px-2 text-center font-semibold text-xs flex text-gray-500 ;
      &.active {
        @apply text-primary-500 border-b-2 border-primary-500 ;
      }
    }
  }
}

.EnrollmentInformation {
  @apply mt-6;
  &-field {
    @apply flex mb-4;
    >.label {
      @apply text-sm font-semibold w-44;
    }
    >.value {
      @apply text-sm font-normal text-gray-800 flex gap-2;
      >.changeEndDate {
        @apply text-primary-500 cursor-pointer text-sm flex items-center gap-1;
        >.icon {
          @apply w-5 h-5;
        }
      }
    }
  }
}

.ModalChangeEndDate {
  @apply mt-6 text-sm font-normal;
  &-input {
    @apply w-full pl-2 text-sm font-normal;
  }
  .GroupBtn {
    @apply flex justify-center gap-4 mt-6
  }
}