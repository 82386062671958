
.formCheckBox {
    @apply flex items-center relative pl-7;
    &-checkmark {
        @apply border border-gray-300 rounded h-5 w-5 absolute top-0 left-0;
    }
    input {
        @apply hidden;
        &:checked ~ .formCheckBox-checkmark {
            @apply border border-primary-500 bg-primary-500 text-white;
            svg {
                @apply inline-block -mt-1;
            }
        }
    }
    
}