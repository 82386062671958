.AssignmentSubmission {
  @apply w-full px-4;
  &-title {
    @apply absolute top-0 z-10 left-0 w-full bg-white px-5 py-3 text-xl text-gray-800 font-semibold flex justify-between border-b border-gray-300;
    .btnDelete {
      @apply text-gray-500 cursor-pointer;
    }
  }
  &-information {
    @apply max-h-[calc(100vh-245px)] mt-14 overflow-scroll overflow-x-hidden ;
    .wrapper-content {
      @apply border border-blue-300 bg-blue-50 p-3 rounded-xl flex gap-2 items-center pr-10 relative;
      >.icon {
        @apply text-primary-500 w-6 h-6;
      }
      >.text {
        @apply text-sm;
      }
    }
    >form {
      @apply mt-4;
    }
    >.preview {
      @apply mt-5 w-full;
      .label {
        @apply text-sm font-semibold text-gray-800;
      }
      .wrapper-preview {
        @apply mt-2 rounded-lg bg-gray-100 p-5;
        .title {
          @apply text-xl font-semibold text-gray-800;
        }
        .action {
          @apply flex items-center text-xs text-gray-400 cursor-pointer;
          .icon {
            @apply w-3 h-3 text-gray-400;
          }
        }
        .note {
              //blockQuote
              max-height: calc(100vh - 195px);
              overflow-y: auto;
              blockquote {
                  overflow: hidden;
                  padding-right: 1.5em;
                  padding-left: 1.5em;
                  margin-left: 0;
                  margin-right: 0;
                  font-style: italic;
                  border-left: solid 5px hsl(0, 0%, 80%);
              }
              
              li {
                background-color: unset !important;
              }
              ul,
                ul li {
                    list-style-type: inherit;
                }
              
              ul {
                  padding-left: 28px;
              }
            
              ol,
              ol li {
                  list-style-type: decimal;
              }
              ol {
                padding-left: 28px;
              }
            
              h2 {
                  font-size: 24px !important;
                  font-weight: bolder;
                }
              
              h3 {
                  font-size: 18.72px !important;
                  font-weight: bolder;
              }
              h4 {
                  font-size: 	16px !important;
                  font-weight: bolder;
              }
            
              p {
                font-size: 16px;
              }
            
              a:-webkit-any-link {
                color: -webkit-link;
                cursor: pointer;
                text-decoration: underline;
              }
        }
      }
    }
    
  }
}

.uploadFile {
  @apply relative w-full h-full bg-white p-4 rounded-lg border-gray-200 flex flex-col items-center justify-center gap-2;
  min-height: 400px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23333' strokeWidth='1' stroke-dasharray='8%2c 8' stroke-dashoffset='48' stroke-linecap='square'/%3e%3c/svg%3e");
  .text-upload {
    @apply text-gray-400 font-semibold text-base underline;
  }
  .text-drag-drop {
    @apply text-base text-gray-800;
  }
  .limitFileType {
    @apply max-w-xs text-xs font-normal text-gray-500 text-center;
  }
}

.AssignmentSubmission .ck-placeholder {
  font-size: 12px !important;
}