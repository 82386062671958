.filterAssignment {
    z-index: 9;
    @apply w-full flex flex-wrap gap-2 items-center;
    &-searchInput {
        @apply w-full max-w-[320px] text-sm max-h-9;
    }
    &-assignmentFrom {
      @apply flex items-center p-1 border border-ooolab_gray_6 rounded;
      .items {
        @apply font-semibold text-xs text-gray-500 px-5 py-[5px] cursor-pointer;
      }
      .selectedFrom {
        @apply text-enterprise bg-blue-50 rounded;
      }
    }
}
