.ModalAssignAssignment {
  @apply p-2;
  &-type {
    @apply border border-gray-300 rounded p-1 flex w-full;
    .btn-type {
      @apply text-xs font-semibold text-gray-400 w-1/2 p-2 text-center cursor-pointer;
      &.active {
        @apply text-primary-500 bg-blue-50;
      }
    }
  }
  li {
    list-style-type: disc;
    @apply py-1 text-gray-800 ml-6;
  }
  >.field {
    @apply mt-4;
    label {
      @apply text-dark-300 text-xs font-semibold;
    }
    >.package-item {
      @apply my-2 flex items-center space-x-2;
      >.btn-remove {
        @apply flex-none flex items-center justify-center w-4 h-4 rounded-full border border-red-500 text-red-500;
        &.disabled {
          @apply opacity-0 pointer-events-none;
        }
      }
    }
    &.group-button {
      @apply flex items-center justify-center space-x-4 mt-6;
    }
    &.group-datepicker {
      @apply flex space-x-4;
    }
    .btn-add-package {
      @apply w-full border border-dashed text-primary-500 border-primary-500 rounded p-1 flex items-center justify-center text-sm font-semibold;
      &.disabled {
        @apply cursor-not-allowed pointer-events-none opacity-50;
      }
    }
  }
  &-assignmnetSelected {
    @apply mt-4;
    &--title {
      @apply text-sm font-semibold;
    }
    .assignmentSelectedItem {
      @apply text-xs;
      &-name {
        @apply text-ellipsis truncate;
      }

    }
  }
}